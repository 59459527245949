import React, { Component } from 'react';

export default function({difficulty, white}){
  const styles = {
    skillPill: {
      padding: 12,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 30,
      fontSize: 14
    },
    newbie: {
      backgroundColor: white ? 'white' : '#c5dffa',
      color: '#0d5ba8'
    },
    beginner: {
      backgroundColor: white ? 'white' : '#DBEFDC',
      color: '#00865a'
    },
    intermediate: {
      color: '#f07300',
      backgroundColor: white ? 'white' : '#FFF6E5'
    },
    advanced: {
      backgroundColor: white ? 'white' : '#FEEAEA',
      color: '#dd2727'
    }
  }

    if(difficulty=='newbie') return <span style={{...styles.skillPill, ...styles.newbie}}>Newbie</span>;
    if(difficulty=='beginner') return <span style={{...styles.skillPill, ...styles.beginner}}>Beginner</span>;
    if(difficulty=='intermediate') return <span style={{...styles.skillPill, ...styles.intermediate}}>Intermediate</span>;
    if(difficulty=='advanced') return <span style={{...styles.skillPill, ...styles.advanced}}>Advanced</span>;

    return null;
}


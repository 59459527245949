import React, { Component } from 'react';

class MarkCompleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isComplete, markComplete, markIncomplete } = this.props;
    return(
      <div class={`markCompleteButton ${isComplete ? 'markCompleteButtonComplete' : 'markCompleteButtonIncomplete'} canHover`} onClick={isComplete ? markIncomplete : markComplete} style={isComplete ? styles.complete : undefined}>
        {isComplete ? 'Undo Mark Complete' : 'Mark as Complete'}
      </div>
    );
  }
}

const styles = {
  complete: {
    opacity: .8,
  }
};

export default MarkCompleteButton;
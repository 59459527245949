import React, { Component } from 'react';
import { Table, Grid, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

//my components
import MyNavbar from '../components/MyNavbar';
import SideNav from '../components/SideNav';

class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: ''
        }
    }

    render() {
      let signedUp = false; //determine if the user has already submit their email to clickfunnels ? use cookies? or just render iframe always

      return (
        !signedUp ? 
          <iframe src='https://storyling.clickfunnels.com/squeeze-page22615477' width='100%' style={{height: '99vh', overflowY: 'hidden' }} frameborder='0'></iframe>
        :
          <div></div>
      );
    }
}

const styles = {

}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser,
    navState: state.navState
})

export default connect(mapStateToProps)(Homepage);

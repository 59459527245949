import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { articlesNeedReload, updateArticles } from "../../redux/actions";
import { connect } from "react-redux";

class ReadFavoriteButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  greyCheck=require('../../assets/img/grey/check.png');
  greyHeart=require('../../assets/img/grey/heart-outline.png');
  blueHeart=require('../../assets/img/blue/heart-outline.png');
  blueCheck=require('../../assets/img/blue/check.png');

  componentDidUpdate(prevProps, prevState){
    if(prevProps.isRead != this.props.isRead || prevProps.isFavorite != this.props.isFavorite){
      this.setState({
        isRead: this.props.isRead,
        isFavorite: this.props.isFavorite
      })
    }
  }

  handleFavoriteClick(){
    let isFavorite = !this.state.isFavorite;
    this.setState({isFavorite: isFavorite});
    fetch('/article/favorite/'+this.props.articleID, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ isFavorite: isFavorite })
    })
    .then(result => result.json())
    .then(result => {
      this.props.articlesNeedReload(true);
    })
  }

  handleReadClick(e){
    let isRead = !this.state.isRead;
    this.setState({isRead: isRead});
    fetch('/article/read/'+this.props.articleID, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ isRead: isRead })
    })
    .then(result => result.json())
    .then(result => {
      this.props.articlesNeedReload(true);
    })
  }

  render() {
    let { isRead, isFavorite } = this.state;

    return(
      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent'
      }}>

        <span style={styles.button} onClick={this.handleFavoriteClick.bind(this)}>
          <img src={isFavorite ? this.blueHeart : this.greyHeart} style={styles.icon} title='Favorite'/>
        </span>

        <span style={styles.button} onClick={this.handleReadClick.bind(this)}>
          <img src={isRead ? this.blueCheck : this.greyCheck} style={styles.icon} title='Is Read'/>
        </span>

      </div>
    );
  }
}

const styles = {
  button: {
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 8px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)',
    borderRadius: 35,
    padding: 13,
    margin: 8,
    cursor: 'pointer',
    backgroundColor: '#fff'
  },
  icon: {
    height: 36,
    flex: 1
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage,
  searchText: state.searchText,
  articleInfo: state.articleInfo
});

const mapActionsToProps = {
  updateArticles,
  articlesNeedReload
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ReadFavoriteButtons);
export default {
    main: '#82ca1c',
    // lightMain: '#aae8a7',
    text: '#3d3d3d',
    lightText: '#595959',
    lighterText: '#a8a8a8',
    lightGrey: '#e1e4e8',
    border: '#dddddd',
    background: '#efefef',
    darkGrey: '#bfbfbf',
    blue: '#007fff',
    blueGrey: '#c2d0da',
    blueBG: '#f5f7fd',
    red: '#FF453A'
}
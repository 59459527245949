import React, { Component } from "react";
import SkillPill from "../../routes/BrowsePage/components/SkillPill";
import LabelIcon from "./LabelIcon";
import { getLengthStr } from "../../helpers/courses";

class HeaderDetailsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getLengthStr = () => {
    const { course } = this.props;
    let len = 0;
    course.lessons.forEach(l => {
      if(l.length) len += parseInt(l.length);
    })
    return getLengthStr(len);
  }

  render() {
    const { course } = this.props;
    let lenStr = this.getLengthStr();
    return (
      <div style={styles.detailsRow}>
        <SkillPill difficulty={course.difficulty} />
        <div style={{ width: 12 }} />
        <LabelIcon iconName="play" label={`${course.lessons.length} lessons`} />
        {lenStr ? <LabelIcon iconName="clock" label={lenStr} /> : null}
      </div>
    );
  }
}

const styles = {
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

export default HeaderDetailsRow;

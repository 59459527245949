import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import './api/http'; // load into global

//components
import App from "./App";

//cs / js
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery/dist/jquery.min.js";
import "./assets/css/app.css";
import "./assets/css/login.css";
import "./assets/css/readStory.css";
import "./assets/css/SideNav.css";
import "./assets/css/wordsPage.css";
import "./assets/css/adminPortal.css";
import "./assets/css/animations.css";
import "./libraries/circular-std"; // this was on NPM but i think it was removed because copywright, so i just need to copy/paste it from an old node modules
import 'react-markdown-editor-lite/lib/index.css';
import 'react-credit-cards/es/styles-compiled.css';

// IE fix
import "react-app-polyfill/ie11";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import PodcastUrlListener from "./components/PodcastUrlListener";

ReactDOM.render(
  <Provider store={store}>
    <>
    <PodcastUrlListener />
    <App />
    </>
  </Provider>,
  document.getElementById("root")
);

// registerServiceWorker();

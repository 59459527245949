import React, { Component } from "react";
import Colors from "../assets/Colors";
import closeIcon from "../assets/img/close.png";
import { isMobile } from 'react-device-detect';

class SiteBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: {
        linkLabel: "",
        link: "",
        text: "",
      },
      isVisible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.loadBanner(), 500);
  }

  onCloseButtonClick = () => {
    this.setState({isVisible: false})
  };

  loadBanner = () => {
    global
      .apiRequest({
        method: "GET",
        url: "/sitebanner/check",
      })
      .then((res) => {
        this.setState({
          banner: res.banner,
          isVisible: res.banner.isActive
        })
      });
  };

  render() {

    const styles = {
      wrapper: {
        position: "fixed",
        top: this.state.isVisible !== true ? -800 : 0,
        transition: '1s',
        width: "100vw",
        padding: 10,
        paddingRight: isMobile ? 10 : 30,
        boxShadow: "0 5px 12px 0 rgba(0, 0, 0, 0.15)",
        backgroundColor: this.state.banner.bgColor || Colors.main,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 999999,
      },
      inside: {
        display: "flex",
        flexDirection: isMobile ? 'column' : "row",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 800,
        position: "relative",
        // flex: 1
      },

      text: {
        color: this.state.banner.fontColor || "#fff",
        textAlign: 'center'
      },
      button: {
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: "solid",
        cursor: "pointer",
        borderColor: this.state.banner.fontColor || "#fff",
        color: this.state.banner.fontColor || "#fff",
        height: 40,
        paddingLeft: 15,
        paddingRight: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginLeft: isMobile ? 0 : 20,
        marginTop: isMobile ? 10 : 0
      },
      closeButton: {
        // padding: 5,
        cursor: "pointer",
        // position: "absolute",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // top: 10,
        padding: 5,
        paddingLeft: 15,
        // right: isMobile ? 10 : 20,
        // backgroundColor: "rgba(0,0,0,.1)",
        // borderRadius: 4,
      },
      closeIcon: {
        height: 25,
        width: 25,
        filter: "brightness(0) invert(1)", // make image white
      },
    };

    return (
      <div style={styles.wrapper}>
        {/* <div style={{width: 35}} /> */}
        <div />
        <div style={styles.inside}>
          <div style={styles.text}>{this.state.banner.text}</div>
          {this.state.banner.link ? (
            <a style={styles.button} href={this.state.banner.link} target="_blank">
              {this.state.banner.linkLabel}
            </a>
          ) : null}
        </div>
        <div style={styles.closeButton} onClick={this.onCloseButtonClick}>
          {/* <img src={closeIcon} style={styles.closeIcon} /> */}
          <i class="fas fa-times" style={{color: this.state.banner.fontColor, fontSize: 26}} />
        </div>
      </div>
    );
  }
}

export default SiteBanner;

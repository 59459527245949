import React, { Component } from 'react';
import PodcastHeader from './../../components/podcasts/PodcastHeader';
// import PodcastPageTemplate from './../../components/podcasts/PodcastPageTemplate';
import PodcastCard from '../../components/podcasts/PodcastCard';
import Colors from '../../assets/Colors';
import LoadingOverlay from '../../components/LoadingOverlay';
import LinkButton from './../../components/podcasts/LinkButton';
import { ClipLoader } from 'react-spinners';
import PageTemplate from '../PageTemplate';

class BrowsePodcastsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      podcasts: []
    };
  }

  navigate = route => {
    this.props.history.push(route);
  }

  componentDidMount(){
    let language = this.props.location.pathname.replace('/', '');
    fetch(`/podcasts/getall?language=${language}`, {
      method: 'GET',
    })
    .then(result => result.json())
    .then(result => {
      if(result.status == 'success'){
        result.articles = result.articles.filter(x => x.isHidden !== true);
        this.setState({podcasts: result.articles, loading: false})
      }else{
        alert(result.message);
        this.setState({ loading: false});
      }
    })
  }

  render() {
    return(
      <PageTemplate podcast>
        <div style={styles.insideCol}>
          <div style={styles.inside}>
            <PodcastHeader />
            <div style={styles.podcastButtonRow}>
            <LinkButton name='Spotify' link='https://open.spotify.com/show/3upbqZk1ZDC7aJMLj3QLF8' />
            <LinkButton name='iTunes' link='https://podcasts.apple.com/us/podcast/learn-spanish-with-stories/id1590978173' />
            <LinkButton name='Google Podcasts' link='https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xODY5MTkyLnJzcw==' />
            </div>
            <div style={styles.hr} />
            {this.state.loading ? 
            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingTop: 100 }}>
                <ClipLoader color="grey" />
              </div>
            :null}
            {this.state.podcasts.map(item => {
              return(
              <PodcastCard item={item} navigate={this.navigate} />
              )
            })}

          </div>
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  hr: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.border,
    marginBottom: 40
  },
  podcastButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  insideCol: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  inside: {
    width: 700,
    paddingLeft: 10,
    paddingRight: 10,
    overflow: 'hidden',
    position: 'relative'
  }

};

export default BrowsePodcastsPage;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Colors from "../../../assets/Colors";
import RowSection from "./RowSection";
import moveIcon from '../../../assets/img/move.png';

class LessonDeckRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  copyLink = () => {
    navigator.clipboard.writeText("https://members.lingomastery.com/words/?lessondeckid="+this.props.item.id)
    this.setState({copied: true})
    setTimeout(() => {
      this.setState({copied: false})                   
    }, 3000);
  }

  render() {
    const { dragHandleProps, item, selectedLanguage } = this.props;
    const { copied } = this.state;
    let hideItem = false;
    if (selectedLanguage && item.language !== selectedLanguage) hideItem = true;

    return (
      <div>
        <div style={hideItem ? styles.hiddenRow : styles.row}>
          <div style={{...styles.colorSquare, backgroundColor: item.color}} />
          <RowSection flex={0.5}>
              {item.title}
            </RowSection>
          <RowSection flex={0.1}>{item.list.length}</RowSection>
          <RowSection flex={0.2}>{item.language}</RowSection>
          <RowSection flex={0.05}>
            <Link to={"/manage-lesson-decks/deck/" + item.id}>
              <i class="fa fa-edit" />
            </Link>
          </RowSection>
          <RowSection flex={0.1}>
            <div onClick={this.copyLink} style={{cursor: 'pointer'}}>{copied ? 'Copied!' : "Copy Link"}</div>
          </RowSection>
          <RowSection flex={0.1}>
            <div className="drag-handle" style={styles.dragHandle} {...dragHandleProps}>
              <img src={moveIcon} style={{height: 18, width: 18}} />
            </div>
          </RowSection>
        </div>
        {hideItem ? null : <div style={{ height: 10 }} />}
      </div>
    );
  }
}

const styles = {
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // marginBottom: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.border,
    borderStyle: "solid",
    padding: 3,
    paddingLeft: 8,
    backgroundColor: '#fff'
  },
  hiddenRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 0,
    overflow: "hidden",
  },
  section: {
    flex: 0.2,
  },
  colorSquare: {
    height: 20,
    width: 20,
    marginRight: 10,
    borderRadius: 4,
  },
  dragHandle: {
    cursor: "grab",
    opacity: .7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
};

export default LessonDeckRow;

import { v4 as uuidv4 } from "uuid";
// var decode = require("parse-entities"); //for some reason google translate was returning html entities (decimal) as plain text for apostrophes. i wonder if this library is messing with the format issues i was having below with line breaks...

const speechParts = {
  ADJ: "adjective",
  ADV: "adverb",
  CONJ: "conjunction",
  DET: "determiner",
  MODAL: "verb",
  NOUN: "noun",
  PREP: "preposition",
  PRON: "pronoun",
  VERB: "verb",
  OTHER: "Other",
};

export default function (word, language, opts = {}) {
  return new Promise(async (resolve) => {
    let lang = "";
    switch (language.toLowerCase().trim()) {
      case "spanish":
        lang = "es";
        break;
      case "german":
        lang = "de";
        break;
      case "italian":
        lang = "it";
        break;
      case "french":
        lang = "fr";
        break;
      case "portuguese":
        lang = "pt";
        break;
      case "russian":
        lang = "ru";
        break;
      default:
        break;
    }

    var azureUrl = `https://api.cognitive.microsofttranslator.com/dictionary/lookup?from=${lang}&to=en&api-version=3.0`;
    let googleUrl = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCB4VYtaSAZI812Efr1ygLOBx9Fg2lXrSE&q=${word}&lang=${lang}&target=en&format=text`;

    let promises = [
      fetch(googleUrl, {
        method: "post",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
      fetch(azureUrl, {
        method: "POST",
        headers: {
          "Ocp-Apim-Subscription-Key": "7cb5b8c9b4a44b22b080eea53e785e98",
          "Ocp-Apim-Subscription-Region": "eastus",
          "Content-type": "application/json",
          "X-ClientTraceId": uuidv4().toString(),
        },
        body: JSON.stringify([{ text: word }]),
        responseType: "json",
      }).then((res) => res.json()),
    ];

    Promise.all(promises).then((allDone) => {
      let googleRes = allDone[0];
      let azureRes = allDone[1];

      let returnObject = {
        translation: "",
        translations: [],
      };

      try {
        returnObject.translation = googleRes.data.translations[0].translatedText;
        returnObject.translations = azureRes[0].translations.map((t) => {
          return {
            text: t.displayTarget,
            partOfSpeech: t.posTag !== "OTHER" ? speechParts[t.posTag] : "",
            confidence: t.confidence,
          };
        });
      } catch (e) {
        console.log("Error parsing azure/google translation response", e);
      }

      resolve(returnObject);
    });
  });
}

import React, { Component } from 'react';
import Colors from '../assets/Colors';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { isMobile } from 'react-device-detect';


class InfoPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }

    togglePopover(){
        this.setState({open: !this.state.open});
    }


    render() {

        return (

            <Popover placement={isMobile ? 'top' : 'right'}  isOpen={this.state.open} target={this.props.element} toggle={this.togglePopover}>
            <PopoverHeader>Define Words</PopoverHeader>
              <PopoverBody>
                <span>Click on a word to define it</span>
              </PopoverBody>
            </Popover>
            
        );
    }
}

const styles = {

}

export default InfoPopup;

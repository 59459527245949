import React, { Component } from "react";
import Routes from "./routes";
import { updateLoggedInUser, setAvailableLanguages, selectLanguage } from "./redux/actions";
import { modifyDeck, selectDeckId, getGeneralDeckId, fetchDecks } from './helpers/decks';
import { connect } from "react-redux";
import Loading from "./components/Loading";
import { isMobile } from "react-device-detect";
import HttpsRedirect from "react-https-redirect";
import ReadStoryClass from "./routes/ReadStoryPage/ReadStoryClass";
import { Helmet } from "react-helmet";
import { fetchLessonDecks } from "./helpers/lessonDecks";
import { loadCourseStatus } from "./helpers/courses";
import SiteBanner from "./components/SiteBanner";
import Constants from "./assets/Constants";
import logSiteVisit from "./scripts/logSiteVisit";

class App extends Component {
  constructor(props) {
    super(props);
    // if(window.location.host.includes('localhost')) isPodcast = true; // FOR TESTING ONLY
    if(props.podcastMeta.isPodcast) document.title = "Spanish Podcast Episodes - "+Constants.appName;

    this.state = {
      loading: true
    };
  }

  componentWillMount() {
    //upon app load, check if browser cookie has a logged in user so we can load their info
    // Loop that runs for mobile/pwa. keeps app on loading screen until it can authenticate with repeated attempts
    //for PWAs this helps. idk why, like at first if it makes requests its not logged in. but then u wait and reload and make requests later and they are logged in\
    // if(isMobile && !this.props.loggedInUser){
    //   setTimeout(() => {if(!this.props.loggedInUser) this.resetApp();}, 100);
    //   setTimeout(() => {if(!this.props.loggedInUser) this.resetApp();}, 500);
    //   setTimeout(() => {if(!this.props.loggedInUser) this.resetApp();}, 1000);
    //   setTimeout(() => {if(!this.props.loggedInUser) this.resetApp();}, 1500);
    //   setTimeout(() => {if(!this.props.loggedInUser) this.resetApp();}, 2000);
    //   setTimeout(() => {if(!this.props.loggedInUser) this.setState({loading: false});}, 2500);

    // }else{
    //   this.checkLogin();
    // }

    this.checkLogin();
  }

  resetApp(stopLoading) {
    // this.setState({loading: true});
    setTimeout(() => {
      this.checkLogin(stopLoading);
    }, 50);
  }

  checkLogin() {
    fetch("/getuser", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(result => result.json())
      .then(async result => {
        if (result.loggedIn) {
          // NOTE also handle user login in LoginPage.js
          this.props.onUpdateLoggedInUser(result.user);
          this.props.selectLanguage(result.user.currentLanguage || this.props.availableLanguages[0]);
          localStorage.setItem("hasLoggedInBefore", 'true')
          fetchDecks();
          await loadCourseStatus();
          fetchLessonDecks();
          logSiteVisit();
          this.setState({ loading: false });
        } else {
          this.props.onUpdateLoggedInUser(null);
          // if(!isMobile){
          this.setState({ loading: false });
          // }
        }
      })
      .catch(e => console.log(e));

    fetch("/languages/get/all", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(langs => langs.json())
      .then(langs => {
        this.props.onSetAvailableLanguages(langs.languages);
      })
      .catch(e => console.log(e));
  }

  render() {
    // {/*<HttpsRedirect>*/}
    // {/*</HttpsRedirect> dont need this as im using scripts/requireHttps.js in the server*/}
    const { isPodcast } = this.props.podcastMeta.isPodcast;
    let podcastHelmet = (
      <Helmet>
        <meta property="og:title" content={`${Constants.appName} Podcast Episodes`} />
      </Helmet>
    );
    return this.state.loading ? (
      <React.Fragment>
        {isPodcast ? podcastHelmet : null}
        <Loading />
      </React.Fragment>
    ) : (
      <React.Fragment>
        {isPodcast ? podcastHelmet : null}
        <SiteBanner />
        <Routes loggedInUser={this.props.loggedInUser} podcastMeta={this.props.podcastMeta} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  availableLanguages: state.availableLanguages,
  podcastMeta: state.podcastMeta
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  onSetAvailableLanguages: setAvailableLanguages,
  selectLanguage,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);

export default function getDictionaryLink(word, language){

  if(language=='spanish')
    return `http://www.wordreference.com/es/en/translation.asp?spen=${word}`;

  if(language=='italian')
    return `http://www.wordreference.com/iten/${word}`;

  if(language=='german')
    return `http://www.wordreference.com/deen/${word}`;

  if(language=='portuguese')
    return `http://www.wordreference.com/pten/${word}`;

  if(language=='french')
    return `http://www.wordreference.com/fren/${word}`;

  if(language=='russian')
    return `http://www.wordreference.com/ruen/${word}`;

  //must be another language. default to english. should never need to use this tho
  return `https://www.dictionary.com/browse/${word}?s=t`;
}
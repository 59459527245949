import React, { Component } from "react";
import { Dropdown, Menu } from "antd";
const sideButtonLength = 19;

class WordRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  shorten(phrase) {
    try {
      // empty translations were causing whitescreen
      if (phrase.length > sideButtonLength) {
        return phrase.substring(0, sideButtonLength) + "...";
      } else {
        return phrase;
      }
    } catch (e) {}
  }

  handleClick = (e, item) => {
    if(e.target === e.currentTarget){
      this.props.setGrandParentState({ selectedWord: item, isFlipped: false, flipSpeed: 0, selectedTab: "flashcards" })
    }
  }

  render() {
    let { setGrandParentState, item, removeWord, setref, reverse, index, selected, isLessonDeck } = this.props;
    let className = "list-group-item list-group-item-action" + (selected ? " activeWord" : " inactiveWord");

    return (
      <div
        ref={(x) => setref("word" + item.word, x)}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <div
          style={styles.listButton}
          className={className}
          onClick={e => this.handleClick(e, item)}
        >
          {this.shorten(reverse ? item.translation : item.word)}
          {/* <i style={styles.closeIcon} onClick={() => this.removeWord(index)} class='fa fa-close fadeHover'></i> */}
          {this.state.hover  ? (
            <div class="my-auto" style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Dropdown overlay={this.props.dropdownMenu}>
                <img
                  style={styles.closeIcon}
                  // onClick={() => removeWord(index)}
                  // class="fadeHoverReverse"
                  src={require("../../../../assets/img/more.png")}
                />
              </Dropdown>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const styles = {
  listButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: 20,
    borderColor: "#F1F2F4",
    borderWidth: 1,
    borderBottomWidth: 0,
    borderLeftWith: 0,
  },
  closeIcon: {
    // float: 'right',
    // height: 34,
    width: 25,
    padding: 5,
    zIndex: 3,
    cursor: "pointer",
    // margin: "auto",
    // position: "absolute",
    // right: 4,
    // top: 18,
    opacity: 0.4,
  },
};

export default WordRow;

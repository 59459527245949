import React, { Component } from "react";
import AnimateHeight from "react-animate-height";
import { toggleNav, updateLoggedInUser } from "../../../redux/actions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import htmlParser from 'react-markdown/plugins/html-parser'
//Components
import LoadingOverlay from "../../../components/LoadingOverlay";
import Colors from "../../../assets/Colors";
import PageTemplate from "../../PageTemplate";
import LessonItem from "../../../components/courses/LessonItem";
import Breadcrumbs from "../../../components/courses/Breadcrumbs";
import { markLessonInProgress, getLessonStatus, markLessonComplete, markLessonIncomplete } from "../../../helpers/courses";
import ReactPlayer from "react-player";
import CourseDetailsHeader from "../../../components/courses/CourseDetailsHeader";
import MarkCompleteButton from "../../../components/courses/MarkCompleteButton";

const ReactMarkdownWithHtml = require('react-markdown/with-html')
const queryString = require("querystring");

class ViewCoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      course: {
        lessons: [],
      },
      selectedLesson: {},
    };
  }

  componentDidMount() {
    this.loadCourse();
  }

  loadCourse = () => {
    let courseId = this.props.match.params.id;
    const queryItems = queryString.parse((this.props.location.search || '').replace("?", "")) || {};
    let selectlessonid = queryItems.selectlessonid || "";
    global.apiRequest({ url: `/courses/get/${courseId}` }).then((res) => {
      let newState = {
        course: res.course,
        loading: false,
      };
      let lessonToSelect = {};
      if (selectlessonid !== undefined) {
        newState.course.lessons.forEach((l) => {
          if (l.id === selectlessonid) lessonToSelect = l;
        });
      } else {
        lessonToSelect = newState.course.lessons[0];
      }
      newState.selectedLesson = lessonToSelect;
      this.setState(newState, () => this.sortCourse());
    });
  };

  sortCourse = () => {
    let newLessons = [...this.state.course.lessons];
    newLessons.sort((a, b) => a.sortIndex > b.sortIndex);
    newLessons = newLessons.map((l) => {
      // let newSections = [...l.sections];
      // newSections.sort((c, d) => c.sortIndex > d.sortIndex);
      return {
        ...l,
        // newSections
      };
    });
    this.setState({
      course: {
        ...this.state.course,
        lessons: newLessons,
      },
    });
  };

  onClickLesson = (lesson) => {
    this.setState({ selectedLesson: lesson }, () => {
      window.scrollTo(0,0)
    });
  };

  onMarkComplete = () => {
    markLessonComplete(this.state.course.id, this.state.selectedLesson.id)
  };

  onMarkIncomplete = () => {
    markLessonIncomplete(this.state.course.id, this.state.selectedLesson.id)
  }

  onPlayVideo = () => {
    markLessonInProgress(this.state.course.id, this.state.selectedLesson.id);
  }
  
  render() {
    console.log('course', this.state.course);
let str = 'hey test <a href="https://google.com" target="_blank">google</a> asdasdee'

    return (
      <PageTemplate limitSize={isMobile ? false : true}>
        <div style={styles.page}>
          <LoadingOverlay hide={!this.state.loading} />
          <div style={styles.breadcrumbWrapper}>
            <Breadcrumbs course={this.state.course} lesson={this.state.selectedLesson} />
          </div>
          {isMobile ? null : <div style={{ height: isMobile ? 25 : 30 }} />}
          <div style={styles.top}>
            <div style={styles.topLeft}>
              <CourseDetailsHeader course={this.state.course} />
              <div style={{ height: 20 }} />
            </div>

            <div style={styles.topRight}>
              <MarkCompleteButton markComplete={this.onMarkComplete} markIncomplete={this.onMarkIncomplete} isComplete={getLessonStatus(this.state.course.id, this.state.selectedLesson.id, this.props.courseStatus)==='complete'}/>
            </div>
          </div>

          <div style={styles.body}>
            {isMobile ? null : <div style={{minWidth: isMobile ? 10 : 20}}></div>}
            <div style={styles.left}>
              <div style={styles.playerWrapper}>
                <ReactPlayer
                  onStart={this.onPlayVideo}
                  url={this.state.selectedLesson.videoUrl}
                  controls={true}
                  className='react-player'
                  width='100%'
                  height='100%'
                />
              </div>
              <div style={styles.belowVideo}>
                <div style={styles.aboutRow}>
                  <div style={styles.about}>About</div>
                  <div style={styles.aboutRight}></div>
                </div>
                <div style={styles.markdownTextColor}>
                  <ReactMarkdownWithHtml
                    children={this.state.selectedLesson.description}
                    className={isMobile ? 'markdownCourseDescMobile' : 'markdownCourseDesc'}
                    allowDangerousHtml
                  />
                </div>
              </div>
            </div>
            <div style={styles.right}>
              <div style={styles.lessonColScroll}>
                {this.state.course.lessons.map((l) => {
                  return (
                    <LessonItem
                      lesson={l}
                      onClick={() => this.onClickLesson(l)}
                      isSelected={this.state.selectedLesson.id === l.id}
                      isSmall={true}
                      infoType={"progress"}
                      status={getLessonStatus(this.state.course.id, l.id, this.props.courseStatus)}
                    />
                  );
                })}
              </div>
            </div>
            {isMobile ? null : <div style={{minWidth: 20}} />}
          </div>
        </div>
      </PageTemplate>
    );
  }
}


const MAX_left_width = 1200;
const MIN_left_width = 600;

const styles = {
  page: {
    overflow: 'auto',
    height: '100%',
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    // padding: isMobile ? 10 : 20,
    flexWrap: isMobile ? "wrap" : undefined,
    paddingTop: 0,
    padding: isMobile ? 10 : 0
    // maxWidth: 1000
  },
  breadcrumbWrapper: {
    padding: isMobile ? 10 : 20,
    paddingBottom: 0,
    paddingTop: isMobile ? 20 : 20,

  },
  top: {
    padding: isMobile ? 10 : 20,
    paddingBottom: 8,
    paddingTop: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: isMobile ? "wrap" : undefined,
    width: isMobile ? '100%' : '100%'
    // justifyContent: 'space-between',
  },
  topLeft: {
    position: "relative",
    flex: 1,
    maxWidth: MAX_left_width,
    minWidth: isMobile ? undefined : MIN_left_width
  },
  left: {
    // flex: isMobile ? 1 : .5,
    position: "relative",
    // minWidth: isMobile ? "100%" : 640,
    paddingBottom: isMobile ? 15 : 0,
    flex: 1,
    maxWidth: MAX_left_width,
    minWidth: isMobile ? undefined : MIN_left_width
    // flexBasis: isMobile ? '100%' : undefined,
    // paddingRight: isMobile ? 0 : 20
  },
  topRight: {
    minWidth: isMobile ? "100%" : 415,
    display: "flex",
    flexDirection: "row",
    justifyContent: isMobile ? "flex-start" : "flex-end",
    paddingLeft: isMobile ? 0 : 15,
    paddingBottom: isMobile ? 10 : 0,
  },
  right: {
    // flex: 1,
    // flexBasis: isMobile ? '100%' : undefined,
    position: "relative",
    paddingLeft: isMobile ? 0 : 15,
    width: isMobile ? '100%' : undefined
  },
  lessonColScroll: {
    width: isMobile ? "100%" : 400,
  },
  player: {
    // width: isMobile ? '100%' : undefined
  },
  playerWrapper: {
    // backgroundColor: 'red',
    // overflow: "hidden",
    // borderRadius: 10,
    // boxShadow: "0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)",
    // width: '100%',
    // position: "relative",
    // height: 500
    position: 'relative',
    paddingTop: '56.25%',
    width: '100%'
  },

  aboutRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 50,
    alignItems: 'flex-end',
    marginBottom: 20

  },
  about: {
    width: 150,
    color: '#007fff',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    textAlign: 'center',
    borderBottomWidth: 2,
    borderBottomColor: '#007fff',
    borderBottomStyle: 'solid',
    fontWeight: 'bold',
    paddingBottom: 3,
    fontSize: 14
  },
  aboutRight: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#C9CED6',
    // borderBottomColor: Colors.lightGrey,
    borderBottomStyle: 'solid'
  },
  markdownTextColor: {
    color: '#8a94a6',
    fontSize: 14
  }
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  courseStatus: state.courseStatus
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
};

export default connect(mapStateToProps, mapActionsToProps)(ViewCoursePage);

import React, { Component } from "react";
import Colors from "../../assets/Colors";
import { withRouter } from "react-router-dom";
import { updateLoggedInUser, toggleNav, setAvailableLanguages, selectLanguage, updateArticles, articlesNeedReload, fetchWordListThunk } from "../../redux/actions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import getFlagImageName from "../../scripts/getFlagImageName";
import NavItem from './NavItem';

const languagesWithExtraSections = ['spanish'];

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  blueIcons = {
    book: require("../../assets/img/blue/book.png"),
    comment: require("../../assets/img/blue/comment.png"),
    facebook: require("../../assets/img/blue/facebook.png"),
    clock: require("../../assets/img/blue/clock.png"),
    heart: require("../../assets/img/blue/heart.png"),
    stack: require("../../assets/img/blue/stack.png"),
    settings: require("../../assets/img/blue/settings.png"),
    logout: require('../../assets/img/logout.png'),
    course: require('../../assets/img/blue/course.png'),
    podcast: require('../../assets/img/blue/podcast.png'),
  };

  greyIcons = {
    book: require("../../assets/img/grey/book.png"),
    comment: require("../../assets/img/grey/comment.png"),
    facebook: require("../../assets/img/grey/facebook.png"),
    community: require("../../assets/img/grey/community.png"),
    clock: require("../../assets/img/grey/clock.png"),
    heart: require("../../assets/img/grey/heart.png"),
    settings: require("../../assets/img/grey/settings.png"),
    // logout: require("../../assets/img/grey/logout.png"),
    stack: require("../../assets/img/grey/stack.png"),
    podcast: require("../../assets/img/grey/podcast.png"),
    logout: require('../../assets/img/logout.png'), 
    course: require('../../assets/img/grey/course.png'), 
    podcast: require('../../assets/img/grey/podcast.png'), 
  };

  logoutUser = () => {
    this.props.toggleNav("");
    fetch("/logout", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      // .then(result => result.json())
      .then(result => {
        this.props.onUpdateLoggedInUser(null);
      });
  }


  componentWillUpdate(nextProps) {
    //close translation popover when this side menu is opened. because the popover zIndexes over the menu
    if (this.props.closePopover && this.props.navState == "" && nextProps.navState == "toggled") this.props.closePopover();
  }

  handleSelectLanguage = (language) => {
    fetch("/user/setlanguage/" + language, {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache"
      }
    })
      .then(result => result.json())
      .then(result => {
        this.props.selectLanguage(language);
        this.props.fetchWordListThunk(language);
        this.reloadArticles();
        if(this.props.location.pathname.includes("/read-story")){
          this.props.history.push('/stories');
        }
      });
  }

  reloadArticles = () => {
    let route = this.props.loggedInUser ? "/article/getall" : "/article/getallfree";
    fetch(route, {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache"
      }
    })
      .then(result => result.json())
      .then(result => {
        if (result.status == "success") {
          this.props.updateArticles(result.articles, result.categories);
          this.props.articlesNeedReload(false);
        } else {
          if (!isMobile) alert("could not get articles. check your internet connection");
        }
      });
  }

  toggleNav = () =>  {
    //track where page is scrolled to in redux and re-scroll here?
    this.props.toggleNav(this.props.navState == "toggled" ? "" : "toggled");
  }

  handleLinkClicked() {
    if (isMobile) {
      setTimeout(() => this.props.toggleNav(""), 200);
    }
  }

  render() {
    if (!this.props.loggedInUser) return null;

    let route = this.props.match.path;
    let flagName = getFlagImageName(this.props.selectedLanguage);
    let flag = <img style={styles.flag} src={require(`../../assets/img/flags/${flagName}.png`)} />;
    let collapsed = this.props.navState === "toggled" ? false : true;
    let isPodcast = this.props.podcastMeta.isPodcast;

    let fakeInputRowStyle = {
      textAlign: collapsed ? "left" : "center",
      position: "absolute",
      bottom: 20,
      paddingBottom: isMobile ? 20 : 0
    };
    if (collapsed) fakeInputRowStyle.left = 17;


    return (
      <div id="sidebar-wrapper" style={styles.wrapper}>
        <ul class="sidebar-nav" style={{ minHeight: isMobile ? undefined : "100%", position: "relative", paddingBottom: 90 }}>
          {this.props.navState === "toggled" ? (
            <div onClick={this.toggleNav.bind(this)} style={styles.bluexWrapper}>
              <img src={require("../../assets/img/blue/close.png")} style={styles.bluex} />
            </div>
          ) : (
            <div style={styles.navToggleButton} onClick={this.toggleNav.bind(this)}>
              <img src={require("../../assets/img/menu.png")} style={styles.menuIcon} />
            </div>
          )}
          <div style={{ height: 20 }} />
          <NavItem
            route="/stories"
            title="Stories"
            blueIcon={this.blueIcons.book}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.book}
            selected={route}
            collapsed={collapsed}
          />
          <NavItem
            route="/conversations"
            title="Conversations"
            blueIcon={this.blueIcons.comment}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.comment}
            selected={route}
            collapsed={collapsed}
          />
          {/* <NavItem
            link="https://podcast.storyling.com/"
            title="Podcasts"
            blueIcon={this.blueIcons.facebook}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.podcast}
            selected={false}
            collapsed={collapsed}
          /> */}
          <NavItem
            route="/words"
            title="Flashcards"
            blueIcon={this.blueIcons.stack}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.stack}
            selected={route}
            collapsed={collapsed}
          />
          {languagesWithExtraSections.indexOf(this.props.selectedLanguage) > -1 ? 
            <NavItem
              route="/courses"
              title="Courses"
              blueIcon={this.blueIcons.course}
              handleLinkClicked={this.handleLinkClicked.bind(this)}
              greyIcon={this.greyIcons.course}
              selected={route}
              isSelected={route.includes('courses/')}
              collapsed={collapsed}
            />
          :null}
          <NavItem
            route="/favorites"
            title="Favorites"
            blueIcon={this.blueIcons.heart}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.heart}
            selected={route}
            collapsed={collapsed}
          />
          <NavItem
            route="/history"
            title="History"
            blueIcon={this.blueIcons.clock}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.clock}
            selected={route}
            collapsed={collapsed}
          />
          {languagesWithExtraSections.indexOf(this.props.selectedLanguage) > -1 ? 
            // <NavItem
            //   link="https://community.lingomastery.com"
            //   title="Community"
            //   blueIcon={undefined}
            //   handleLinkClicked={this.handleLinkClicked.bind(this)}
            //   greyIcon={this.greyIcons.community}
            //   selected={false}
            //   collapsed={collapsed}
            // />
            <NavItem
            link="https://www.facebook.com/groups/storyling.spanish/"
            title="Community"
            blueIcon={this.blueIcons.facebook}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.facebook}
            selected={false}
            collapsed={collapsed}
          />
          :null}
          {languagesWithExtraSections.indexOf(this.props.selectedLanguage) > -1 ? 
            <NavItem
              link="https://podcast.lingomastery.com"
              title="Podcast"
              blueIcon={this.blueIcons.podcast}
              handleLinkClicked={this.handleLinkClicked.bind(this)}
              greyIcon={this.greyIcons.podcast}
              isSelected={isPodcast}
              selected={isPodcast}
              active={isPodcast}
              collapsed={collapsed}
            />
          :null}
          <NavItem
            route="/settings"
            title="Settings"
            blueIcon={this.blueIcons.settings}
            handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.settings}
            selected={route}
            collapsed={collapsed}
          />
          <NavItem
            onClick={this.logoutUser}
            link='none'
            title="Log Out"
            blueIcon={this.blueIcons.logout}
            // handleLinkClicked={this.handleLinkClicked.bind(this)}
            greyIcon={this.greyIcons.logout}
            selected={false}
            collapsed={collapsed}
          />

{/* 
          {isMobile ? (
            <div>
              <NavItem
                route="/settings"
                title="Settings"
                blueIcon={this.blueIcons.settings}
                handleLinkClicked={this.handleLinkClicked.bind(this)}
                greyIcon={this.greyIcons.settings}
                selected={route}
                collapsed={collapsed}
              />
              <div onClick={() => this.logoutUser()}>
                <NavItem
                  title="Log Out"
                  link="none"
                  greyIcon={this.greyIcons.logout}
                  selected={false}
                  collapsed={collapsed}
                />
              </div>
            </div>
          ) : null} */}

          {this.props.availableLanguages.length < 2  || isPodcast ? null : (
            <div style={fakeInputRowStyle}>
              <span style={styles.fakeInput} onClick={this.props.navState === "" ? () => this.props.toggleNav("toggled") : null}>
                {flag}
                {collapsed ? null : (
                  <span>
                    <select
                      onChange={item => this.handleSelectLanguage(item.target.value.toLowerCase())}
                      value={
                        this.props.selectedLanguage
                          ? this.props.selectedLanguage.charAt(0).toUpperCase() + this.props.selectedLanguage.slice(1)
                          : ""
                      }
                      style={styles.languageSelect}
                    >
                      {this.props.availableLanguages.map((item, index) => {
                        if(!item.isActive && item.title !== this.props.selectedLanguage) return null;
                        return (
                          <option key={item.title + "key"} style={{ paddingRight: 24, zIndex: 2 }}>
                            {item.title ? item.title.charAt(0).toUpperCase() + item.title.slice(1) : ""}
                          </option>
                        );
                      })}
                    </select>
                    <img src={require("../../assets/img/down.png")} style={styles.selectArrow} />
                  </span>
                )}
              </span>
            </div>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage,
  availableLanguages: state.availableLanguages,
  podcastMeta: state.podcastMeta
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
  selectLanguage,
  setAvailableLanguages,
  updateArticles,
  articlesNeedReload,
  fetchWordListThunk
};

export default withRouter(connect(
  mapStateToProps,
  mapActionsToProps
)(SideNav));

const styles = {
  container: {
    position: "absolute",
    height: "100%",
    width: "15%"
  },
  wrapper: {
    backgroundColor: "#fff",
    zIndex: isMobile ? 10 : 1100,
    // zIndex: 10,
    boxShadow: `0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)`
  },
  dropdownItem: {
    cursor: "default"
  },
  languageLabel: {
    color: "#717171",
    fontSize: 13
  },
  languageSelect: {
    border: "none",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    // width: "84%",
    fontSize: 16,
    color: '#a6aebc',
    minWidth: 150
  },
  flag: {
    width: "10%",
    display: "inline",
    marginLeft: 5,
    marginBottom: 1,
    marginRight: 5
  },
  fakeInput: {
    backgroundColor: "#fff",
    // display: 'flex',
    // maxWidth: '80%',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    border: "1px solid "+Colors.lightGrey,
    color: Colors.lightGrey,
    borderRadius: 50,
    padding: 9,
    paddingLeft: 9,
    position: 'relative',
    // height: 32
  },
  selectArrow: {
    maxHeight: 28,
    position: 'absolute',
    right: 7,
    top: 5,
    zIndex: 1,
    pointerEvents: 'none',
    transform: 'rotate(180deg)'
  },
  navToggleButton: {
    padding: 5,
    position: 'absolute',
    top: 12,
    left: 24
  },
  menuIcon: {
    height: 24,
    width: 24,
    cursor: 'pointer'
  },
  bluex: {
    height: 15,
    width: 15,
  },
  bluexWrapper: {
    cursor: 'pointer',
    padding: 7,
    position: 'absolute',
    right: 18,
    top: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};


import React, { Component } from "react";
import $ from "jquery";
import ReactMarkdown from "react-markdown";
import translate from "../../scripts/translate";
import { FadeLoader } from "react-spinners";
import addFlashcard from "../../scripts/addFlashcard";
import { CSSTransition } from "react-transition-group";
import LightButton from "../buttons/LightButton";
// import '../../node_modules/bootstrap/dist/js/bootstrap';


class HighlightWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translation: "",
      loadingTranslation: false,
      selectedText: ""
    };
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleCreateFlashcard() {
    if (this.state.translation.length == 0 || !this.state.selectedText) return;
    addFlashcard(this.state.selectedText, this.state.translation, this.props.language).then(result => {
      $("#highlightModal").modal("hide");
    });
  }

  handleMouseUp() {
    this.setState({ loadingTranslation: true });
    let selectedText = this.getSelectedText().replace(/\n\r?/g, "<br />"); //convert to BR before transaltion so google will preserve it.
    translate(selectedText, this.props.language).then(translationRes => {
      console.log(translationRes.translation);
      translationRes.translation = translationRes.translation.replace(/<br\s*[\/]?>/gi, "\n"); // convert BRs back to line breaks after translation
      this.setState({
        loadingTranslation: false,
        translation: translationRes.translation,
        selectedText: selectedText.replace(/<br\s*[\/]?>/gi, "\n")
      });
    });
  }

  getSelectedText() {
    if (window.getSelection()) return window.getSelection().toString();
  }

  removeMarkdown(text) {
    text = text.replace(/\n\r?/g, "<br />"); //initially convert all line breaks to BR so we can get them when highlighting
    text = text.replace(/\*/g, ""); //remove asterisk from markdown
    return text;
  }

  render() {
    if (this.props.show) {
      $("#highlightModal").modal("show");
      this.setState({
        translation: "",
        selectedText: "",
        text: this.props.item ? this.removeMarkdown(this.props.item.text) : ""
      });
      this.props.hide();
    }

    let renderText = this.state.text;
    if (this.state.selectedText) {
      // split selection based on linebreaks and highlight each section individually
      let selectedItems = this.state.selectedText.split(/(\r\n|\r|\n)/g);
      selectedItems.forEach(item => {
        item = item.trim();
        renderText = renderText.replace(item, "<span class='highlight-translation'>" + item + "</span>");
      });
    }

    return (
      <div class="modal fade" tabindex="-1" role="dialog" id="highlightModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">New Flashcard</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style={{ padding: 0 }}>
              <div class="text-muted text-center" style={styles.labelText}>
                Highlight some text to translate it
              </div>
              <p id="coloredSelection" onMouseUp={() => this.handleMouseUp()} style={styles.text} dangerouslySetInnerHTML={{ __html: renderText }} />
              <div style={{ position: "relative", paddingLeft: 15, paddingRight: 15 }}>
                <textarea
                  rows="3"
                  style={styles.translationTextarea}
                  value={this.state.translation}
                  onChange={x => this.setState({ translation: x.target.value })}
                />
                <i
                  class="fas fa-question-circle"
                  style={styles.translationInfo}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Edit the translation, or copy & paste a section from below"
                />
                <div style={styles.fadeLoader}>{this.state.loadingTranslation ? <FadeLoader color={"grey"} /> : null}</div>
              </div>
              <div style={{ padding: 15, marginBottom: 10 }}>
                <div class="collapse" id={"highlightWindowTranslation"} style={styles.entireTranslation}>
                  <ReactMarkdown source={this.props.item ? this.props.item.translation : ""} style={{marginBottom: 0}} />
                </div>
                <span style={{float: 'right'}}>
                  <LightButton
                    onClick={() => this.handleCreateFlashcard()}
                    text="Create Flashcard"
                    imgSrc={require('../../assets/img/blue/dark/check.png')}
                  />
                </span>
                <div class="fadeHover" style={styles.viewTranslation} data-toggle="collapse" href={"#highlightWindowTranslation"}>
                  <i class="fas fa-caret-down" /> Show entire translation
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  icon: {
    marginRight: 10
  },
  translationTextarea: {
    background: "#ededed",
    border: 0,
    padding: 7,
    paddingRight: 20,
    borderRadius: 2,
    width: "100%",
    marginBottom: 15,
    position: "relative",
    whiteSpace: "pre-line"
  },
  text: {
    padding: 15,
    marginBottom: 10,
    paddingTop: 8,
    whiteSpace: "pre-wrap"
  },
  labelText: {
    width: "100%",
    fontSize: 14,
    marginBottom: 0,
    opacity: 0.7
  },
  viewTranslation: {
    color: "#bbbbbb",
    display: "inline-block"
  },
  entireTranslation: {
    //the paragraph that expands on the bottom
    opacity: 0.7,
    fontStyle: "italic"
  },
  fadeLoader: {
    position: "absolute",
    top: 7,
    left: "44%",
    opacity: 0.3
  },
  translationInfo: {
    position: "absolute",
    top: 4,
    right: 20,
    opacity: 0.3,
    cursor: "regular"
  }
};

export default HighlightWindow;

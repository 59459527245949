import React, { Component } from 'react';

class SelectAuthorizedLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: []
    };
  }

  componentDidMount(){
    this.setAllLanguagesToState();
  }

  setAllLanguagesToState(){
    fetch('/languages/get/all', {
      method: 'GET',
      credentials: 'same-origin',
      headers: {Accept: 'application/json','Content-Type': 'application/json'},
    })
    .then(result => result.json())
    .then(result => {
      this.setState({languages: result.languages});
    })
  }

  handleChange(e){
    this.props.handleChange(e.target.value);
  }

  render() {
    let { selectedLanguage } = this.props;
    return(
      <select class="form-control" onChange={e => this.handleChange(e)} value={selectedLanguage}>
          <option value={'all'} selected={selectedLanguage==='all' ? true : false}>
            all
          </option>
        {this.state.languages.map(item => {
          let itemLang = item.title;
          return(
            <option value={itemLang} selected={selectedLanguage===itemLang ? true : false}>
              {itemLang}
            </option>
          )
        })}
    </select>
    );
  }
}

const styles = {

};

export default SelectAuthorizedLanguage;
import React, { Component } from "react";
import AnimateHeight from "react-animate-height";
import { toggleNav, updateLoggedInUser } from "../../../redux/actions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

//Components
import LoadingOverlay from "../../../components/LoadingOverlay";
import Colors from "../../../assets/Colors";
import PageTemplate from "../../PageTemplate";
import LessonItem from "../../../components/courses/LessonItem";
import Breadcrumbs from "../../../components/courses/Breadcrumbs";
import SkillPill from "../../BrowsePage/components/SkillPill";
import LabelIcon from "../../../components/courses/LabelIcon";
import { getLessonStatus } from "../../../helpers/courses";
import CourseDetailsHeader from "../../../components/courses/CourseDetailsHeader";

class CourseLessonsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      course: {
        lessons: []
      }
    };
  }

  componentDidMount(){
    this.loadCourse()
  }

  loadCourse = () => {
    let courseId = this.props.match.params.id;
    global.apiRequest({ url: `/courses/get/${courseId}` }).then((res) => {
      this.setState({ course: res.course, loading: false }, () => this.sortCourse());
    });
  };

  sortCourse = () => {
    let newLessons = [...this.state.course.lessons];
    newLessons.sort((a, b) => a.sortIndex > b.sortIndex);
    newLessons = newLessons.map(l => {
      // let newSections = [...l.sections];
      // newSections.sort((c, d) => c.sortIndex > d.sortIndex);
      return({
        ...l,
        // newSections
      })
    })
    this.setState({
      course: {
        ...this.state.course,
        lessons: newLessons
      }
    })
  }

  onClickLesson = lesson => {
    this.props.history.push(`/courses/${this.state.course.id}?selectlessonid=${lesson.id}`)
  }

  render() {
    return (
      <PageTemplate>
        <LoadingOverlay hide={!this.state.loading}/> 
        <div style={styles.body}>
          <Breadcrumbs course={this.state.course} />
          <div style={{height: 30}} />
          <CourseDetailsHeader course={this.state.course} />
          <div style={{height: 20}} />

          <div style={styles.modulesDescription}>There are {this.state.course.lessons.length} lessons in this module.</div>
          <div style={{height: 20}} />

          <div style={styles.lessonItemCol}>
            {this.state.course.lessons.map(l => {
              return <LessonItem lesson={l} onClick={() => this.onClickLesson(l)} status={getLessonStatus(this.state.course.id, l.id)} showLessonLabel={true}/>
            })}
            <div style={{height: 50}} />
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: isMobile ? 10 : 20
    // alignItems: 'center',
  },
  lessonItemCol: {
    width: isMobile ? '100%' : 800,
    overflowY: 'auto'

  },

};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
};

export default connect(mapStateToProps, mapActionsToProps)(CourseLessonsPage);

import React, { Component } from "react";

const icons = {
  play: require("../../../src/assets/img/play.png"),
  clock: require("../../../src/assets/img/clock.png"),
};

class LabelIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { iconName, label } = this.props;
    return (
      <span style={styles.wrapper}>
        <img src={icons[iconName]} style={styles.icon} />
        <span style={styles.label}>{label}</span>
      </span>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 14
  },
  icon: {
    height: 18,
    width: 18,
    marginRight: 5
  },
  label: {
    fontSize: 13,
    paddingTop: 1,
    color: '#8a94a6'
  }
};

export default LabelIcon;

import React, { Component } from 'react';

// NOT CURRENTLY IN USE

class FreeStoriesModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <div>
                <div className="modal fade" id="FreeStoriesModal" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            {/* <iframe src='https://storyling.clickfunnels.com/optin22669949'  height='400' frameBorder='0'></iframe> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    profileText: {
        // textAlign: 'center'
    },
    icon: {
        marginRight: 10,
    }
}

export default FreeStoriesModal;
import React from "react";
import { FacebookButton, TwitterButton, GooglePlusButton, LinkedInButton  } from "react-social";

const Socials = ({}) => {
  return (
    <div  style={{zIndex: 99}}>
      <div>
        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location} target="_blank">
          <img class="fadeHover" title="Share!" style={styles.socialIcon} src={require("../assets/img/social/facebook.png")} />
        </a>
        <TwitterButton element="span">
          <img class="fadeHover" title="Share!" style={styles.socialIcon} src={require("../assets/img/social/twitter.png")} />
        </TwitterButton>
        {/* <GooglePlusButton element="span">
          <img class="fadeHover" title="Share!" style={styles.socialIcon} src={require("../assets/img/social/google-plus.png")} />
        </GooglePlusButton> */}
        <LinkedInButton element="span">
          <img class="fadeHover" title="Share!" style={styles.socialIcon} src={require("../assets/img/social/linkedin.png")} />
        </LinkedInButton>
      </div>
    </div>
  );
};

const styles = {
  //SOCIAL ICONS
  socialIconsWrapper: {
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    marginTop: 40,
    marginBottom: 0
  },
  socialIcon: {
    height: 32,
    width: 32,
    marginRight: 7,
     zIndex: 5
  }
};

export default Socials;

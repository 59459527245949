import React, { Component } from 'react';

class DeckEmptyMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({showMessage: true})
    }, 1000);
  }

  render() {
    const { showMessage } = this.state;
    return(
      <div style={styles.wrapper}>
        {showMessage ? <div style={styles.message}>Deck empty</div> : null}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    flex :1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    opacity: .5,
    textAlign: 'center',
    marginTop: 150
  }
};

export default DeckEmptyMessage;
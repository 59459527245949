import React, { Component } from 'react';
import MyNavbar from '../../components/MyNavbar';
import SideNav from '../../components/SideNav';
import { toggleNav } from '../../redux/actions';
import { connect } from 'react-redux';

class DashboardPage extends Component {
    constructor(props){
        super(props);
        this.state={
            
        }
    }

    toggleNav(){
        this.props.toggleNav(this.props.navState=='toggled' ? '' : 'toggled');
    }

    render() {
        return (
            <div>
                <MyNavbar />
                <div id='sideNavWrapper' class={this.props.navState}>
                    <SideNav />
                    <div class='container'>
                        <h1>Dashboard</h1>
                        <p>may not need this user dashboard page, in that case it will link directly to /browse</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    navState: state.navState
})

const mapActionsToProps = {
    toggleNav: toggleNav //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(DashboardPage);

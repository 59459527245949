import store from "../redux/store";
import * as types from "../redux/types";

export function loadCourseStatus() {
  return new Promise(resolve => {
    global.apiRequest({
      url: `/courses/usercourses/getall`
    }).then(res => {
      res.userCourses.forEach(c => {
        store.dispatch({
          type: types.HYDRATE_COMPLETE_LESSONS,
          payload: {courseId: c.CourseId, completedLessonIds: c.completedLessonIds}
        })
        store.dispatch({
          type: types.HYDRATE_IN_PROGRESS_LESSONS,
          payload: {courseId: c.CourseId, inProgressLessonIds: c.inProgressLessonIds}
        })
      })
      // res.userCourses
    })
    resolve()
  })
}

export function markLessonComplete(courseId, lessonId) {
  let canAdd = true;
  let existingCourse = store.getState().courseStatus.courses[courseId] || {};
  if(existingCourse.completedLessonIds){
    if(existingCourse.completedLessonIds.indexOf(lessonId) > -1){
      canAdd = false
    }
  }
  if(canAdd){
    global.apiRequest({
       url: `/courses/lessons/markcomplete`,
       method: 'POST',
       body: {courseId, lessonId}
      }).then(res => {})
    store.dispatch({
      type: types.MARK_LESSON_COMPLETE,
      payload: {courseId, lessonId}
    })
  }
}

export function markLessonIncomplete(courseId, lessonId) {
  global.apiRequest({
    url: `/courses/lessons/markincomplete`,
    method: 'POST',
    body: {courseId, lessonId}
  }).then(res => {})
  store.dispatch({
    type: types.MARK_LESSON_INCOMPLETE,
    payload: {courseId, lessonId}
  })
}

export function markLessonInProgress(courseId, lessonId){
  let canAdd = true;
  let existingCourse = store.getState().courseStatus.courses[courseId] || {};
  if(existingCourse.inProgressLessonIds){
    if(existingCourse.inProgressLessonIds.indexOf(lessonId) > -1){
      canAdd = false
    }
  }
  if(canAdd){
    store.dispatch({
      type: types.MARK_LESSON_IN_PROGRESS,
      payload: {courseId, lessonId}
    })
    global.apiRequest({
      url: `/courses/lessons/markinprogress`,
      body: {courseId, lessonId},
      method: 'POST',
    }).then(res => {})
  }
}

export function getCourseProgress(course={}){
  let progress = 0;
  let courseData = store.getState().courseStatus.courses[course.id];
  if(courseData){
    if(courseData.completedLessonIds){
      courseData.completedLessonIds.forEach(cid => {
        progress += (100/course.lessons.length)
      })
    }
    if(courseData.inProgressLessonIds){
      courseData.inProgressLessonIds.forEach(id => {
        let doesCount = true;
        if(courseData.completedLessonIds){
          if(courseData.completedLessonIds.indexOf(id) > -1){
            doesCount = false
          }
          if(doesCount){
            progress += ((100/course.lessons.length)/2)
          }
        }
      })
    }
  }
  if(progress > 99.9) progress = 100
  return progress;
}

export function getLessonStatus(courseId, lessonId, courseStatus){
  let courses = (courseStatus || store.getState().courseStatus).courses;
  let existingCourse = courses[courseId];
  let res = 'new';
  if(existingCourse){
    if(existingCourse.inProgressLessonIds.indexOf(lessonId)>-1){
      res = 'in_progress'
    }
    if(existingCourse.completedLessonIds.indexOf(lessonId)>-1){
      res = 'complete'
    }
  }
  return res;
}

export function getLengthStr(len){
  let lenStr = '';
  if(len){
    let r = len%60;
    let minutes = (len - r)/60;
    if(minutes > 59){
      let h_r = minutes%60;
      let hours = (minutes-h_r)/60;
      lenStr = `${hours} hr ${h_r} min`
    }else{
      let seconds = r
      if(seconds > 30) minutes = minutes + 1;

      lenStr = `${minutes < 1 ? 1 : minutes} min`
    }
  }
  return lenStr
}


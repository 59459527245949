
export default function apiRequest(options={
  method: 'get',
  url: '',
  body: undefined
}){
  return fetch(options.url, {
    method: options.method,
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: options.body ? JSON.stringify(options.body) : undefined
  }).then((result) => result.json())
}

global.apiRequest = apiRequest;
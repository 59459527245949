import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { updateLoggedInUser, toggleNav } from "../../redux/actions";
import { isMobile, isBrowser } from "react-device-detect";
import FreeStoriesModal from "../../components/modals/FreeStoriesModal";
import { withRouter } from "react-router";
import Search from "./Search";

class MyNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.checkIfLoggedIn();
  }

  handleLogoLink() {
    if (this.props.podcastMeta.isPodcast) {
      window.location.href = "https://members.lingomastery.com";
    } else {
      if (this.props.loggedInUser) {
        this.props.history.push("/stories");
      } else {
        window.location = "https://www.lingomastery.com";
      }
    }
  }

  handleMenuPress() {} //dont delete this empty function i forget why


  toggleNav() {
    //track where page is scrolled to in redux and re-scroll here?
    this.props.toggleNav(this.props.navState == "toggled" ? "" : "toggled");
  }

  render() {
    let { showSearch } = this.props;
    const { isPodcast } = this.props.podcastMeta;

    let menuButton =
      this.props.loggedInUser ? (
        <div style={styles.navToggleButton} onClick={this.toggleNav.bind(this)}>
          <img src={require("../../assets/img/menu.png")} style={styles.menuIcon} />
        </div>
      ) : null;

    let avatarSection =
      this.props.loggedInUser && !isMobile ? (
        <div style={{ position: "absolute", right: 15 }}>
          {showSearch ? <Search /> : null}
          <span class="dropdown">
            {/* <a
              class="dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ opacity: 0.7, cursor: "pointer" }}
            > */}
              {isMobile ? "Account" : this.props.loggedInUser.email}
            {/* </a> */}
            {/* <div class="dropdown-menu" aria-labelledby="navbarDropdown"> */}
              {/* {isPodcast ? null : (
                <div class="dropdown-item">
                  <Link to="/settings" style={{ textDecoration: "none", color: "#212529" }}>
                    <span>Settings</span>
                  </Link>
                </div>
              )} */}

              {/* <div class="dropdown-item" style={{ cursor: "pointer" }} onClick={this.logoutUser.bind(this)}>
                Log out
              </div>
            </div> */}
          </span>
        </div>
      ) : null;

    let logoLeftMargin = 0;
    if (isBrowser) {
      logoLeftMargin = this.props.navState === "toggled" ? 200 : 30;
    }

    return (
      <div>
        <FreeStoriesModal />
        <nav style={styles.navbar} class="navbar navbar-expand-md bg-white navbar-light fixed-top">
          <a class="navbar-brand" style={{ cursor: "pointer", margin: 0, padding: 0 }}>
            {menuButton}
            <span onClick={this.handleLogoLink.bind(this)}>
              <img
                src={require("../../assets/img/logo-header.png")}
                style={{ height: 34, marginLeft: logoLeftMargin, transition: ".3s" }}
              />
            </span>
          </a>
          {avatarSection}
          {!this.props.loggedInUser ? (
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExample03"
              aria-controls="navbarsExample03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon" />
            </button>
          ) : null}
          <div class="collapse navbar-collapse" id="navbarsExample03">
            {this.props.loggedInUser ? null : (
              <ul class="navbar-nav ml-auto">
                <li class="nav-item" style={{ marginRight: 20 }}>
                  <Link to="/login" class="nav-link">
                    <span class={"nav-link" + isMobile ? "" : "py-0"}>Login</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <div class={"nav-link" + isMobile ? "" : "py-0"}>
                    <a class="btn" style={{backgroundColor: '#00bdff', color: '#fff'}} href="https://www.lingomastery.com/plussp">
                      Sign Up
                    </a>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
    );
  }
}

const styles = {
  navbar: {
    boxShadow: "0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)",
    marginBottom: 0
  },
  navToggleButton: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 22,
    display: "inline"
  },
  menuIcon: {
    height: 24,
    width: 24
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  podcastMeta: state.podcastMeta
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(MyNavbar));

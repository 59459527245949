import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Dropdown, Menu } from "antd";
import moreIcon from '../../../assets/img/more.png';

const defaultColor = "#220597";

class DeckSquare extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMouseEnter = () => {
    this.setState({ showMenu: true });
  };

  onMouseLeave = () => {
    this.setState({ showMenu: false });
  };

  render() {
    const { title = "", list = [], color, onClick, onDelete, onRenameDeck, isLessonDeck } = this.props;

    const dropdownMenu = (
      <Menu style={{ width: 130 }}>
        <Menu.Item key="3" onClick={e => onRenameDeck(e.domEvent)}>
          Edit Name
        </Menu.Item>
        <Menu.Item key="3" onClick={e => onDelete(e.domEvent)}>
          Delete Deck
        </Menu.Item>
      </Menu>
    );

    return (
      <div
        style={{ ...styles.wrapper, backgroundColor: color || defaultColor }}
        class="fadeHover"
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {this.state.showMenu && title !== 'General' && !isLessonDeck ? (
          <div style={styles.menuWrapper}>
            <Dropdown overlay={dropdownMenu}>
              <img
                style={styles.closeIcon}
                // onClick={() => removeWord(index)}
                // class="fadeHoverReverse"
                src={moreIcon}
              />
            </Dropdown>
          </div>
        ) : null}
        <div style={styles.title}>{title.toUpperCase()}</div>
        <div style={styles.subtitle}>{list.length} card{list.length===1 ? '' : 's'}</div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    height: isMobile ? 80 : 150,
    width: isMobile ? "49%" : 200,
    marginRight: isMobile ? 0 : 20,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 12,
    cursor: "pointer",
    marginBottom: isMobile ? 10 : 15,
    position: "relative",
    minHeight: isMobile ? 120 : undefined,
    boxShadow: "0 5px 12px 0 rgba(0, 0, 0, 0.15)",
  },
  title: {
    color: "#fff",
    fontSize: 18,
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    color: "#fff",
    textAlign: "center",
    fontSize: 12,
  },
  closeIcon: {
    // float: 'right',
    // height: 34,
    width: 29,
    padding: 5,
    zIndex: 3,
    cursor: "pointer",
    // margin: "auto",
    // position: "absolute",
    // right: 4,
    // top: 18,
    // opacity: 0.4,
    filter: 'brightness(0) invert(1)'
  },
  menuWrapper: {
    position: "absolute",
    top: 4,
    right: 12,
  },
};

export default DeckSquare;

import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { PulseLoader, ClipLoader } from 'react-spinners';
import { Link, Redirect } from 'react-router';
import { toggleNav, updateLoggedInUser } from '../redux/actions';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import translate from '../scripts/translate';
import ReactDOM from 'react-dom';
import Colors from '../assets/Colors';
import getDictionaryLink from '../scripts/getDictionaryLink';
import addFlashcard from '../scripts/addFlashcard';
import { getGeneralDeckId, fetchDecks, modifyDeck } from '../helpers/decks';

const initialState = {
  savingLoading: false,
  addedWord: false,
  isPossibleToRemove: true
}

class TranslationPopover extends Component {
  constructor(props) {
    super(props);
    this.popover = React.createRef();
    this.state = initialState
  }

    handleAddWord(word){ 
      if(this.props.loggedInUser){
        this.setState({savingLoading: true});
        addFlashcard(word, this.props.popoverInfo.translation, this.props.language).then(result => {
          this.setState({
            addedWord: true,
            savedDeckName: this.props.deckInfo.allSavedWordsDeckNames[word],
            savingLoading: false,
            isPossibleToRemove: false
          })
          setTimeout(() => {
            this.setState({isPossibleToRemove: true})
          }, 500);
          fetchDecks()
        })
      }else{
        alert('Sign up for an account to use this feature!')
      }
    }

    removeWord = (word='') => {
      let deckName = this.props.deckInfo.allSavedWordsDeckNames[word];
      let deckId = null;
      Object.values(this.props.deckInfo.decks).forEach(d => {
        if(d.title === deckName) deckId = d.id
      })
      let newDeck = this.props.deckInfo.decks[deckId];
      newDeck.list = newDeck.list.filter(x => x.word !== word);
      modifyDeck(newDeck).then(res => {
        fetchDecks();
        this.onMouseLeaveAddButton()
        this.props.toggleSelf()
      })
    }

    onMouseEnterAddButton = () => {
      if(this.state.addedWord === true && this.state.isPossibleToRemove) this.setState({showRemove: true})
    }

    onMouseLeaveAddButton = () => {
      this.setState({showRemove: false});
    }

    componentWillReceiveProps(nextProps){
      if(this.props.open && !nextProps.open){
        // reset state, then make changes after that
        this.setState(initialState, () => {
          this.checkWordAdded(nextProps)
        })
        
      }else{
        this.checkWordAdded(nextProps)
      }
    }

    checkWordAdded = (nextProps) => {
      if((nextProps.popoverInfo.word != this.props.popoverInfo.word) || (nextProps.open && !this.props.open)){
        if(this.isWordAlreadyAdded(nextProps.popoverInfo.word)){
          this.setState({
            addedWord: true,
            savedDeckName: this.props.deckInfo.allSavedWordsDeckNames[nextProps.popoverInfo.word]
          });          
        }else{
          this.setState({addedWord: false});
        }
      }
    }

    isWordAlreadyAdded(word){
      if(this.props.loggedInUser){
        return this.props.deckInfo.allSavedWords[word] === true;
      }else{
        return false;
      }
    }

    render() {
      let popoverAddIcon = <span><img src={require('../assets/img/addword.png')} style={{width: 24, height: 24}} /> Save word</span>;
      if(this.state.addedWord == true) popoverAddIcon = <span><i class='fa fa-check' style={{ color: '#82ca1c' }}></i> Saved <span style={styles.savedDeckName}>in "{this.state.savedDeckName || 'General'}"</span></span>;
      if(this.state.savingLoading == true) popoverAddIcon = <span style={{display: 'inline-block'}}><PulseLoader size={6} color={'grey'} /></span>;
      let hoverRemoveWord = (
        <span style={styles.removeWordPill}>
          <span style={styles.removeWordText}>Remove Word</span>
        </span>
      )
      
      if(!this.props.open) return null;

      let partOfSpeech = (((this.props.popoverInfo.meanings || [])[0]) || {}).partOfSpeech; // there can be many here, but i am just rendering one for now
      // let synonyms = ((((((this.props.popoverInfo.meanings || [])[0]) || {}).definitions || [])[0] || {}).synonyms ||[]).slice(0,3);
      let synonyms_str = '';
      try{
        let synonyms = this.props.popoverInfo.meanings[0].synonyms || [];
        synonyms.forEach((s, index) => {
          synonyms_str += `${s}${index === synonyms.length-1 ? '' : ', '}`
        })
      }catch(e){}

      let hasAlternateMeanings = false;
      this.props.popoverInfo.translations.forEach(w => {
        if(w.text.toLowerCase() !== this.props.popoverInfo.translation.toLowerCase()) hasAlternateMeanings = true;
      })


      return (
        <div ref={this.popover}>
          <Popover placement={isMobile ? 'top' : 'top'}  style={{minWidth: 200, boxShadow: '0 3px 12px 0 rgba(0, 0, 0, 0.18)'}} isOpen={this.props.open} target={this.props.popoverInfo.id} toggle={this.props.toggleSelf} placement={'bottom'}>
            <PopoverHeader style={{backgroundColor: '#fff'}}>
              {this.props.hideSave ? <span style={{height: 20, color: 'transparent'}}>{'.'}</span> : 
              <span
              class='fadeHover'
              style={{color: '#82ca1c', fontSize: 14, fontWeight: 'bold'}} 
              onClick={() => this.state.showRemove===true ? this.removeWord(this.props.popoverInfo.word) : (this.state.addedWord ===  true ? null : this.handleAddWord(this.props.popoverInfo.word))}
              onMouseEnter={this.onMouseEnterAddButton}
              onMouseLeave={this.onMouseLeaveAddButton}
              >
                {this.state.showRemove ? hoverRemoveWord : popoverAddIcon} 
              </span>
              }
              <img src={require('../assets/img/close.png')} style={{width: 20, height: 20, float: 'right', marginLeft: 10}} onClick={() => this.props.toggleSelf()} class='fadeHoverReverse'/>
            </PopoverHeader>
            <PopoverBody style={{padding: 14}}>
              <div>
                <span style={{color: '#5e5e5e', fontSize: 16}}>{this.props.popoverInfo.word}</span>
                <a href={this.props.popoverInfo.translation ? getDictionaryLink(this.props.popoverInfo.word, this.props.language) : null} target='_blank' class='fadeHover' style={{float: 'right'}} id='defineWordLink'>
                  <img src={require('../assets/img/link.png')} style={{width: 24, height: 24, opacity: .6}} />
                </a>
                {this.props.popoverInfo.loading || !this.props.popoverInfo.translation ? 
                  <div><PulseLoader color='#ededed' /></div>
                : null}
                <div style={styles.translationRow}>
                  <div style={styles.popoverTranslation}>{this.props.popoverInfo.translation}</div>
                  <div style={styles.partOfSpeech}>{partOfSpeech}</div>
                </div>

                {hasAlternateMeanings ?
                  <div class="fadeHover" style={styles.moreButton} data-toggle="collapse" href={"#alternateDefinitions"}>
                    <i class="fas fa-caret-down" style={{marginRight: 2}}/> More meanings...
                  </div>
                :null}

                <div class="collapse" id={"alternateDefinitions"} style={styles.alternateDefinitions}>
                  {this.props.popoverInfo.translations.length>0 ? this.props.popoverInfo.translations.map(t => {
                    if(t.text.toLowerCase() === this.props.popoverInfo.translation.toLowerCase()) return null;
                      return(
                        <div style={styles.alternateTranslationsRow}>
                          <span style={styles.alternateTranslation}>{t.text}</span>
                          <span style={styles.alternatePartOfSpeech}>{t.partOfSpeech}</span>
                        </div>
                      )
                    })
                  :null}
                </div>


              </div>
            </PopoverBody>
          </Popover>
        </div>
      );
    }
}

const styles = {
  popoverCover: {
    position: 'absolute',
    top:0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity:.5,
    alignItems: 'center'
  },
  popoverTranslation: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#000',

  },
  translationRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 8

  },
  partOfSpeech: {
    marginLeft: 15,
    color: Colors.lighterText,
    // fontSize: 12,
    fontWeight: 200,
    // fontStyle: 'italic'
  },
  synonyms: {
    color: Colors.lighterText,
    borderTop: '1px solid '+Colors.lightGrey,
    paddingTop: 8,
  },
  savedDeckName: {
    fontWeight: 'normal',
    color: Colors.lighterText,
    // fontSize: 12,
  },
  alternateTranslationsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 3,
    marginBottom: 3,
    width: '100%'
  },
  alternateTranslation: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#919191'
  },
  alternatePartOfSpeech: {
    color: Colors.lighterText
  },
  removeWordPill: {
    // padding: 3,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.red,
    display: 'inline-block',
    paddingLeft: 5,
    paddingRight: 5

  },
  removeWordText: {
    color: '#fff',
    textAlign: 'center'
  },
  moreButton: {
    opacity: .4,
    borderTop: '1px solid '+Colors.lightGrey,
    paddingTop: '8px'
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage,
  deckInfo: state.deckInfo
  // wordList: state.wordList
})

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
}

export default connect(mapStateToProps, mapActionsToProps)(TranslationPopover);

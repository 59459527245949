import React, { Component } from "react";
import AnimateHeight from "react-animate-height";
import { toggleNav, updateLoggedInUser } from "../../../redux/actions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

//Components
import LoadingOverlay from "../../../components/LoadingOverlay";
import Colors from "../../../assets/Colors";
import PageTemplate from "../../PageTemplate";
import CourseCard from "../../../components/courses/CourseCard/CourseCard";

class CoursesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      loading: true
    };
  }

  componentDidMount(){
    this.loadCourses()
  }

  loadCourses = () => {
    global.apiRequest({ url: `/courses/getall` }).then((res) => {
      res.courses = res.courses.sort((a, b) => {
        return a.createdAt > b.createdAt ? 1 : -1;
      })
      this.setState({
        courses: res.courses,
        loading: false,
      });
    });
  };

  onClickCourse = c => {
    this.props.history.push(`/courses/${c.id}/lessons`)
  }

  render() {
    const { courses } = this.state;
    let shownCourses = courses.filter(x => x.language === this.props.selectedLanguage)

    return (
      <PageTemplate>
        <LoadingOverlay hide={!this.state.loading} />
        <div style={styles.top}>
          <div style={styles.pageTitle}>Our Courses</div>
          <div>Here are our available courses.</div>

        </div>
      
        <div style={styles.courseRow}>
          {shownCourses.map((c) => {
            return <CourseCard onClick={() => this.onClickCourse(c)} course={c} />;
          })}
          <div style={{height: 50}} />
        </div>
     
      </PageTemplate>
    );
  }
}

const styles = {
  wrapper: {

  },

  courseRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    padding: isMobile ? 5 : 15,
    paddingTop: 0,
  },
  pageTitle: {
    fontWeight: 700,
    fontSize: 28,
    color: '#0a1f44',
    paddingTop: 10,
    paddingBottom: 10

  },
  top: {
    padding: isMobile ? 15 : 25,
    paddingBottom: 25
  }
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
};

export default connect(mapStateToProps, mapActionsToProps)(CoursesPage);

import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import CustomSelect from "./CustomSelect";

let options = {
  read: [{ title: "All Stories", value: "any" }, { title: "Read", value: "read" }, { title: "Unread", value: "unread" }],
  sortBy: [
    { title: "Newest First", value: "newest" },
    { title: "Alphabetical", value: "alphabetical" },
    { title: "Most Popular", value: "popular" },
    { title: "Hot", value: "hot" },
  ],
  filterDifficulty: [
    { title: "Any Difficulty", value: "any" },
    { title: "Newbie", value: "newbie" },
    { title: "Beginner", value: "beginner" },
    { title: "Intermediate", value: "intermediate" },
    { title: "Advanced", value: "advanced" }
  ],
  filterHidden: [
    {title: 'Hidden And Visible', value: 'any'},
    {title: 'Hidden Only', value: true},
    {title: 'Visible Only', value: false},
  ]
};

let Dropdowns = props => {

  let {
    shown_categories,
    sortBy,
    filterRead,
    filterCategory,
    filterDifficulty,
    filterHidden,
    showArticles,
    loggedInUser,
    title,
    setValue,
    showAdminFilter
  } = props;

  let rowStyle = {
    ...styles.row,
    // justifyContent: isMobile ? 'center' : "flex-end",
    justifyContent: 'center',
  };

  return (
    <div class="browseDropdowns" style={rowStyle}>
      {/* {title && !isMobile ? <h3 style={styles.title}>{title}</h3> : null} */}
      {/* <input
        onChange={e => this.handleSearchChange(e)}
        value={this.state.search}
        type="text"
        class="form-control"
        style={styles.searchbox}
        placeholder="Search..."
      /> */}
      {showAdminFilter ? 
        <CustomSelect
          options={options.filterHidden}
          valName={"filterHidden"}
          defaultValue="any"
          onChange={setValue}
          selected={filterHidden}
          admin={true}
        />
      :null}
      {showArticles != "history" ? (
        <CustomSelect
          defaultValue={"newest"}
          valName={"sortBy"}
          options={options.sortBy}
          selected={sortBy}
          onChange={setValue}
        />
      ) : null}
      {loggedInUser && showArticles != "history" ? (
        <CustomSelect
          defaultValue={"any"}
          valName={"filterRead"}
          options={options.read}
          selected={filterRead}
          onChange={setValue}
        />
      ) : null}
      <CustomSelect
        selected={filterDifficulty}
        valName={"filterDifficulty"}
        options={options.filterDifficulty}
        defaultValue={"any"}
        onChange={setValue}
      />
      <CustomSelect
        options={shown_categories}
        valName={"filterCategory"}
        defaultValue="any"
        onChange={setValue}
        selected={filterCategory}
      />
    </div>
  );
};

const styles = {
  row: {
    marginTop: 20,
    marginBottom: 10,
    paddingTop: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    position: "relative"
  },
  title: {
    position: "absolute",
    left: 0,
    top: 5,
    letterSpacing: 0.5,
    fontSize: 24
  }
};

export default Dropdowns;

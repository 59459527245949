import React, { Component } from "react";
import WordRow from "./WordRow";
import Colors from "../../../../assets/Colors";
import { Menu } from "antd";
import { connect } from "react-redux";
const shuffleDeselected = require("../../../../assets/img/shuffle.png");
const shuffleSelected = require("../../../../assets/img/shuffle_selected.png");

class WordList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      setParentState,
      removeWord,
      reverse,
      selectedWord,
      setref,
      shuffle,
      selectedTab,
      onSelectTab,
      moveWord,
      copyWord,
      renameWord,
      selectedDeck
    } = this.props;

    

    return (
      <div style={styles.wrapper}>
        <div style={styles.listContainer}>
          <div style={styles.gradient}>
            {/* <img src={require('../../../assets/img/down-arrow.png')} style={styles.gradientArrow} /> */}
          </div>
          <div style={styles.deckSelectRow}>
            <div
              style={selectedTab === "flashcards" ? { ...styles.tab, ...styles.selectedTab } : styles.tab}
              onClick={() => onSelectTab("flashcards")}
            >
              Flashcards
            </div>
            <div
              style={selectedTab === "decks" ? { ...styles.tab, ...styles.selectedTab } : styles.tab}
              onClick={() => onSelectTab("decks")}
            >
              Decks
            </div>
          </div>
          {selectedDeck ? (
            <React.Fragment>
              <div style={styles.topListRow}>
                <div style={{ justifySelf: "flex-start" }}>
                  <div style={styles.topListWordsText}>
                    {selectedDeck.title || "General"} 
                  </div>
                </div>
                <div style={{ justifySelf: "flex-end", display: "flex", alignItems: "flex-end" }}>
                  {selectedDeck.isLessonDeck ? null : 
                      <div style={styles.topListRightButton}>
                      <div data-toggle="modal" data-target="#CustomCardModal">
                        <img src={require("../../../../assets/img/add.png")} width="26" style={styles.topListIcon} />
                      </div>
                    </div>
                  }
                  <div style={styles.topListRightButton}>
                    <div onClick={() => setParentState({ reverse: !reverse })}>
                      <img src={require("../../../../assets/img/reverse.png")} width="30" style={styles.topListIcon} />
                    </div>
                  </div>
                  <div style={styles.topListRightButton}>
                    <div onClick={() => setParentState({ shuffle: !shuffle })}>
                      <img src={shuffle ? shuffleSelected : shuffleDeselected} width="30" style={styles.topListIcon} />
                    </div>
                  </div>
                </div>
              </div>
              <div style={styles.wordScroll} id="wordsScroll">
                {(selectedDeck.list || []).map((item, index) => {
                  // console.log("propdecks", this.props.decks);
                  const dropdownMenu = (
                    <Menu style={{ width: 130 }}>
                      {selectedDeck.isLessonDeck ? null : 
                        <Menu.SubMenu title="Move To">
                          {Object.values(this.props.deckInfo.decks).map((d) => {
                            return (
                              <Menu.Item>
                                <div onClick={(e) => moveWord(e, item, d)}>{d.title}</div>
                              </Menu.Item>
                            );
                          })}
                        </Menu.SubMenu>
                      }
                      <Menu.SubMenu title="Copy To">
                        {Object.values(this.props.deckInfo.decks).map((d) => {
                          return <Menu.Item onClick={(e) => copyWord(e.domEvent, item, d)}>{d.title}</Menu.Item>;
                        })}
                      </Menu.SubMenu>
                      {selectedDeck.isLessonDeck ? null : 
                        <Menu.Item key="1" onClick={(e) => renameWord(e.domEvent, item, index)}>
                          Edit
                        </Menu.Item>
                      }
                      {selectedDeck.isLessonDeck ? null : 
                        <Menu.Item key="3" onClick={(e) => removeWord(e.domEvent, item.word)}>
                          Delete
                        </Menu.Item>
                      }
                    </Menu>
                  );
                  return (
                    <WordRow
                      setref={setref}
                      item={item}
                      setGrandParentState={setParentState}
                      index={index}
                      reverse={reverse}
                      selected={item.word === selectedWord.word}
                      removeWord={removeWord}
                      dropdownMenu={dropdownMenu}
                      isLessonDeck={selectedDeck.isLessonDeck}
                    />
                  );
                })}
                <div style={styles.bottomOfListPadding} />
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    flex: 0.28,
    maxHeight: "100%",
    height: "100%",
  },
  totalWordsCount: {
    marginRight: 10,
    marginTop: 10,
    float: "right",
  },
  closeIcon: {
    height: 34,
    width: 34,
    padding: 5,
    zIndex: 3,
    cursor: "pointer",
    margin: "auto",
    position: "absolute",
    right: 4,
    top: 14,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    background: "#fff",
    boxShadow: "0 0 10px 0 rgba(10, 31, 68, 0.1)",
  },
  wordScroll: {
    overflow: "auto",
    maxHeight: "100%",
  },
  topListRow: {
    //top row with words count and buttons
    paddingTop: 12,
    paddingBottom: 12,
    height: 70,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  removeButton: {
    float: "right",
    margin: "auto",
  },
  topListWordsText: {
    display: "block",
    textAlign: "center",
    textAlignVertical: "center",
    alignItems: "center",
    margin: "auto",
    fontWeight: 'bold'
  },
  topListRightButton: {
    display: "block",
    margin: "auto",
    padding: 0,
    marginLeft: 10,
  },
  topListIcon: {
    padding: 5,
    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
  },
  gradient: {
    backgroundImage: "linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))",
    position: "absolute",
    bottom: 0,
    marginTop: -100,
    height: 100,
    width: "100%",
    zIndex: 10,
    pointerEvents: "none",
  },
  gradientArrow: {
    height: 40,
    position: "absolute",
    bottom: 12,
    left: "50%",
    webkitTransform: "translateX(-50%)",
    transform: "translateX(-50%)",
    filter: "invert(10%)",
    zIndex: 2,
    pointerEvents: "none",
  },
  bottomOfListPadding: {
    height: 80,
    width: "100%",
    position: "relative",
    backgroundColor: "transparent",
    zIndex: 99,
  },

  deckSelectRow: {
    display: "flex",
    flexDirection: "row",
    boxShadow: "0 0 10px 0 rgba(10, 31, 68, 0.1)",
    // height: 70
  },
  tab: {
    flex: 0.5,
    borderBottomWidth: 5,
    borderBottomStyle: "solid",
    borderBottomColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "#A7AEBC",
    cursor: "pointer",
    backgroundColor: "#F8F9FB",
    paddingTop: 5,
    height: 65,
  },
  selectedTab: {
    borderBottomColor: "#0D55CF",
    color: "#0D55CF",
  },
};

const mapStateToProps = (state) => ({
  deckInfo: state.deckInfo,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(WordList);

export default function(word, translation, language){
  return new Promise(resolve => {
    fetch('/words/addword', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        word: word,
        language: language,
        translation: translation
      })
    })
      .then(result => result.json())
      .then(result => {
        resolve(result)
      })
  })
}
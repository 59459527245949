import React, { Component } from "react";
import Colors from "../../assets/Colors";
import { isMobile } from 'react-device-detect';

// service logos
const spotify = require("../../assets/img/podcasts/spotify.png");
const itunes = require("../../assets/img/podcasts/itunes.png");
const google = require("../../assets/img/podcasts/google.png");
const stitcher = require("../../assets/img/podcasts/stitcher.png");
const pocketcasts = require("../../assets/img/podcasts/pocketcasts.png");
const rss = require("../../assets/img/podcasts/rss.png");

class LinkButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getImage(name) {
    if (name.toLowerCase().includes("spotify")) return spotify;
    if (name.toLowerCase().includes("itunes")) return itunes;
    if (name.toLowerCase().includes("google")) return google;
    if (name.toLowerCase().includes("stitcher")) return stitcher;
    if (name.toLowerCase().includes("rss")) return rss;
    if (name.toLowerCase().includes("pocketcasts")) return pocketcasts;
  }

  render() {
    let { link, name } = this.props;
    return (
      <a href={link} style={styles.wrapper} target='_blank' >
          <img src={this.getImage(name)} style={styles.logo} height='32'  />
          <div style={styles.col}>
            <div style={styles.listenOn}>LISTEN ON</div>
            <div style={styles.name}>{name}</div>
          </div>
      </a>
    );
  }
}

const styles = {
  wrapper: {
    height: 56,
    position: 'relative',
    borderRadius: 4,
    boxShadow: "0 8px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)",
    backgroundColor: "#ffffff",
    minWidth: isMobile ? 140 : 160,
    paddingRight: isMobile ? 15 : 30,
    marginRight: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: 'pointer',
    textDecoration: 'none',
    marginBottom: 20
  },
  logo: {
    // maxHeight: "100%",
    // maxWidth: 80,
    // width: 30
    // height: 'auto',
    maxHeight: 45,
    marginLeft: 15,
    marginRight: 18
  },
  col: {
    display: "flex",
    flexDirection: "column"
  },
  listenOn: {
    color: Colors.lighterText,
    fontSize: 11,
    textDecoration: 'none'

  },
  name: {
    textDecoration: 'none',
    color: Colors.text,
    marginTop: -1
  }
};

export default LinkButton;

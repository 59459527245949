import React, { Component } from 'react';
import { Button } from 'antd';
import Colors from '../../../assets/Colors';
import Constants from '../../../assets/Constants';
const one_day = 1000*60*60*24

class MobileBottomBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  componentDidMount(){
    setTimeout(() => {
      let ts = localStorage.getItem('mobileDecksBannerTimestamp');
      if(!ts) this.setState({isVisible: true});
      if(ts){
        if(parseInt(ts) < Date.now() - (one_day*30)) this.setState({isVisible: true});
      }
    }, 2000);
  }

  onClickOk = () => {
    this.setState({isVisible: false}) 
    localStorage.setItem('mobileDecksBannerTimestamp', Date.now()+'')
  }

  render() {
    if(this.state.isVisible){
      return(
        <div style={styles.wrapper}>
          <div style={styles.text}>Visit {Constants.appName} Plus on a computer to create custom flashcard decks!</div>
          <div style={styles.button} onClick={this.onClickOk}>Got it</div>
        </div>
      );
    }else{
      return null
    }
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#00bcff',
    position: 'fixed',
    zIndex: 9999,
    bottom: 0,
    width: '100vw',
    height: 200
  },
  text: {
    // marginRight: 20,
    // width: '60vw',
    color: '#fff',
    textAlign: 'center',
    marginBottom: 20
  },
  button: {
    height: 40,
    width: 80,
    borderRadius: 4,
    color: '#fff',
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

export default MobileBottomBanner;
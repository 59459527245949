import React, { Component } from "react";
import MyNavbar from "../../components/MyNavbar";
import { Link, Redirect } from "react-router-dom";
import { updateLoggedInUser } from "../../redux/actions";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import AdminNavbar from "../../components/admin/AdminNavbar";
import Colors from "../../assets/Colors";
import { runInThisContext } from "vm";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      moreIndex: null,
      currentPage: 1
    };
  }

  //-----LIFECYCLE--------------------------------
  componentDidMount() {
    this.getPage(this.state.currentPage);
  }

  //---------METHODS-----------------------------

  getPage(pageNumber) {
    fetch("/logs/" + pageNumber, {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(result => result.json())
      .then(result => {
        if (result.status == "success") {
          console.log(result);
          this.setState({
            logs: result.logs,
            count: result.count,
            pages: result.pages,
            currentPage: pageNumber
          });
        } else {
          alert(result.message);
        }
      });
  }

  handleMoreClick(index) {
    this.setState({ moreIndex: index });
  }

  getPaginationNumbers() {
    let pageNumbers = [];
    for (let i = 0; i < this.state.pages; i++) {
      let buttonStyle = null;
      if (i == this.state.currentPage - 1) {
        buttonStyle = {
          backgroundColor: Colors.main,
          color: "white"
        };
      }

      pageNumbers.push(
        <li class="page-item" onClick={() => this.handlePageClick(i + 1)}>
          <a class="page-link" style={buttonStyle} onClick={() => this.handlePageClick(i + 1)}>
            {i + 1}
          </a>
        </li>
      );
    }
    return pageNumbers;
  }

  handlePageClick(index) {
    this.getPage(index);
  }

  handlePageBack() {
    if (this.state.currentPage == 1) return;
    this.getPage(this.state.currentPage - 1);
  }

  handlePageForward() {
    if (this.state.currentPage == this.state.pages) return;
    this.getPage(this.state.currentPage + 1);
  }

  render() {
    return (
      <div style={{ height: "100%", paddingTop: 70 }}>
        <AdminNavbar active="logs" />
        <div class="container fadeMeIn">
          <h1>Moderator Logs</h1>
          <hr />
          {this.state.logs.map((item, index) => {
            return (
              <p style={styles.log}>
                <span style={styles.date}>{new Date(item.createdAt).toString()}</span>
                <br />
                {item.summary}
                {this.state.moreIndex == index ? (
                  <p style={styles.details}>{item.details}</p>
                ) : (
                  <span style={styles.more} onClick={() => this.handleMoreClick(index)}>
                    more
                  </span>
                )}
              </p>
            );
          })}
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" onClick={() => this.handlePageBack()} aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              {this.getPaginationNumbers()}
              <li class="page-item">
                <a class="page-link" onClick={() => this.handlePageForward()} aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
          <p>Total logs: {this.state.count}</p>
          <br />
        </div>
      </div>
    );
  }
}

const styles = {
  log: {
    borderBottom: "1px solid " + Colors.border,
    marginBottom: 15,
    paddingBottom: 15,
  },
  more: {
    cursor: "pointer",
    marginLeft: 15,
    color: Colors.main
  },
  date: {
    opacity: 0.7,
    fontSize: 14
  },
  details: {
    whiteSpace: 'pre-wrap',
    backgroundColor: Colors.lightGrey,
    borderRadius: 2,
    padding: 5,
    fontFamily: 'monospace'
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(LoginPage);

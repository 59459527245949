import React, { Component } from "react";
import { isMobile } from 'react-device-detect';
import Colors from "../../assets/Colors";
import { Link } from 'react-router-dom';

class BlurCover extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let wrapperStyle = {
      ...styles.wrapper,
      top: 0,
      paddingTop: 50,
    };
    return (
      <div style={wrapperStyle} class='blurbg'>
        <div style={styles.popup}>
          <img src={require("../../assets/img/podcasts/info.png")} height="64" />
          <h3 style={styles.title}>You must be signed in to read the rest of the transcript</h3>
          <a href='https://www.lingomastery.com/plussp/' style={styles.signupButton}>Sign Up Now!</a>
          <Link to='/login' style={styles.login}>Login Now</Link>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: -5000,
    // filter: 'blur(5px)',
    // backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    zIndex: 2,
    
    // backgroundColor: 'red'
  },
  popup: {
    width: isMobile ? '90%' : 500,
    // margin: 15,
    margin: 'auto',
    borderRadius: 4,
    backgroundColor: '#fff',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: isMobile ? 20 : 50,
    paddingRight: isMobile ? 20 : 50,
    position: 'absolute',
    zIndex: 20,
    boxShadow: '0 18px 18px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1)'
  },
  signupButton: {
    cursor: 'pointer',
    borderRadius: 4,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
    color: '#fff',
    width: '100%',
    backgroundColor: '#9ED74C'

  },
  login: {
    cursor: 'pointer',
    padding: 10,
    marginTop: 20,
    color: Colors.lighterText,
    textDecoration: 'none',
    color: Colors.text
  },
  title: {
    marginTop: 20,
    marginBottom: 40,
    textAlign: 'center'
  }
};

export default BlurCover;

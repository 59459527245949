import React, { Component } from 'react';
import { toggleNav, updateLoggedInUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import { TwitterButton, GooglePlusButton, LinkedInButton  } from "react-social";
import Colors from '../../assets/Colors';
import SkillPill from '../../routes/BrowsePage/components/SkillPill';
import PlayerControls from './PlayerControls';
import Constants from '../../assets/Constants';

class StoryTopBox extends Component {
  constructor(props) {
      super(props);
      this.state = {
        topContainerStyle: {position: 'absolute', top: this.getHeight('navbar'), width: '100%'},
        musicBoxStyle: {},
        lastTopContainerHeight: 0,
        imageMaxHeight: '150px', //for the small image not the bg
      }
  }

  //------LIFECYCLE-----------

  componentDidMount(){
    this.progressBar.addEventListener("click", this.progressSeek.bind(this))
    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.props.top(this.getHeight('topContainer')+this.getHeight('navbar'));
  }

  componentWillReceiveProps(nextProps, nextState){
    if(nextProps.article.title != this.props.article.title)
      setTimeout(() => this.props.top(this.getHeight('topContainer')+this.getHeight('navbar')) , 100);

      //change topcontainer style when sidenav is opened
    if(nextProps.navState != this.props.navState){
      let topContainerStyle = JSON.parse(JSON.stringify(this.state.topContainerStyle));
      let musicBoxStyle = JSON.parse(JSON.stringify(this.state.musicBoxStyle));

      // OPEN nav when box is locked
      if(nextProps.navState=='toggled' && topContainerStyle.position=='fixed'){
        topContainerStyle.left = 250;
        musicBoxStyle.left = 250;
        topContainerStyle.width=isMobile ? '100%' : 'auto';
        musicBoxStyle.width=isMobile ? '100%' : 'auto';

      // nav closing, regardless of topbar locked state
      }else{
        topContainerStyle.left = 0;
        // topContainerStyle.width='100%';
        musicBoxStyle.left = isMobile ? 0 : 77;
        // musicBoxStyle.width='100%';
      }

      //only set if its not already correct
      if(this.state.topContainerStyle.left != topContainerStyle.left) this.setState({ topContainerStyle, musicBoxStyle });
    }
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll );
  }

  //---METHODS---

  //return measurements for positioning controls
  getHeight(arg){
    switch(arg){
      case 'navbar':
        if(this.props.loggedInUser){ //different height navbar if someones logged in
          return 50; // this was 56.8 but i changed it
        }else{
          return isBrowser ? 50 : 50;           
        }
        break;
      case 'topContainer':
        return this.readStoryTopContainer.clientHeight; break;
      case 'storyTitle':
        return this.playerTitleContainer.clientHeight; break;
      case 'storyTitle':
      return this.musicbox.clientHeight; break;
    }
  }

  handleScroll(e){
    if(this.playerTitleContainer){
      // NOTE: i found a better way to implement sticking player to top based on scroll position - see PlayPodcastPage handleScroll method.
      // but never implemented it here because this was working fine still. if problems in future, maybe convert this to that
      
      //lock controls in place
      if(window.scrollY > this.getHeight('storyTitle')+43 && this.state.topContainerStyle.position != "fixed" ){ // idk why but 43 makes it more accurate
        this.setState({
          topContainerStyle: {
            position: "fixed", 
            top: ((this.getHeight('storyTitle')-this.getHeight('navbar'))+15)*-1, //+10 HERE TO MAKE IT HIGHER UP
            // left: this.props.navState=='toggled' ? 125:77, //half the width of sidenav
            right: 0
          },
          musicBoxStyle: {
            position: 'fixed', 
            top: this.getHeight('navbar')-2, 
            left: this.props.navState=='toggled' ? 250: (isMobile || !this.props.loggedInUser ? 0 : 77), 
            right: 0, 
            width: 'auto'
          }
        }); 

      //unlock controls
      }else if(window.scrollY< this.getHeight('storyTitle')+43 && this.state.topContainerStyle.position=='fixed'){ // idk why but 43 makes it more accurate
        this.setState({
          topContainerStyle: {
            position: "absolute", 
            top: this.getHeight('navbar'), 
            width: '100%', 
            left: 0
          },
          musicBoxStyle: { position: 'relative' },
        }); 
      }

      // set top if it changes, and only if necessary
      if(this.getHeight('topContainer')!=this.state.lastTopContainerHeight){
        if(this.state.topContainerStyle.position=='fixed'){
          this.props.top(this.getHeight('musicbox')+this.getHeight('navbar'));
        }else{
          this.props.top(this.getHeight('topContainer')+this.getHeight('navbar'));
        }
        this.setState({lastTopContainerHeight: this.getHeight('musicbox')+this.getHeight('navbar') });            
      }
    }
  }

  progressSeek(e){
    // console.log((e.offsetX/this.progressBar.offsetWidth)*100)
    //convert to ms before calling playTIme, it accepts ms as arg
    let percent = (e.offsetX/this.progressBar.offsetWidth)*100;
    let ms = parseInt(this.props.duration) * parseInt(percent);
    this.props.playTime(ms/100)
  }
    
  render() {
    let musicPlayerLocked = this.state.topContainerStyle.position=='absolute' ? false : true;

    let { playStatus, playbackRate, position, duration, togglePlaybackRate, handleSkip, handlePlayPause } = this.props;
    let { difficulty, title, category, artwork, ccAttribution, subtitle } = this.props.article;

    let socialIconsWrapperStyle = {
      position: 'fixed',
      left: 0,
      bottom: musicPlayerLocked ? -344 : -200
    };

    let socials = (
      <div style={socialIconsWrapperStyle}>
        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location} target="_blank">
          <img class="fadeHover" title="Share!" style={styles.socialIcon} src={require("../../assets/img/social/facebook.png")} />
        </a>
        <TwitterButton element="span">
          <img
            class="fadeHover"
            title="Share!"
            message={`${Constants.appName} - Language Immersion - Learn Languages by Reading with Audio`}
            style={styles.socialIcon}
            src={require("../../assets/img/social/twitter.png")}
          />
        </TwitterButton>
        <GooglePlusButton element="span">
          <img class="fadeHover" title="Share!" style={styles.socialIcon} src={require("../../assets/img/social/google-plus.png")} />
        </GooglePlusButton>
        <LinkedInButton element="span" title={`${Constants.appName} - Language Immersion - Learn Languages by Reading with Audio`}>
          <img class="fadeHover" title="Share!" style={styles.socialIcon} src={require("../../assets/img/social/linkedin.png")} />
        </LinkedInButton>
      </div>
    );
    
    return (
      <div id="readStoryTopContainer" ref={x => this.readStoryTopContainer = x} style={this.state.topContainerStyle}>
        <div style={styles.backgroundImageContainer}>
          <div style={{...styles.backgroundImage, backgroundImage: 'url('+artwork+')'}}></div>
          <div style={styles.backgroundImageOverlay} />
        </div>

          <div class='col mx-auto' style={{paddingLeft: 0, paddingRight: 0, paddingTop: 20, maxWidth: 780}}>
            <div ref={x => this.playerTitleContainer = x}>
            <div class='row'>
              {isBrowser && artwork ? 
                <div>
                  <img 
                    onLoad={() => this.props.top(this.getHeight('topContainer')+this.getHeight('navbar'))} 
                    src={artwork} 
                    class='w-100' 
                    style={styles.squareImage}/>
                </div>
              : null}
                <div class={isBrowser ? '' : 'mx-auto'}  style={{...styles.topInfo, maxWidth: isMobile ? 300 : 400}}>
                  <div style={styles.topInfoRow}>
                    <SkillPill difficulty={difficulty} white='true'/>
                    <span style={styles.categorySpan}>{category}</span>
                  </div>
                  <h3 style={{...styles.oneLine, fontWeight: 'bold'}}>{title}</h3>
                  <h3 style={styles.subtitle}>{subtitle}</h3>
                  {ccAttribution ? <p dangerouslySetInnerHTML={{__html: ccAttribution}} id='ccAttrText'/> : null}
                  {/* <p style={styles.oneLine}>{description}</p> */}
                  <br/><br/>
                </div>
              </div>
            </div>
            <br/>
            {/* {isBrowser && !this.props.loggedInUser ? socials : null} */}

            <PlayerControls
              setRef={(name, ref) => this[name] = ref}
              musicBoxStyle={this.state.musicBoxStyle}
              musicPlayerLocked={musicPlayerLocked}
              position={position}
              duration={duration}
              togglePlaybackRate={togglePlaybackRate}
              playbackRate={playbackRate}
              handleSkip={handleSkip}
              handlePlayPause={handlePlayPause}
              playStatus={playStatus}
            />

          </div>
      </div>
    );
  }
}

const styles = {
  categorySpan: {
    color: '#fff',
    marginLeft: 15
  },
  oneLine: {
    color: '#fff',
    marginBottom: 10,
    marginTop: 10,
    textAlign: isMobile ? 'center' : 'auto',
    width: '100%',
    letterSpacing: .5
  },
  subtitle: {
    textAlign: isMobile ? 'center' : 'auto',
    width: '100%',
    color: '#ffffff',
    opacity: .4,
    letterSpacing: .5,
    fontSize: 24
  },
  squareImage: {
    boxShadow: '0 18px 18px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1)',
    borderRadius: 12,
    maxWidth: 250,
    display: 'inline',
    float: 'left',
    marginLeft: 15
  },
  backgroundImage: {
    backgroundSize: 'cover',    
    height: '110%',
    filter: 'blur(5px) brightness(.6)',
    margin: -10,
  },
  topInfo: { // title subtitle etc. next to picture
    paddingLeft: isMobile ? 0 : 12,
    textAlign: isMobile ? 'center' : 'left'
  },
  backgroundImageOverlay: {
    // webkitFilter: 'sepia(100%) hue-rotate(90deg) saturate(400%)',
    // filter: 'sepia(100%) hue-rotate(90deg) saturate(400%)'
    position: 'absolute',
    overflow: 'hidden',
    top:0, 
    left:0,
    right:0,
    bottom: 0,
    backgroundColor: Colors.blue,
    opacity: .8,
  },
  backgroundImageContainer: {
    position: 'absolute',
    overflow: 'hidden',
    top:0, 
    bottom: 55,
    left:0,
    right:0,
  },

  //SOCIAL ICONS
  socialIconsWrapper: {
    position: 'absolute',
    left: -70,
    bottom: -220

  },
  socialIcon: {
    height: 32,
    width: 32,
    display: 'block',
    marginBottom: 7
  },
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState
})

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
}

export default connect(mapStateToProps, mapActionsToProps)(StoryTopBox);

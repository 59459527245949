import React, { Component } from 'react';
import MyNavbar from '../components/MyNavbar';
import { Redirect } from 'react-router-dom';

class PrivacyPolicy extends Component {


    render() {


        return (
                <div>
                    
                       
                </div>
        );
    }
}

const styles = {
    container: {
        paddingTop: 70
    }
}

export default PrivacyPolicy;

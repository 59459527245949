import React, { Component } from "react";
import MyNavbar from "../components/MyNavbar";
import { Link, Redirect } from "react-router";
import { updateLoggedInUser, selectLanguage, setAvailableLanguages, updateArticles } from "../redux/actions";
import { connect } from "react-redux";
import ForgotPasswordModal from "../components/modals/ForgotPasswordModal";
import { ClipLoader, PulseLoader } from "react-spinners";
import PageTemplate from "./PageTemplate";
import { loadCourseStatus } from "../helpers/courses";
import { fetchDecks } from "../helpers/decks";
import { fetchLessonDecks } from "../helpers/lessonDecks";
import logSiteVisit from "../scripts/logSiteVisit";
import Colors from "../assets/Colors";

// NOTE this page is designed to work only with circle.so

class OauthLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false
    };
  }


  componentDidMount(){
    this.checkIfLoggedIn();
  }

  checkIfLoggedIn(){
    global.apiRequest({
      url: '/getuser'
    }).then(async result => {
        if (result.loggedIn) {
          // NOTE also handle user login in LoginPage.js
          let codeResult = await global.apiRequest({url: `/oauth-api/circlecode`});
          let circleCode = codeResult.circleCode;
          this.setState({circleCode}, () => this.doRedirect())
        }else{
          window.location = 'https://members.lingomastery.com/login'
        }
      })
      .catch(e => console.log(e));
  }

  doRedirect(){
    // use client id from the url to determine where to redirect. but circle is only one right now, so just doing that by default.

    window.location = `https://community.lingomastery.com/oauth2/callback?code=${this.state.circleCode}`
  }

  render() {
    return (
      <PageTemplate>
        <div style={styles.center}>
          <div style={styles.message}>
            Logging you in to Lingomastery Community...
          </div>
          <ClipLoader color={Colors.lighterText}/>
        </div>
      </PageTemplate>
    );
  }
}

const styles = {
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100
  },
  message: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 18,
    color: Colors.lightText
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, 
  selectLanguage,
  setAvailableLanguages,
  updateArticles
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(OauthLoginPage);

import React, { Component } from "react";
import AdminNavbar from "../../components/admin/AdminNavbar";
import { Link, Redirect } from "react-router-dom";
import { updateLoggedInUser } from "../../redux/actions";
import { connect } from "react-redux";
import { ClipLoader, PulseLoader } from "react-spinners";
import SelectAuthorizedLanguage from "../../components/admin/SelectAuthorizedLanguage";
import dayjs from "dayjs";

class ViewUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      loadingLogs: false,
      logs: [],
    };
  }

  //-----LIFECYCLE--------------------------------
  componentDidMount() {
    fetch("/user/" + this.props.router.match.params.id, {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((result) => {
        this.setState({ loading: false });
        if (result.status == "success") {
          this.setState({ user: result.user });
        } else {
          alert("error getting users.");
        }
      });
  }

  //---------METHODS-----------------------------

  saveUser() {
    fetch("/user/" + this.props.router.match.params.id, {
      method: "post",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: this.state.user }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          alert("Saved user.");
        } else {
          alert("error saving user.");
        }
      });
  }

  handleChange(e, theKey) {
    //theKey should be the same as the model name from the DB
    let changeObject = this.state.user;
    changeObject[theKey] = e.target.value.toLowerCase();
    this.setState({ user: changeObject });
  }

  handleSubscriptionActiveChange(e) {
    let changeObject = this.state.user;
    changeObject.subscriptionActive = e.target.value;
    this.setState({ user: changeObject });
  }

  handleAccountTypeChange(e) {
    let changeObject = this.state.user;
    changeObject.accountType = e.target.value;
    this.setState({ user: changeObject });
  }

  handleLanguageChange(lang) {
    let changeObject = this.state.user;
    changeObject.authorizedLanguages = lang;
    this.setState({ user: changeObject });
  }

  onLoadUserLogs = () => {
    this.setState({ loadingLogs: true });
    global
      .apiRequest({
        method: "POST",
        body: { userId: this.state.user.id },
        url: "/user/logs/getvisits",
      })
      .then((res) => {
        this.setState({
          // logs: res.logs.sort((a, b) => {
          //   return new Date(a.visitedAt) > new Date(b.visitedAt) ? -1 : 1 
          // }),
          logs: res.logs,
          loadingLogs: false,
        });
        if(res.logs.length === 0) alert('no logs for this user yet')
        if(res.limited) alert('limited to 500 most recent logs')
      });
  };

  handleSubmitNewPassword() {
    if (this.state.password1 != this.state.password2 && this.state.password1.length < 4)
      return alert("Please make sure passwords match, and are at least 6 charecters");
    this.setState({ loading: true });
    fetch("/password/reset/" + this.state.user.id, {
      method: "post",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password1: this.state.password1,
        password2: this.state.password2,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        this.setState({
          password1: "",
          password2: "",
          loading: false,
        });
        alert(result.message);
      });
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <AdminNavbar active="viewusers" />
        <div class="container fadeMeIn" style={{ paddingTop: 80 }}>
          <h2>View User</h2>
          {this.state.loading ? <PulseLoader color="grey" /> : null}
          <br />

          <div class="form-group row">
            <label class="col-sm-3 col-form-label font-weight-bold">Email</label>
            <div class="col-md-6">
              <input onChange={(e) => this.handleChange(e, "email")} value={this.state.user.email} type="email" class="form-control" />
              <small style={{ opacity: 0.5 }}>No upper case</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label font-weight-bold">Subscription Active?</label>
            <div class="col-md-6">
              <select
                class="form-control"
                onChange={(e) => this.handleSubscriptionActiveChange(e)}
                value={this.state.user.subscriptionActive}
              >
                <option value={false} selected={this.state.user.subscriptionActive == false ? true : false}>
                  No
                </option>
                <option value={true} selected={this.state.user.subscriptionActive == false ? false : true}>
                  Yes
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputPassword" class="col-sm-3 col-form-label font-weight-bold">
              Account Type
            </label>
            <div class="col-md-6">
              <select class="form-control" onChange={(e) => this.handleAccountTypeChange(e)} value={this.state.user.accountType}>
                <option value={"admin"} selected={this.state.user.accountType === "admin" ? true : false}>
                  Admin
                </option>
                <option value={"moderator"} selected={this.state.user.accountType === "moderator" ? true : false}>
                  Moderator
                </option>
                <option value={"editor"} selected={this.state.user.accountType === "editor" ? true : false}>
                  Editor
                </option>
                <option value={"user"} selected={this.state.user.accountType === "user" ? true : false}>
                  User
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputPassword" class="col-sm-3 col-form-label font-weight-bold">
              Authorized Language
            </label>
            <div class="col-sm-6 col-form-label">
              <SelectAuthorizedLanguage
                handleChange={this.handleLanguageChange.bind(this)}
                selectedLanguage={this.state.user.authorizedLanguages}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label font-weight-bold">Stripe Subscription ID</label>
            <label class="col-sm-6 col-form-label">{this.state.user.subscriptionId}</label>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label font-weight-bold">Created At</label>
            <label class="col-sm-6 col-form-label">{new Date(this.state.user.createdAt).toLocaleString()}</label>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label font-weight-bold">Subscription Type</label>
            <label class="col-sm-6 col-form-label">{this.state.user.subscriptionType}</label>
          </div>

          <br />

          <div class="btn btn-primary" onClick={this.saveUser.bind(this)} style={styles.saveButton}>
            Save
          </div>
          <br />
          <br />
          <hr />

          <div class="form-group col-6 col-xl-4">
            <small style={styles.formLabel}>New Password</small>
            <input
              type="password"
              class="form-control"
              value={this.state.password1}
              onChange={(x) => this.setState({ password1: x.target.value })}
            />
          </div>
          <div class="form-group col-6 col-xl-4">
            <small style={styles.formLabel}>Confirm New Password</small>
            <input
              type="password"
              class="form-control"
              id="password2"
              value={this.state.password2}
              onChange={(x) => this.setState({ password2: x.target.value })}
            />
          </div>
          <button class="btn btn-secondary" style={styles.button} onClick={this.handleSubmitNewPassword.bind(this)}>
            Change Password
          </button>
          <br />
          <hr />
          <h3>User Logs</h3>
          {this.state.logs.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Date/Time</th>
                  <th scope="col">IP</th>
                  <th scope="col">Device / User Agent</th>
                </tr>
              </thead>
              <tbody>
                {this.state.logs.map((log) => {
                  let visited = dayjs(new Date(log.visitedAt))
                  return (
                    <tr>
                      <td>{visited.format('MMM DD YYYY, HH:mm:ss Z')} </td>
                      <td>{log.ip}</td>
                      <td>{log.userAgent}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <button class="btn btn-secondary" style={styles.button} onClick={this.onLoadUserLogs.bind(this)}>
              Load User Logs
            </button>
          )}
          {this.state.loadingLogs ? <ClipLoader /> : null}
        </div>
        <br />
      </div>
    );
  }
}

const styles = {
  saveButton: {
    minWidth: 150,
  },
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
};

export default connect(mapStateToProps, mapActionsToProps)(ViewUserPage);

import React, { Component } from "react";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { Link } from "react-router-dom";
import Colors from "../../../assets/Colors";
import { message, Button, Icon, Input } from "antd";
import AdminInput from "../../../components/admin/AdminInput";
import ImageInput from "../../../components/ImageInput";
import shortid from "shortid";
import LesssonRow from "./LessonRow";
import DraggableList from "react-draggable-list";
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
const ReactMarkdownWithHtml = require('react-markdown/with-html')

const vimeo_key = '609ad9dd5e128e17f59fcf8ecb02643c'; // public scope only

const skillLevels = [
  {label: 'Newbie', value: 'newbie'},
  {label: 'Beginner', value: 'beginner'},
  {label: 'Intermediate', value: 'intermediate'},
  {label: 'Advanced', value: 'advanced'},
]

class AdminEditCoursePage extends Component {
  constructor(props) {
    super(props);
    let isEdit = props.router.location.pathname.includes("/new") ? false : true;
    this.state = {
      loading: isEdit ? true : false,
      isEdit: isEdit,
      course: {
        lessons: [],
      },
      languages: [],
      selectedLessonId: "",
      // selectedSectionId: "",
    };
  }

  componentDidMount() {
    if (this.state.isEdit) this.loadCourse();
    this.loadLanguages();
  }

  loadLanguages = async () => {
    var langRes = await global.apiRequest({ url: "/languages/get/all" });
    const formattedLanguages = langRes.languages.map((l) => {
      return {
        label: l.title,
        value: l.title,
      };
    });
    if (langRes.languages.length > 0) this.setState({ languages: formattedLanguages });
  };

  loadCourse = () => {
    if (!this.state.isEdit) return;
    let courseId = this.props.router.match.params.id;
    global.apiRequest({ url: `/courses/get/${courseId}` }).then((res) => {
      this.setState({ course: res.course, loading: false }, () => this.sortCourse());
    });
  };

  getErrorMessage = () => {};

  submit = () => {
    let isEdit = this.props.router.location.pathname.includes("/new") ? false : true;
    let courseId = this.props.router.match.params.id;
    let errorMessage = this.getErrorMessage();
    if (errorMessage) return alert(errorMessage);

    this.setState({ loading: true });

    fetch(isEdit ? `/courses/edit/${courseId}` : `/courses/new`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        course: this.state.course,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({ loading: false });
          message.success(`${isEdit ? "Saved" : "Added"} course!`);
          this.props.router.history.push("/manage-courses");
        } else {
          alert(result.message);
          this.setState({ loading: false });
        }
      });
  };

  sortCourse = () => {
    let newLessons = [...this.state.course.lessons];
    newLessons.sort((a, b) => a.sortIndex > b.sortIndex);
    newLessons = newLessons.map(l => {
      // let newSections = [...l.sections];
      // newSections.sort((c, d) => c.sortIndex > d.sortIndex);
      return({
        ...l,
        // newSections
      })
    })
    this.setState({
      course: {
        ...this.state.course,
        lessons: newLessons
      }
    })
  }

  changeCourseItem = (key, val) => {
    this.setState({
      course: {
        ...this.state.course,
        [key]: val,
      },
    });
  };

  handleUploadCourseImage = (e) => {};

  onClickDeleteCourse = () => {
    // are u sure ?
  };

  addLesson = () => {
    this.setState({
      course: {
        ...this.state.course,
        lessons: [...this.state.course.lessons, { id: shortid.generate(), title: "New Lesson" }],
      },
    }, () => this.assignSortIndexes());
  };

  // addSection = () => {
  //   let newLesson = this.getCurrentLesson();
  //   newLesson.sections = [...newLesson.sections, { title: "New Section", id: shortid.generate() }];
  //   this.setState({
  //     course: {
  //       ...this.state.course,
  //       lessons: [
  //         ...this.state.course.lessons.map((x) => {
  //           return x.id === this.state.selectedLessonId ? newLesson : x;
  //         }),
  //       ],
  //     },
  //   }, () => this.assignSortIndexes());
  // };

  getCurrentLesson = () => {
    let lesson = { };
    this.state.course.lessons.forEach((l) => {
      if (l.id === this.state.selectedLessonId) lesson = l;
    });
    return lesson;
  };

  // getCurrentSection = () => {
  //   let lesson = this.getCurrentLesson();
  //   let section = {};
  //   lesson.sections.forEach((s) => {
  //     if (s.id === this.state.selectedSectionId) section = s;
  //   });
  //   return section;
  // };

  onChangeLessonItem = (key, val) => {
    let newLesson = this.getCurrentLesson();
    newLesson[key] = val;
    if(key==='videoUrl' && val){
      this.setVideoLength(val);
    }
    if(key==='length'){
      if(/\D/.test(val)) return; // numbers only
    }
    this.setState({
      course: {
        ...this.state.course,
        lessons: [
          ...this.state.course.lessons.map((x) => {
            return x.id === this.state.selectedLessonId ? newLesson : x;
          }),
        ],
      },
    });
  };

  // attaches the current index to every item under 'sortIndex' for saving sort in db
  assignSortIndexes = () => {
    let newLessons = [...this.state.course.lessons];
    newLessons = newLessons.map((l, lessonIndex) => {
      let newLessonItem = {
        ...l,
        sortIndex: lessonIndex
      };
      // let newSections = [...newLessonItem.sections];
      // newSections = newSections.map((s, sectionIndex) => {
      //   return({...s, sortIndex: sectionIndex})
      // })
      // newLessonItem.sections = newSections;
      return(newLessonItem)
    })
    this.setState({
      course: {
        ...this.state.course,
        lessons: newLessons
      }
    })
  }

  // onChangeSectionItem = (key, val) => {
  //   const { selectedSectionId, selectedLessonId } = this.state;
  //   let newLesson = this.getCurrentLesson();
  //   let newSection = this.getCurrentSection();
  //   newSection[key] = val;
  //   newLesson.sections = newLesson.sections.map(s => s.id === selectedSectionId ? newSection : s);
  //   this.setState({
  //     course: {
  //       ...this.state.course,
  //       lessons: [...this.state.course.lessons.map((x) => x.id === selectedLessonId ? newLesson : x)],
  //     },
  //   });
  // };

  // onSectionsListChange = newList => {
  //   let newLesson = {...this.getCurrentLesson(), sections: newList};
  //   this.setState({
  //     course: {
  //       ...this.state.course,
  //       lessons: this.state.course.lessons.map(l => {
  //         return(l.id === this.state.selectedLessonId ? newLesson : l)
  //       })
  //     }
  //   }, () => this.assignSortIndexes())
  // }

  onLessonsListChange = newList => {
    this.setState({
      course: {
        ...this.state.course,
        lessons: newList
      }
    }, () => this.assignSortIndexes())
  }

  deleteLesson = () => {
    if(window.confirm("Are you sure you want to delete this lesson? Click OK to delete")){
      this.setState({
        // selectedSectionId: '',
        selectedLessonId: '',
        course: {
          ...this.state.course,
          lessons: this.state.course.lessons.filter(x => x.id !== this.state.selectedLessonId)
        }
      }, () => this.assignSortIndexes())
    }
  }

  // deleteSection = () => {
  //   if(window.confirm("Are you sure you want to delete this section? Click OK to delete")){
  //     let newLessons = [...this.state.course.lessons];
  //     newLessons = newLessons.map(l => {
  //       if(l.id === this.state.selectedLessonId){
  //         return({
  //           ...l,
  //           sections: l.sections.filter(x => x.id !== this.state.selectedSectionId)
  //         })
  //       }else{
  //         return l
  //       }
  //     })
  //     this.setState({
  //       selectedSectionId: '',
  //       course: {
  //         ...this.state.course,
  //         lessons: newLessons,
  //       }
  //     }, () => this.assignSortIndexes())
  //   }
  // }

  onSelectLessonId = id => {
    this.setState({
      selectedLessonId: id,
      // selectedSectionId: ''
    })
  }

  handleMarkdownChange = ({html, text}) => {    
    console.log('handleEditorChange', html, text)
    this.onChangeLessonItem('description', text)

  }

  setVideoLength = url => {
    return new Promise(resolve => {
      let urlArr = url.split('/');
      let videoId = urlArr[urlArr.length-1].split('?')[0]
      fetch(`https://api.vimeo.com/videos/${videoId}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${vimeo_key}`
        }
      })
      .then(result => result.json())
      .then(result => {
        console.log("vimeo result", result)
        console.log('result duration', result.duration)
        this.onChangeLessonItem('length', result.duration)
      })
    })
  }

  render() {
    const { isEdit } = this.state;

    const currentLesson = this.getCurrentLesson();
    // const currentSection = this.getCurrentSection();

    return (
      <div style={styles.wrapper} class="container-fluid">
        <AdminNavbar active={"manage-courses"} />
        {this.state.loading ? <LoadingOverlay /> : null}
        <div style={styles.topRow}>
          <h1 style={{ marginRight: 40 }}>{isEdit ? "Edit Course" : "New Course"}</h1>
          <div style={{ width: 400, position: "relative" }}>
            <AdminInput label="Title" value={this.state.course.title} onChangeText={(text) => this.changeCourseItem("title", text)} />
            <AdminInput
              label="Language"
              value={this.state.course.language}
              onChange={(text) => this.changeCourseItem("language", text)}
              options={this.state.languages}
            />
            <AdminInput
              label="Difficulty"
              value={this.state.course.difficulty}
              onChange={(text) => this.changeCourseItem("difficulty", text)}
              options={skillLevels}
            />
          </div>
          <div style={{ width: 400, marginLeft: 30 }}>
            <div style={styles.imageRow}>
              <div style={{ marginRight: 30 }}>Image</div>
              <ImageInput onChange={(awsUrl) => this.changeCourseItem("imageUrl", awsUrl)} value={this.state.course.imageUrl} />
            </div>
          </div>
        </div>

        <div style={styles.bottom}>
          <div style={styles.lessonsColLeft}>
            <div style={{display: 'flex',flexDirection: 'row',alignItems: 'center',justifyContent: 'space-between',width: '100%', padding: 3}}>
              <h4>Lessons</h4>
              <Button style={{ width: 170 }} onClick={this.addLesson}>
                + Add Lesson
              </Button>
            </div>
            <div style={styles.lessonsLeftScroll}>
              <DraggableList
                itemKey={"id"}
                template={({item, dragHandleProps, commonProps}) => {
                  // console.log('cp',commonProps)
                  return <LesssonRow
                    // index={index}
                    dragHandleProps={dragHandleProps}
                    key={item.id}
                    lesson={item}
                    isSelected={this.state.selectedLessonId === item.id}
                    onClick={() => this.onSelectLessonId(item.id)}
                  />
                }}
                list={this.state.course.lessons}
                onMoveEnd={this.onLessonsListChange}
                // container={() => this.tbody}
                constrainDrag={true}
                padding={0}
              />


              <div style={{ height: 20, width: "100%" }} />
            </div>
          </div>
          {this.state.selectedLessonId ? (
            <div style={styles.lessonsRight}>
              <div style={styles.rightTop}>
                <div style={{width: '100%', display: 'flex',flexDirection: 'row',justifyContent: 'flex-end',marginBottom: 10}}>
                  <Button onClick={this.deleteLesson} type='danger' ghost size='small' >
                   Delete Lesson
                    </Button>
                </div>
                <AdminInput labelWidth={180} label="Title" value={currentLesson.title} onChangeText={(text) => this.onChangeLessonItem("title", text)} />
                <AdminInput labelWidth={180} label="Video URL (Vimeo)" value={currentLesson.videoUrl} onChangeText={(text) => this.onChangeLessonItem("videoUrl", text)} />
                <AdminInput labelWidth={180} label="Video Length (Seconds)" value={currentLesson.length} onChangeText={(text) => this.onChangeLessonItem("length", text)} />
                {/* <Input.TextArea value={currentLesson.description} onChange={e => this.onChangeDescription} /> */}
                <MdEditor
                  value={currentLesson.description}
                  style={{ height: 350 }}
                  renderHTML={(text) => (
                    <ReactMarkdownWithHtml children={text} allowDangerousHtml />
                  )}
                  onChange={this.handleMarkdownChange}
                  />
                <div style={{display: 'flex',flexDirection: 'row',}}>
                  <div style={{flex: .5}}>
                    <ul>
                      <li style={styles.markdownHelp}>Line breaks: end a line in 2 spaces</li>
                    </ul>
                  </div>
                  <div style={{flex: .5, textAlign: 'center', opacity: .5}}>(user sees this)</div>
                </div>
                <div style={{height: 10 }} />
              </div>
              {/* <div style={styles.rightBottom}>
                <h4>Sections</h4>
                <div style={styles.rightBottomRow}>
                  <div style={styles.sectionsColLeft}>
                  <DraggableList
                    itemKey={"id"}
                    template={({item, dragHandleProps, commonProps}) => (
                      <LesssonRow
                        // index={index}
                        key={item.id}
                        dragHandleProps={dragHandleProps}
                        lesson={item}
                        isSelected={this.state.selectedSectionId === item.id}
                        onClick={() => this.setState({ selectedSectionId: item.id })}
                      />
                    )}
                    list={currentLesson.sections}
                    onMoveEnd={this.onSectionsListChange}
                    // container={() => this.tbody}
                    constrainDrag={true}
                    padding={0}
                  />
                    <Button onClick={this.addSection} style={{ width: 120, marginTop: 15 }}>
                      + Add Section
                    </Button>
                    <div style={{height: 20}} />
                  </div>
                  <div style={styles.sectionsRight}>
                    {this.state.selectedSectionId ? 
                      <React.Fragment>
                        <div style={{width: '100%', display: 'flex',flexDirection: 'row',justifyContent: 'flex-end',marginBottom: 10}}>
                          <Button onClick={this.deleteSection} type='danger' ghost size='small' >
                            Delete Section
                          </Button>
                        </div>
                        <AdminInput label='Title' value={currentSection.title} onChangeText={text => this.onChangeSectionItem('title', text)} />
                        <AdminInput label='Vimeo URL' value={currentSection.videoUrl} onChangeText={text => this.onChangeSectionItem('videoUrl', text)} />
                      </React.Fragment>
                    :null}
                  </div>
                </div>
              </div> */}
            </div>
          ) : null}
        </div>

        <div style={styles.bottomBar}>
          {isEdit ? (
            <div class="btn btn-outline-danger" onClick={this.onClickDeleteCourse}>
              Delete Course
            </div>
          ) : (
            <div />
          )}
          <div class="btn btn-primary" style={{ width: 120 }} onClick={this.submit}>
            {isEdit ? "Save" : "Submit"}
          </div>
        </div>
        <div style={{height: 70}} />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    // padding: 20,
    paddingTop: 75,
    height: "100vh",
    // paddingBottom: 65,
    display: "flex",
    flexDirection: "column",
    position: 'relative',
    // backgroundColor: 'red',
    // overflow: 'auto'
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: Colors.border,
    paddingBottom: 10,
    marginBottom: 20,
    // justifyContent: "space-between",
  },
  bottomBar: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
    padding: 10,
    backgroundColor: "#fff",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: Colors.border,
    zIndex: 3
  },
  imageRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  // bottom
  bottom: {
    // height: '100%',
    display: "flex",
    flexDirection: "row",
    // width: "100%",
    // marginTop: 20,
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    // display: 'flex',
    zIndex: 22
  },
  lessonsColLeft: {
    display: 'flex',
    flexDirection: "column",
    // flex: 0.35,
    width: 400,
    height: '100%',
    overflow: "hidden",
  },
  lessonsLeftScroll: {
    // height: '100%',
    overflow: 'auto',
    height: '100%'
  },
  lessonsRight: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    position: "relative",
    backgroundColor: "#EBEBEB",
    padding: 14,
    borderRadius: 4,
    overflowY: 'auto'
  },
  rightTop: {},
  rightBottom: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
    padding: 12,
    borderRadius: 4,
  },
  rightBottomRow: {
    display: "flex",
    flexDirection: "row",
  },
  sectionsColLeft: {
    flexDirection: "column",
    flex: 0.35,
    minHeight: 400,
    height: '50vh',
    overflow: 'auto'
  },
  sectionsRight: {
    flex: 0.65,
    backgroundColor: "#EBEBEB",
    padding: 10,
  },
};

export default AdminEditCoursePage;

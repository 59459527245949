import * as types from '../types';
import { getGeneralDeckId } from '../../helpers/decks';

const initialState = {
  decks: {},
  selectedDeck: {},
}

export function lessonDecksReducer(state = initialState, action) {
  switch (action.type) {
    case types.SELECT_LESSON_DECK:
      return({...state, selectedDeck: action.payload})
      
    case types.HYDRATE_LESSON_DECKS: 
      let decksIndexed = {};
      action.payload.forEach(d => decksIndexed[d.id] = d)
      return({...state, decks: decksIndexed})

    default: return state;
  }
}
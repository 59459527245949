import React, { Component } from "react";
import MyNavbar from "../../components/MyNavbar";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import AdminNavbar from "../../components/admin/AdminNavbar";
import Colors from "../../assets/Colors";
import AdminInput from '../../components/admin/AdminInput';
import { Button, Popover, message } from "antd";
import { SketchPicker } from "react-color";

class AdminEditBannerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: Colors.main,
      fontColor: '#ffffff',
    };
  }

  componentDidMount(){
    this.loadBannerInfo()
  }

  loadBannerInfo = () => {
    global
    .apiRequest({
      method: "GET",
      url: "/sitebanner/check",
    })
    .then((res) => {
      this.setState({
        isActive: res.banner.isActive,
        text: res.banner.text,
        link: res.banner.link,
        linkLabel: res.banner.linkLabel,
        bgColor: res.banner.bgColor,
        fontColor: res.banner.fontColor,
      })
    });
  }

  onChangeItem = (key, val) => {
    this.setState({[key]: val});
  }

  submit = () => {
    console.log(this.state)
    this.setState({loading: true})
    global.apiRequest({
      method: 'POST',
      url: '/sitebanner/edit',
      body: {
        link: this.state.link,
        linkLabel: this.state.linkLabel,
        text: this.state.text,
        isActive: this.state.isActive,
        bgColor: this.state.bgColor,
        fontColor: this.state.fontColor
      }
    }).then(res => {
      this.setState({loading: false})
      message.success("Banner info saved!")
      // this.props.history.push('/admin')
    })
  }

  render() {
    let bgColorPickerContent = <SketchPicker color={this.state.bgColor} onChangeComplete={c => this.setState({bgColor: c.hex})} />;
    let fontColorPickerContent = <SketchPicker color={this.state.fontColor} onChangeComplete={c => this.setState({fontColor: c.hex})} />;

    return (
      <div style={{ height: "100%", paddingTop: 70 }}>
      <AdminNavbar active="sitebanner" />
      <div class="container fadeMeIn">
        <h1>Site Banner</h1>
        <hr />
        <AdminInput labelWidth={170} maxWidth={150} label="Is Active?" value={this.state.isActive} onChange={val => this.setState({isActive: val})} options={[{label: 'Yes', value: true}, {label: 'No', value: false}]} />
        <AdminInput labelWidth={170} label="Link URL" value={this.state.link} onChangeText={(text) => this.onChangeItem("link", text)} />
        <AdminInput labelWidth={170} label="Link label" value={this.state.linkLabel} onChangeText={(text) => this.onChangeItem("linkLabel", text)} />
        <AdminInput labelWidth={170} label="Text" value={this.state.text} onChangeText={(text) => this.onChangeItem("text", text)} multiline={true}/>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Background color</label>
            <div class="col-sm-10">
              
              <Popover placement='right' content={bgColorPickerContent}>
              <div style={{...styles.colorSquare, backgroundColor: this.state.bgColor }}></div>
              </Popover>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Font color</label>
            <div class="col-sm-10">
              
              <Popover placement='right' content={fontColorPickerContent}>
              <div style={{...styles.colorSquare, backgroundColor: this.state.fontColor }}></div>
              </Popover>
            </div>
          </div>
        {/* <AdminInput labelWidth={180} label="Background Color" value={this.state.bgColor} onChangeText={(text) => this.onChangeItem("bgColor", text)} />
        <AdminInput labelWidth={180} label="Font Color" value={this.state.fontColor} onChangeText={(text) => this.onChangeItem("fontColor", text)} /> */}
       <br />
       <br />
       <Button type='primary' onClick={this.submit} loading={this.state.loading} disabled={this.state.loading}>Save</Button>
      </div>
    </div>
    );
  }
}

const styles = {
  colorSquare: {
    height: 28,
    width: 28,
    borderRadius: 4,
    cursor: 'pointer',
    borderWidth :2,
    borderStyle: 'solid',
    borderColor: 'black'
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

const mapActionsToProps = {
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AdminEditBannerPage);

import React, { Component } from 'react';
import MyNavbar from '../components/MyNavbar';
import { Link, Redirect } from 'react-router';
import { updateLoggedInUser } from '../redux/actions';
import { connect } from 'react-redux';
import ForgotPasswordModal from '../components/modals/ForgotPasswordModal';
import AnimateHeight from 'react-animate-height';
import { PulseLoader } from 'react-spinners';
import {isBrowser} from 'react-device-detect';


class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password1: '',
            password2: '',
            error: null,
            loading: false
        }
    }


    submitRegister() {
        if (
            this.state.email.length > 1 && 
            this.state.password1.length > 4 && 
            this.state.password1 == this.state.password2
        ) {
            this.setState({loading: true});
            fetch('/register', {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password1
                })
            })
            .then(result => result.json())
            .then(result => {
                // console.log(result + ' RESULT');
                if (result.status == 'success') {
                    this.props.onUpdateLoggedInUser(result.user);
                    this.setState({email: '', password: ''});
                }
                this.setState({loading: false});
                alert(result.message);
            })

        } else {
            this.setState({ error: "Invalid email and/or passwords" });
        }
    }

    render() {
        return (
            <div>
                <MyNavbar />
                <div class='container text-center col-md-4 offset-md-4'>
                    <ForgotPasswordModal />
                    <div style={{padding: isBrowser ? 150 : 0 }}>
                        {/* <img class="mb-4" src={require('../assets/img/png')} alt="" width="72" height="72"/> */}
                        <h1 class="h3 mb-3 font-weight-normal">Create Your Account</h1>
                        <input style={styles.input} type="email" class="form-control" placeholder="email" autofocus value={this.state.email} onChange={(x) => this.setState({ email: x.target.value })} />
                        <input style={styles.input} type="password" id="inputPassword" class="form-control" placeholder="Password" value={this.state.password1} onChange={(x) => this.setState({ password1: x.target.value })} />
                        <input style={styles.input} type="password" id="inputPassword2" class="form-control" placeholder="Confirm password" value={this.state.password2} onChange={(x) => this.setState({ password2: x.target.value })} />
                        <br/>
                        <button class="btn btn-lg btn-primary btn-block" onClick={this.submitRegister.bind(this)}>
                            {this.state.loading ? <PulseLoader color='#f7f7f7' /> : 'Submit'}
                        </button>
                        {<AnimateHeight duration={300} height={this.state.error ? 80 : 0}>
                            <div class='alert alert-danger' style={{marginTop: 15}}>{this.state.error}</div>
                        </AnimateHeight>}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    input: {
        marginTop: 10,
        marginBottom: 10
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(LoginPage);

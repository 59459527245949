import React, { Component } from "react";
import MyNavbar from "../components/MyNavbar";
import { Link, Redirect } from "react-router";
import { updateLoggedInUser, selectLanguage, setAvailableLanguages, updateArticles } from "../redux/actions";
import { connect } from "react-redux";
import ForgotPasswordModal from "../components/modals/ForgotPasswordModal";
import { PulseLoader } from "react-spinners";
import PageTemplate from "./PageTemplate";
import { loadCourseStatus } from "../helpers/courses";
import { fetchDecks } from "../helpers/decks";
import { fetchLessonDecks } from "../helpers/lessonDecks";
import logSiteVisit from "../scripts/logSiteVisit";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false
    };
  }

  checkSubmit(e) {
    if (e.key == "Enter") this.loginUser();
  }

  getNewArticles(){
    global.apiRequest({
      url: `/articles/getall`,
      method: 'get'
    })
    .then(result => {
      if (result.status == "success") {
        this.props.updateArticles(result.articles, result.categories);
      }
    });
  }

  loginUser() {
    if (this.state.email.length > 1 && this.state.password.length > 4) {
      this.setState({ loading: true });
      fetch("/login", {
        method: "post",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: this.state.email, password: this.state.password })
      })
        .then(result => result.json())
        .then(result => {
          // NOTE also handle user login in app.js
          this.setState({ loading: false });
          if (result.status == "success") {
            this.setState({ email: "", password: "" });
            this.props.onUpdateLoggedInUser(result.user);
            this.props.selectLanguage(result.user.currentLanguage); //this should fix initial login blank articles screen
            this.getNewArticles();
            fetch('/languages/get/all', {
              method: 'get',
              credentials: 'same-origin',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
            })
            .then(langs => langs.json())
            .then(langs => {
                this.props.setAvailableLanguages(langs.languages);
            }).catch(e => console.log(e))
            loadCourseStatus();
            fetchDecks();
            fetchLessonDecks();
            logSiteVisit();
          } else {
            alert(result.message);
            console.log(result);
          }
        });
    } else {
      this.setState({ error: "Please enter a valid email and password" });
    }
  }

  render() {
    return (
      <PageTemplate>
        <div id="loginContainer" class="fadeMeIn" style={{flexDirection: 'column'}}>
          <ForgotPasswordModal />
          <div class="form-signin text-center" style={{ maxWidth: 330 }}>
            {/* <img class="mb-4" src={require('../assets/img/png')} alt="" width="72" height="72"/> */}
            <h1 class="h3 mb-3 font-weight-normal">Please Sign In</h1>
            <label for="inputEmail" class="sr-only">
              Email
            </label>
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              autofocus
              value={this.state.email}
              onChange={x => this.setState({ email: x.target.value })}
            />
            <label for="inputPassword" class="sr-only">
              Password
            </label>
            <input
              type="password"
              onKeyPress={this.checkSubmit.bind(this)}
              id="inputPassword"
              class="form-control"
              placeholder="Password"
              value={this.state.password}
              onChange={x => this.setState({ password: x.target.value })}
            />
            <small style={{ opacity: 0.5, margin: 5 }}>
              By clicking sign in, you agree to the{" "}
              <a target="_blank" href="https://lingomastery.com/terms-of-use">
                terms of use
              </a>{" "}
              and{" "}
              <a target="_blank" href="https://lingomastery.com/subscriber-agreement">
                subscriber agreement
              </a>
              .
            </small>
            <br />
            <br />
            <button class="btn btn-lg btn-primary btn-block" onClick={this.loginUser.bind(this)}>
              {this.state.loading ? <PulseLoader color="#f7f7f7" /> : "Sign in"}
            </button>

            <p class="mt-5 mb-3 text-muted">
              <span data-toggle="modal" data-target="#forgotPasswordModal" style={{ cursor: "pointer" }}>
                Forgot Password?
              </span>
            </p>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  selectLanguage,
  setAvailableLanguages,
  updateArticles
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(LoginPage);

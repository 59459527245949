import React, { Component } from "react";
import { toggleNav, updateLoggedInUser, setLastClickedArticleId, articlesNeedReload } from "../../redux/actions";
import { connect } from "react-redux";
import { isMobile, isBrowser } from "react-device-detect";
import Cookies from "universal-cookie";
import { Scrollbars } from 'react-custom-scrollbars'

//components
import Paragraph from "../../components/read-page/Paragraph";
import LoadingOverlay from "../../components/LoadingOverlay";
import TranslationPopover from "../../components/TranslationPopover";
import HighlightWindow from "../../components/read-page/HighlightWindow";
import Colors from "../../assets/Colors";
import ReadStoryClass from "../ReadStoryPage/ReadStoryClass";
import PlayerControls from "../../components/read-page/PlayerControls";
import Pagination from "../../components/read-page/Pagination";

const cookies = new Cookies();
var Sound = require("react-sound").default;
var shortid = require("shortid");
const queryString = require("querystring");


class EmbedStoryPage extends ReadStoryClass {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    setTimeout(() => window.scrollTo({ top: 0 }), 100);
    window.addEventListener("scroll", this.handleScroll.bind(this));
    this.setState({ loading: true });

    let articleRoute = this.props.loggedInUser ? "/article/get/" : "/article/getfree/";
    var result = await global.apiRequest({ url: articleRoute + this.props.router.match.params.id });
    if (result.status == "success") {
      result.story.pages = JSON.parse(result.story.pages);
      this.setState({
        loading: false,
        article: result.story,
        tooltipId: "paragraphCard0p0",
        tutorialTooltipOpen: true,
        tutorialTooltipText: "Click on a word to define it"
      });
    } else {
      this.setState({ loading: false, notSignedUp: true });
      alert(result.message);
    }

    //TUTORIAL COOKIES - should we show tutorial? check cookies
    // not using thsi ATM, was for popups
    const tutorialVar = "showTutorial2";
    if (!this.props.loggedInUser && !cookies.get(tutorialVar)) {
      this.setState({ showTutorial: true });
      cookies.set(tutorialVar, "done");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  //-------METHODS---------------

  handleScroll() {
    if (this.state.popoverOpen) this.setState({ popoverOpen: false });
  }

  render() {
    let { isRead, isFavorite } = this.state;
    let { loggedInUser } = this.props;
    let hide_audio = queryString.parse(this.props.router.location.search.replace("?", "")).hide_audio;

    let wrapperStyle = {
      paddingTop: hide_audio ? 0 : 120,
      height: '100vh',
      position: 'relative'
    }

    return (
      <div style={wrapperStyle}>
        <LoadingOverlay hide={this.state.loading == false} />
        {hide_audio ? null : 
          <PlayerControls
            setRef={(name, ref) => (this[name] = ref)}
            musicBoxStyle={styles.musicBoxStyle}
            musicPlayerLocked={true}
            position={this.state.position}
            duration={this.state.duration}
            togglePlaybackRate={() => this.togglePlaybackRate()}
            playbackRate={this.state.playbackRate}
            handlePlayPause={() => this.handlePlayPause()}
            playStatus={this.state.playStatus}
            handleSkip={(x) => this.handleSkip(x)}
          />
        }

        {this.state.popoverOpen ? <div style={styles.popoverBackgroundButton} onClick={this.togglePopover.bind(this)} /> : null}

        <Sound
          autoLoad={true}
          onLoading={(x) => this.setState({ duration: x.duration })}
          url={this.state.article.audio}
          playStatus={this.state.playStatus}
          // playFromPosition={0 /* in milliseconds */}
          // onLoading={this.handleSongLoading}
          onPlaying={(x) => this.handleSongPlaying(x)}
          position={this.state.position}
          playbackRate={this.state.playbackRate}
          // onFinishedPlaying={this.handleSongFinishedPlaying}
        />

        <HighlightWindow
          show={this.state.showHighlight}
          item={this.state.highlightItem}
          hide={() => this.setState({ showHighlight: false })}
          language={this.state.article.language}
        />
        <Scrollbars style={{height: '100%'}}>
          <div class="mx-auto" style={styles.mainpage}>
            {this.state.article
              ? this.state.article.pages[this.state.currentPageIndex].map((item, index) => {
                  return (
                    <div class="fadeMeIn">
                      <Paragraph
                        position={this.state.position}
                        handlePlayPause={this.handlePlayPause.bind(this)}
                        playStatus={this.state.playStatus}
                        nextParagraphStartsAt={(this.state.article.pages[this.state.currentPageIndex][index+1] || {}).startsAt}
                        ref={(x) => (this["paragraph" + index] = x)}
                        onboardingRefSetter={(name, ref) => (this[name] = ref)}
                        paragraph={item}
                        id={index + "p" + this.state.currentPageIndex}
                        handlePlayParagraph={(x) => this.handlePlayParagraph(x)}
                        wordClicked={(word, translation, id) => this.handleWordClicked(word, translation, id)}
                        key={index + "p" + this.state.currentPageIndex}
                        index={index}
                        highlightClick={() => this.handleHighlightClick(item)}
                        loggedInUser={loggedInUser}
                      />
                    </div>
                  );
                })
              : null}

            <TranslationPopover
              language={this.state.article.language ? this.state.article.language.toLowerCase() : ""}
              open={this.state.popoverOpen}
              popoverInfo={this.state.popoverInfo}
              toggleSelf={this.togglePopover.bind(this)}
            />
  {/* 
            <nav style={styles.bottomStuff}>
              <Pagination
                handleBackPage={this.handleBackPage.bind(this)}
                handleNextPage={this.handleNextPage.bind(this)}
                handlePageClick={(pgIndex) => this.handlePageClick(pgIndex)}
                article={this.state.article}
                currentPageIndex={this.state.currentPageIndex}
              />
              <div>
                <br />
              </div>
            </nav> */}
          </div>

        </Scrollbars>
      </div>
    );
  }
}

const styles = {
  musicBoxStyle: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 25,
    boxShadow: "0 0px 13px 5px rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1)",
  },
  bottomStuff: {
    float: "bottom",
    marginTop: 50,
  },
  mainpage: {
    float: "none",
    position: "relative",
    maxWidth: 780,
    backgroundColor: "white",
    padding: 15,
    paddingTop: 25,
    borderRadius: 3,
    // marginTop: 20,
    // boxShadow: '0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)'
  },

  popoverBackgroundButton: {
    // position: 'absolute',
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    // height: '100vh'
  },
  tutorialTooltipText: {
    fontSize: 18,
    // opacity: .7,
    fontWeight: "bold",
    color: "#fff",
  },
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage,
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
  articlesNeedReload,
};

export default connect(mapStateToProps, mapActionsToProps)(EmbedStoryPage);

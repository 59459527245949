import React, { Component } from 'react';

class LightButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { onClick, text, imgSrc, dataToggle, href, dark } = this.props;
    return(
      <span style={styles.button} data-toggle={dataToggle || null} href={href || null} class={dark ? 'lightButtonDark' : 'lightbutton'} onClick={onClick} >
      <img src={imgSrc} style={styles.buttonIcon} />
      <span style={styles.buttonText}> {text}</span>
    </span>
    );
  }
}

const styles = {
  button: {
    color:  '#0d55cf',
    borderRadius: 3,
    // minWidth: 120,
    padding: 8,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 7,
    // fontWeight: '500',
    zIndex: 3,
  },
  buttonIcon: {
    height: 18,
    width: 18,
    margin:5,
    marginRight: 10,
    marginLeft: 0,
    marginTop: 2,
  },
  buttonText: {
    color: '#0d55cf',
    fontSize: 13,
    margin: 'auto'
  },
};

export default LightButton;
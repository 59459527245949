import { combineReducers } from 'redux';
import { decksReducer } from './decks';
import * as types from '../types';
import { lessonDecksReducer } from './lessonDecks';
import { filtersReducer } from './filters';
import { courseStatusReducer } from './courses';

export function loggedInUserReducer(state = null, action) {
  switch (action.type) {
    case types.UPDATE_LOGGED_IN_USER:
      return action.payload;
    default: return state;
  }
}

export function toggleNavReducer(state = '', action) {
  switch (action.type) {
    case types.TOGGLE_NAV:
      return action.payload;
    default: return state;
  }
}

export function selectLanguageReducer(state = 'italian', action) {
  switch (action.type) {
    case types.SELECT_LANGUAGE:
      return action.payload;
    default: return state;
  }
}

export function podcastMetaReducer(state = {isPodcast: false}, action) {
  switch (action.type) {
    case types.SET_PODCAST_STATUS:
      return {...state, isPodcast: action.payload};
    default: return state;
  }
}

export function setAvailableLanguagesReducer(state = ['italian'], action) {
  switch (action.type) {
    case types.SET_LANGUAGES:
      return action.payload;
    default: return state;
  }
}

export function searchTextReducer(state = '', action) {
  switch (action.type) {
    case types.UPDATE_SEARCH_TEXT:
      return action.payload;
    default: return state;
  }
}

export function articleReducer(state = {articlesNeedReload: true, articles: [], categories: []}, action) {
  switch (action.type) {
    case types.UPDATE_ARTICLES:
      return {
        ...state, 
        articles: action.payload.articlesArray, 
        categories: action.payload.categories
      };
    case types.ARTICLES_NEED_RELOAD:
      return {...state, articlesNeedReload: action.payload};
    default: return state;
  }
}

export function lastClickedArticleReducer(state = '', action) {
  switch (action.type) {
    case types.LAST_CLICKED_ARTICLE:
      return action.payload;
    default: return state;
  }
}

export function wordListReducer(state = [], action) {
  switch (action.type) {
    case types.UPDATE_WORD_LIST:
      return action.payload;
    default: return state;
  }
}


export default combineReducers({
  loggedInUser: loggedInUserReducer,
  navState: toggleNavReducer,
  selectedLanguage: selectLanguageReducer,
  availableLanguages: setAvailableLanguagesReducer,
  searchText: searchTextReducer,
  articleInfo: articleReducer,
  lastClickedArticleId: lastClickedArticleReducer,
  wordList: wordListReducer,
  deckInfo: decksReducer,
  lessonDeckInfo: lessonDecksReducer,
  filters: filtersReducer,
  courseStatus: courseStatusReducer,
  podcastMeta: podcastMetaReducer

})
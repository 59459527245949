import React, { Component } from "react";
import Colors from "../assets/Colors";
import { ClipLoader } from "react-spinners";
import { thatReturnsThis } from "react-card-flip";

class LoadingOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRender: true,
      isHiding: false
    };
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if(!prevProps.hide && this.props.hide){
  //     // show again!
  //   }

  //   if(prevProps.hide && !this.props.hide){
  //     // hide it nicely

  //   }

  //   if(this.props.hide && this.state.shouldRender && !this.state.isHiding){

  //   }
  // }

  render() {
    let hide = this.props.hide;
    let containerStyle = {
      width: "100%",
      paddingTop: "30vh",
      position: "fixed",
      height: "100vh",
      zIndex: 2000,
      backgroundColor: "rgba(255,255,255,.6)",
      transition: ".3s",
      opacity: hide ? 0 : 1,
    };
    if (hide) containerStyle.pointerEvents = "none";

    return (
      <div class="text-center" style={containerStyle}>
        <div style={styles.insideContainer} class="text-center col-4 offset-4">
          <div style={{ filter: "blur(0px)" }}>
            <ClipLoader color={"white"} />
            <p style={{ color: "white", fontWeight: "bold" }}>Loading...</p>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  insideContainer: {
    backgroundColor: "rgba(0,0,0,.15)",
    width: "30%",
    minHeight: 130,
    paddingTop: 40,
    borderRadius: 3,
  },
};

export default LoadingOverlay;

import React, { Component } from 'react';
import { updateSearchText } from "../../redux/actions";
import { connect } from "react-redux";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <span style={styles.wrapper}>
        <span style={styles.fakeInput}>
          <input
            type='text'
            onChange={e => this.props.updateSearchText(e.target.value)}
            value={this.props.searchText}
            style={styles.input}
            placeholder="Search"
          />
          <img src={require('../../assets/img/search.png')} style={styles.icon} />
        </span>
      </span>
    );
  }
}

const styles = {
  wrapper: {
    marginRight: 15,
    display: 'inline-block'
  },
  input: {
    backgroundColor: 'transparent',
    border: 'none',
    // height: 30,
    borderRadius: 50,
    fontSize: 14,
    width: 220,
    height: 36
  },
  fakeInput: {
    borderRadius: 50,
    backgroundColor: '#f1f2f4',
    // padding: 9,
    paddingLeft: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative'
  },
  icon: {
    height: 15,
    // marginBottom: 2,
    marginRight: 12,
    // marginLeft: 3
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  searchText: state.searchText
});

const mapActionsToProps = {
  updateSearchText
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Search);
var decode = require("parse-entities"); //for some reason google translate was returning html entities (decimal) as plain text for apostrophes. i wonder if this library is messing with the format issues i was having below with line breaks...
export default function (word) {
  return new Promise(async (resolve) => {
    let returnObject = {};
    let dictionaryRes = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en_US/${word}`).then((res) => res.json());
    console.log("dictionary res", dictionaryRes);
    let meanings = [];
    try {
      meanings = (dictionaryRes[0].meanings || []).map((meaning) => {
        let newMeaning = {};
        newMeaning.partOfSpeech = meaning.partOfSpeech;
        try {
          newMeaning.synonyms = meaning.definitions[0].synonyms.slice(0, 3);
        } catch (e) {
          console.warn(e);
        }
        return newMeaning;
      });
    } catch (e) {
      console.warn(e);
    }
    returnObject.meanings = meanings;

    resolve(returnObject);
  });
}

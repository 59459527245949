import React, { Component } from "react";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { Link } from "react-router-dom";
import Colors from "../../../assets/Colors";
import { message } from "antd";
import CourseCard from "../../../components/courses/CourseCard/CourseCard";
import AdminInput from "../../../components/admin/AdminInput";

class AdminManageCoursesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      courses: [],
      languageOptions: [],
    };
  }

  componentDidMount() {
    this.loadCourses();
    this.loadLanguages();
  }

  loadLanguages = async () => {
    var langRes = await global.apiRequest({ url: "/languages/get/all" });
    const formattedLanguages = langRes.languages.map((l) => {
      return {
        label: l.title,
        value: l.title,
      };
    });
    if (langRes.languages.length > 0) this.setState({ languageOptions: formattedLanguages });
  };

  loadCourses = () => {
    global.apiRequest({ url: `/courses/getall` }).then((res) => {
      this.setState({
        courses: res.courses,
        loading: false,
      });
    });
  };

  onClickCourse = (course) => {
    this.props.router.history.push("/manage-courses/edit/" + course.id);
  };

  render() {
    const { languageOptions, filterLanguage } = this.state;
    let filteredCourses = [...this.state.courses];
    if (filterLanguage) filteredCourses = filteredCourses.filter((x) => x.language === filterLanguage);

    return (
      <div style={styles.wrapper} class="container">
        <AdminNavbar active={"manage-courses"} />
        {this.state.loading ? <LoadingOverlay /> : null}
        <div style={styles.topRow}>
          <h1>Courses</h1>
          <Link to="/manage-courses/new">
            <div class="btn btn-primary">+ New Course</div>
          </Link>
        </div>
        <div style={styles.topRow2}>
          <AdminInput
            labelWidth={200}
            style={{ maxWidth: 400 }}
            label="Filter Language"
            options={languageOptions}
            value={this.state.filterLanguage}
            onChange={(x) => this.setState({ filterLanguage: x })}
          />
        </div>
        <div style={styles.courseRow}>
          {filteredCourses.map((c) => {
            return <CourseCard onClick={() => this.onClickCourse(c)} course={c} />;
          })}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    // padding: 20,
    paddingTop: 75,
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  courseRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};

export default AdminManageCoursesPage;

import React, { Component } from "react";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import LoadingOverlay from "../../../components/LoadingOverlay";
import DeckRow from "./DeckRow";
import { Button, Input, message, Popover } from "antd";
import Colors from "../../../assets/Colors";
import { SketchPicker, CompactPicker } from 'react-color'

class EditLessonDeckPage extends Component {
  constructor(props) {
    super(props);
    let isEdit = props.router.location.pathname.includes("/new") ? false : true;
    this.state = {
      loading: isEdit ? true : false,
      deck: {
        list: [],
        color: "#220597"
      },
      languages: [],
    };
  }

  componentDidMount() {
    let isEdit = this.props.router.location.pathname.includes("/new") ? false : true;
    if (isEdit) this.loadDeckInfo();
    this.loadLanguages();
  }

  loadLanguages = async () => {
    var langRes = await fetch("/languages/get/all", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((result) => result.json());
    if (langRes.languages.length > 0) this.setState({ languages: langRes.languages });
  };

  loadDeckInfo = () => {
    fetch(`/lessondecks/get/${this.props.router.match.params.id}`, {
      method: "GET",
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({
            loading: false,
            deck: result.deck,
          });
        } else {
          alert(result.message);
          this.setState({ loading: false });
        }
      });
  };

  setDeckItem = (key, val) => {
    this.setState({
      deck: {
        ...this.state.deck,
        [key]: val,
      },
    });
  };

  onRemoveCard = (card) => {
    let newList = [...this.state.deck.list];
    newList = newList.filter((x) => x.word !== card.word);
    this.setState({
      deck: {
        ...this.state.deck,
        list: newList,
      },
    });
  };

  addWord = () => {
    if (!this.state.newWord || !this.state.newTranslation) return message.error("Please enter a word and translation");
    const theCard = {
      word: this.state.newWord,
      translation: this.state.newTranslation,
    };
    console.log("thisstatedeck", this.state.deck);
    this.setState({
      deck: {
        ...this.state.deck,
        list: [theCard, ...this.state.deck.list],
      },
      newWord: "",
      newTranslation: "",
    });
    this.newWordInput.focus();
  };

  onTranslationKeyPress = (e) => {
    if (e.key == "Enter") this.addWord();
  };

  getErrorMessage = () => {
    let msg = undefined;
    const { title, list, language } = this.state.deck;
    if (list.length < 1) msg = "Please add at least 1 flashcard";
    if (!title) msg = "Please add a title";
    if (!language) msg = "Please select a language for this deck.";
    return msg;
  };

  submit = () => {
    let isEdit = this.props.router.location.pathname.includes("/new") ? false : true;
    let deckId = this.props.router.match.params.id;
    let errorMessage = this.getErrorMessage();
    if (errorMessage) return alert(errorMessage);

    this.setState({ loading: true });

    fetch(isEdit ? `/lessondecks/edit/${deckId}` : `/lessondecks/new`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        deck: this.state.deck,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({ loading: false });
          message.success(`${isEdit ? "Saved" : "Created"} deck!`);
          this.props.router.history.push("/manage-lesson-decks");
        } else {
          alert(result.message);
          this.setState({ loading: false });
        }
      });
  };

  onClickDelete = () => {
    if(window.confirm("Are you sure you want to delete this deck? click OK to delete")) this.onConfirmDelete()
  }

  onConfirmDelete = () => {
    let deckId = this.props.router.match.params.id;

    fetch(`/lessondecks/delete/${deckId}`, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({ loading: false });
          message.success('Deleted deck');
          this.props.router.history.push("/manage-lesson-decks");
        } else {
          alert(result.message);
          this.setState({ loading: false });
        }
      });
  }

  onChangeColor =color => {
    this.setState({
      deck: {
        ...this.state.deck,
        color: color.hex
      }
    })
  }

  render() {
    let isEdit = this.props.router.location.pathname.includes("/new") ? false : true;
    let colorPickerContent = <SketchPicker color={this.state.deck.color} onChangeComplete={this.onChangeColor} />;

    return (
      <div style={styles.wrapper}>
        <div class="container" style={{paddingBottom: 80}}>
          <AdminNavbar active={"manage-lesson-decks"} />
          {this.state.loading ? <LoadingOverlay /> : null}
          <h1>{isEdit ? "Edit Deck" : "New Lesson Deck"}</h1>
          <br />
          <br />

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Title</label>
            <div class="col-sm-10">
              <input
                maxLength="253"
                type="text"
                class="form-control"
                placeholder="title"
                onChange={(x) => this.setDeckItem("title", x.target.value)}
                value={this.state.deck.title}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Color</label>
            <div class="col-sm-10">
              
              <Popover placement='right' content={colorPickerContent}>
              <div style={{...styles.colorSquare, backgroundColor: this.state.deck.color }}></div>
              </Popover>
            </div>
          </div>

          <div class="form-group row">
            <label for="description" class="col-sm-2 col-form-label">
              Language
            </label>
            <div class="col-sm-5">
              <div class="form-group">
                <select
                  class="form-control"
                  onChange={(x) => this.setDeckItem("language", x.target.value)}
                  value={this.state.deck.language}
                >
                  <option value={null}></option>
                  {this.state.languages.map((item, index) => {
                    return <option value={item.title}>{item.title}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>

          {/* <h3>Add Card</h3> */}

          <div style={styles.decksContainer}>
            <h3>Cards ({this.state.deck.list.length})</h3>
          <div style={styles.newCardContainer}>
            <Input
              ref={(x) => (this.newWordInput = x)}
              style={styles.wordInput}
              value={this.state.newWord}
              onChange={(e) => this.setState({ newWord: e.target.value })}
              placeholder="Word"
            />
            <Input
              style={styles.wordInput}
              value={this.state.newTranslation}
              onChange={(e) => this.setState({ newTranslation: e.target.value })}
              onKeyPress={this.onTranslationKeyPress}
              placeholder="Translation"
            />
            <Button onClick={this.addWord}>Add</Button>
            <small style={{ opacity: 0.5, marginLeft: 10 }}>Use tab & enter keys for quick adding</small>
          </div>
            {(this.state.deck.list || []).map((card, index) => {
              return (
                <DeckRow
                  key={card.word + card.translation}
                  word={card.word}
                  translation={card.translation}
                  onDelete={() => this.onRemoveCard(card)}
                />
              );
            })}
           
          </div>

          <div style={styles.bottomBar}>
            {isEdit ? 
          <div class="btn btn-outline-danger"  onClick={this.onClickDelete}>
              Delete Deck
            </div>
            :<div />}
            <div class="btn btn-primary" style={{ width: 120 }} onClick={this.submit}>
              {isEdit ? "Save" : "Submit"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    paddingTop: 70,
    display: "flex",
    flexDirection: "column",
    // paddingBottom: 80,
    height: '100vh',
    backgroundColor: Colors.blueBG,
    overflowY: 'auto'
  },
  wordInput: {
    marginRight: 15,
    width: 250,
  },
  newCardContainer: {
    marginBottom: 25,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  decksContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  bottomBar: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
    padding: 10,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: Colors.border,
  },
  colorSquare: {
    height: 28,
    width: 28,
    borderRadius: 4,
    cursor: 'pointer',
    borderWidth :2,
    borderStyle: 'solid',
    borderColor: 'black'
  }
};

export default EditLessonDeckPage;

import React, { Component } from 'react';
import MyNavbar from '../../components/MyNavbar';
import { Link, Redirect } from 'react-router';
import { toggleNav, updateLoggedInUser, setLastClickedArticleId, articlesNeedReload } from '../../redux/actions';
import { connect } from 'react-redux';
import { isMobile, isBrowser } from 'react-device-detect';
import Cookies from 'universal-cookie';
import {Helmet} from "react-helmet";


//components
import StoryTopBox from '../../components/read-page/StoryTopBox';
import Paragraph from '../../components/read-page/Paragraph';
import Footer from '../../components/Footer';
import SideNav from '../../components/SideNav';
import LoadingOverlay from '../../components/LoadingOverlay';
import TranslationPopover from '../../components/TranslationPopover';
import NotLoggedIn from '../../components/NotLoggedIn';
import HighlightWindow from "../../components/read-page/HighlightWindow";
import Colors from '../../assets/Colors';
import ReadFavoriteButtons from '../../components/read-page/ReadFavoriteButtons';
import PageTemplate from '../PageTemplate';
import ReadStoryClass from './ReadStoryClass';
import Socials from '../../components/Socials';
import Pagination from '../../components/read-page/Pagination';


const cookies = new Cookies();
var Sound = require('react-sound').default;
var shortid = require('shortid');
// const translate = require('google-translate-api');

class ReadStoryPage extends ReadStoryClass {

    //----------LIFECYCLE------------------
    async componentDidMount(){
      setTimeout(() => window.scrollTo({ top: 0}), 100); 
      //clickfunnels popup - see element created in JSX also that goes with this
      // if(!this.props.loggedInUser){
      //   const script = document.createElement("script");
      //   script.src = "https://www.clickfunnels.com/assets/cfpop.js";
      //   script.async = true;
      //   document.body.appendChild(script);
      // }

      // check every second if user is 90% done with listening to story, so we can mark it as read.
      let readChecker = setInterval(() => {
        if(this.state.checkToMarkRead && this.state.position/this.state.duration >.9 && this.state.isRead==false){
          this.handleReadClick();
          clearInterval(readChecker);
        }
      }, 1000);

      window.addEventListener('scroll', this.handleScroll.bind(this));
      // window.addEventListener('mousedown', this.handleExitDivClick.bind(this));
      this.setState({loading: true});

      let articleRoute = this.props.loggedInUser ? '/article/get/' : '/article/getfree/';
      var result = await global.apiRequest({ url: articleRoute + this.props.router.match.params.id });
      if(result.status=='success') {
        result.story.pages = JSON.parse(result.story.pages);
        let isFavorite = false;
        let isRead = false;
        if(result.story.UserStories){
          isFavorite = result.story.UserStories[0] ? result.story.UserStories[0].isFavorite : false;
          isRead = result.story.UserStories[0] ? result.story.UserStories[0].isRead : false;
        }
        this.setState({
          loading: false,
          article: result.story,
          isFavorite: isFavorite,
          isRead: isRead,
          clickfunnelsFormSource: this.getClickfunnelsFormSource(result.story.language),
          tooltipId: 'paragraphCard0p0', 
          tutorialTooltipOpen: true, 
          tutorialTooltipText: 'Click on a word to define it'
        });
      } else {
        this.setState({ loading: false, notSignedUp: true });         
        // alert('could not find article with id: '+this.props.router.match.params.id);
        alert(result.message)
        // this.props.router.history.push('/browse');
      }
      

      //TUTORIAL COOKIES - should we show tutorial? check cookies
      // not using thsi ATM, was for popups
      const tutorialVar = 'showTutorial2';
      if(!this.props.loggedInUser && !cookies.get(tutorialVar)){
        this.setState({showTutorial: true});
        cookies.set(tutorialVar, 'done');
      }
    }

    componentWillUnmount(){
      window.removeEventListener('scroll', this.handleScroll );
    }

    //-------METHODS---------------



    handleScroll() {
      if (this.state.popoverOpen) this.setState({ popoverOpen: false });
    }

    handleReadClick(e){
      let isRead = !this.state.isRead;
      this.setState({isRead: isRead});
      fetch('/article/read/'+this.props.router.match.params.id, {
        method: 'post',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ isRead: isRead })
      })
      .then(result => result.json())
      .then(result => {
        this.props.articlesNeedReload(true);
      })
    }

    initOnboarding(){
      // if(!this.state.showTutorial) return; // skip
      // handle if user logged in ?

      // let translateButton = $('#translateButtonTooltipTarget');
      // let word = $('.onboardingWord');


      // let animateItem = function(x){
      //   x.animate({opacity: '0'}, 500,
      //     function(){
      //       x.animate({opacity: '1'}, 500)
      //     });
      //     setTimeout(() => {
      //       animateItem(x);
      //     }, 1300);
      // }
      
      // animateItem(word);
      // animateItem(translateButton);


      // // word.css({border: '1px solid blue'});
      // word.mouseenter(function(){
      //   word.animate(null);
      // })
      
      
    }


  getClickfunnelsFormSource(lang) {
    // lang = lang.toLowerCase();
    // switch (lang) {
    //   case 'spanish':
    //     return 'https://storyling.clickfunnels.com/optin22669949';
    //     break;
    //   case 'french':
    //     return 'https://storyling.clickfunnels.com/optin';
    //     break;
    //   case 'german':
    //     return 'https://storyling.clickfunnels.com/optin25822488';
    //     break;
    //   case 'italian':
    //     return 'https://storyling.clickfunnels.com/optin25822507';
    //     break;
    //   case 'russian':
    //     return 'https://storyling.clickfunnels.com/optin';
    //     break;
    //   case 'portuguese':
    //     return 'https://storyling.clickfunnels.com/optin25822528';
    //     break;
    //   default:
    //     break;
    // }
    return ''
  }

    render() {
      let { isRead, isFavorite } = this.state;
      let { loggedInUser } = this.props;

        return this.state.notSignedUp ? (
          <NotLoggedIn />
        ) : (
          <div>
            <MyNavbar />
            <div id={loggedInUser ? "sideNavWrapper" : ""} class={this.props.navState}>
              {loggedInUser ? <SideNav closePopover={this.props.closePopover} /> : null}
              <div id="page-content-wrapper" style={{ backgroundColor: Colors.blueBG }}>
                <div class="fadeMeIn">
                  <LoadingOverlay hide={this.state.loading == false} />

                  <StoryTopBox
                    article={this.state.article}
                    top={x => this.setState({ bodyTop: x })}
                    handleDrag={x => this.handleDrag(x)}
                    handlePlayPause={() => this.handlePlayPause()}
                    playStatus={this.state.playStatus}
                    handleSkip={x => this.handleSkip(x)}
                    duration={this.state.duration}
                    position={this.state.position}
                    playTime={x => this.handlePlayParagraph(x)}
                    togglePlaybackRate={() => this.togglePlaybackRate()}
                    playbackRate={this.state.playbackRate}
                  />

                  {this.state.popoverOpen ? (
                    <div style={styles.popoverBackgroundButton} onClick={this.togglePopover.bind(this)} />
                  ) : null}

                  <Sound
                    autoLoad={true}
                    onLoading={x => this.setState({ duration: x.duration })}
                    url={this.state.article.audio}
                    playStatus={this.state.playStatus}
                    // playFromPosition={0 /* in milliseconds */}
                    // onLoading={this.handleSongLoading}
                    onPlaying={x => this.handleSongPlaying(x)}
                    position={this.state.position}
                    playbackRate={this.state.playbackRate}
                    // onFinishedPlaying={this.handleSongFinishedPlaying}
                  />

                  <HighlightWindow
                    show={this.state.showHighlight}
                    item={this.state.highlightItem}
                    hide={() => this.setState({ showHighlight: false })}
                    language={this.state.article.language}
                  />

                  {/* Clickfunnels page leave popup - see componentDidMount method also */}
                  {/* <a href='https://storyling.clickfunnels.com/optin_box/i8bsqcokky7zbszj' data-delay='0' data-exit='true' ><img src="" /></a> */}

                  <div
                    style={{
                      position: "absolute",
                      top: this.state.bodyTop,
                      width: "100%",
                      minHeight: "50%",
                      backgroundColor: Colors.blueBG
                    }}>
                    <div class="mx-auto" style={styles.mainpage}>
                      {this.state.article
                        ? this.state.article.pages[this.state.currentPageIndex].map((item, index) => {
                            return (
                              <div class="fadeMeIn">
                                <Paragraph
                                  ref={x => (this["paragraph" + index] = x)}
                                  onboardingRefSetter={(name, ref) => (this[name] = ref)}
                                  paragraph={item}
                                  position={this.state.position}
                                  handlePlayPause={this.handlePlayPause.bind(this)}
                                  nextParagraphStartsAt={(this.state.article.pages[this.state.currentPageIndex][index+1] || {}).startsAt}
                                  playStatus={this.state.playStatus}
                                  id={index + "p" + this.state.currentPageIndex}
                                  handlePlayParagraph={x => this.handlePlayParagraph(x)}
                                  wordClicked={(word, translation, id) => this.handleWordClicked(word, translation, id)}
                                  key={index + "p" + this.state.currentPageIndex}
                                  index={index}
                                  highlightClick={() => this.handleHighlightClick(item)}
                                  loggedInUser={loggedInUser}
                                />
                              </div>
                            );
                          })
                        : null}
                      {/* {loggedInUser ? null : (
                        <iframe
                          style={{ overflow: "hidden" }}
                          scrolling="no"
                          src={this.state.clickfunnelsFormSource}
                          width="100%"
                          height={isMobile ? "400" : "350"}
                          frameborder="0"
                        />
                      )} */}
                    </div>

                    <TranslationPopover
                      language={this.state.article.language ? this.state.article.language.toLowerCase() : ""}
                      open={this.state.popoverOpen}
                      popoverInfo={this.state.popoverInfo}
                      toggleSelf={this.togglePopover.bind(this)}
                    />

                    {/* {!loggedInUser ? <div><SignUpBanner show={this.state.currentPageIndex==this.state.article.pages.length-1 ? true : false} /><br/></div> : null}                        */}
                    <nav style={styles.bottomStuff}>
                      {/*// oscar said hide the pagination for now
                       <Pagination
                        handleBackPage={this.handleBackPage.bind(this)}
                        handleNextPage={this.handleNextPage.bind(this)}
                        handlePageClick={pgIndex => this.handlePageClick(pgIndex)}
                        article={this.state.article}
                        currentPageIndex={this.state.currentPageIndex}
                      /> */}
                      <div>
                        {loggedInUser ? (
                          <ReadFavoriteButtons isFavorite={isFavorite} isRead={isRead} articleID={this.state.article.id} />
                        ) : null}
                        {/* <hr style={{width: isMobile ? '100%' : 800}}/> */}
                        <div style={styles.socialIconsWrapper}>
                          {loggedInUser ? null : (
                           <Socials />
                          )}
                        </div>
                        <br />
                        <Footer buttonInfo={loggedInUser ? { isFavorite, isRead, articleID: this.state.article.id } : null} />
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage,
  deckInfo: state.deckInfo
})

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
  articlesNeedReload
}

export default connect(mapStateToProps, mapActionsToProps)(ReadStoryPage);


const styles = {
  oneLine: {
    // whiteSpace: 'wrap',
    overflow: 'hidden'
  },
  nextButton: {
    float: 'right'
  },
  tutorialOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,.4)',
    zIndex: 50,
  },
  bottomStuff: {
    float: 'bottom',
    marginTop: 50,
  },
  mainpage:{
    float: 'none',
    position: 'relative',
    maxWidth: 780,
    backgroundColor: 'white',
    padding: 15,
    paddingTop: 5,
    borderRadius: 3,
    marginTop: 20,
    boxShadow: '0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)'
  },


  popoverBackgroundButton: {
    // position: 'absolute',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    // height: '100vh'
  },
  tutorialTooltipText: {
    fontSize: 18,
    // opacity: .7,
    fontWeight: 'bold',
    color: '#fff'
  },
  tutorialPopover: {
    backgroundColor: '#82ca1c',
    borderRadius: 3,
    borderColor: '#82ca1c',
    boxShadow: '0 3px 12px 0 rgba(0, 0, 0, 0.28)',
    transform: 'scale(1.02)'

  },

  bottomLabel: {
    color: 'rgb(94,94,94)',
    marginRight: 30,
    marginLeft: 10,
    display: 'inline-block',
  },
  bottomCol: {
    display: 'inline-block',
    cursor: 'pointer'
  },
  bottomIcon: {
    position: 'relative',
    fontSize: 24,
    top: 2
  }
}

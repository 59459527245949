import React, { Component } from 'react';
import moveIcon from '../../../assets/img/move.png';

class LesssonRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { lesson, isSelected, onClick, dragHandleProps } = this.props;
    const styles = {
      wrapper: {
        backgroundColor: isSelected ? '#EBEBEB' : 'white',
        width: '100%',
        cursor: 'pointer',
        padding: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        // height: 50,
        minHeight: 50,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
      },
      title: {
        fontWeight: isSelected ? 'bold' : 'normal'
      },
      dragHandle: {
        marginLeft: 10,
        padding: 5,
        cursor: 'grab'
      },
      moveIcon: {
        height: 18,
        width: 18,
        opacity: .8
      }
    };
    let prefix = ''
    if(lesson.sortIndex !== undefined) prefix = (lesson.sortIndex+1)+'. ';
    return(
      <div style={styles.wrapper} onClick={onClick}>
        <div style={styles.title}>{prefix + lesson.title || ''}</div>
        <div className='drag-handle' {...dragHandleProps} style={styles.dragHandle}>
          <img src={moveIcon} style={styles.moveIcon} />
        </div>
      </div>
    );
  }
}



export default LesssonRow;
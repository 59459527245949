import React, { Component } from 'react';
import AdminNavbar from '../../components/admin/AdminNavbar';
import { Link, Redirect } from 'react-router';
import { updateLoggedInUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';


class AdminDashboardPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                <AdminNavbar />
                <div class='container fadeMeIn' style={{paddingTop: 75}}>
                    <h1>admin dashboard page</h1>
                    <p>may not need a dashboard page, this can just link directly to the manage articles page...</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(AdminDashboardPage);

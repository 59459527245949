import React from "react";
import translate from '../../scripts/translate';
import InfoPopup from '../../components/InfoPopup';
import dictionary from "../../scripts/dictionary";
import removePunctuation from "../../scripts/removePunctuation";

export default class ReadStoryClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHighlight: false,
      notSignedUp: false,
      checkToMarkRead: true, //should the interval mark the story as read if its past 90% ?
      playbackRate: 1,
      bodyTop: 0, //height content starts at based on music player height
      loading: true,
      tooltipId: null,
      tutorialTooltipText: "",
      tutorialTooltipOpen: false,
      tutorialTooltipIndex: 0, //determine how many tooltips we have shown already
      showTutorial: false, //set using cookies
      shownDictionaryTooltip: false,
      isFavorite: false,
      isRead: false,
      article: {
        UserStories: [],
        pages: [[]]
      },
      currentPageIndex: 0,
      playStatus: "PAUSED", // 'PLAYING', 'STOPPED', 'PAUSED'
      duration: 0,
      position: 0,
      popoverOpen: false,
      popoverInfo: { word: "", id: 0, translation: null , translations: []},
    };
  }

  handleWordClicked(word, id) {
    word = removePunctuation(word);

    if (id != this.state.popoverInfo.id) this.setState({ popoverOpen: false });
    if (this.state.popoverOpen == false) this.setState({ popoverOpen: true });
    this.setState({
      popoverInfo: { word: word, id: id, addedWord: false, translations: [] },
      popoverLoading: true
    });
    translate(word, this.state.article.language, {dictionary: true}).then(translationRes => {

      this.setState({
        popoverOpen: true,
        popoverInfo: {
          ...this.state.popoverInfo,
          translation: translationRes.translation,
          translations: translationRes.translations
          // meanings: translationRes.meanings
        },
        // popoverLoading: false
      }, () => {
        // dictionary(translationRes.translation).then(dictionaryRes => {
        //   this.setState({
        //     popoverInfo: {
        //       ...this.state.popoverInfo,
        //       meanings: dictionaryRes.meanings,
        //     },
        //   });
        // })
      });
      if (!this.state.shownDictionaryTooltip) {
        this.setState({
          tooltipId: "defineWordLink",
          tutorialTooltipOpen: true,
          tutorialTooltipText: "Click here to look up a word in the dictionary",
          shownDictionaryTooltip: true
        });
      }
    });
  }

  togglePopover() {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  togglePlaybackRate() {
    let playbackRate = this.state.playbackRate == 1 ? 0.75 : 1;
    this.setState({ playbackRate: playbackRate });
  }

  handleNextPage() {
    let newIndex = this.state.currentPageIndex + 1;
    if (newIndex < this.state.article.pages.length) {
      this.setState({ currentPageIndex: newIndex });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, 50);
    } else {
      //on the last page
    }
  }

  handleBackPage() {
    let newIndex = this.state.currentPageIndex - 1;
    if (newIndex >= 0) {
      this.setState({ currentPageIndex: newIndex });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  handlePageClick(pageIndex) {
    this.setState({ currentPageIndex: pageIndex });
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 50);
  }

  handlePlayParagraph(time) {
    if (this.state.position / this.state.duration > 0.98) {
      //force play song if its over
      this.setState({ position: parseInt(time) });
      this.setState({ playStatus: "PAUSED" });
      setTimeout(() => {
        //makes song play again for some reason
        this.setState({ playStatus: "PLAYING" });
      }, 200);
    } else {
      this.setState({ position: parseInt(time) });
      this.setState({ playStatus: "PLAYING" });
    }
  }

  
  handlePlayPause(){
    this.setState({playStatus: this.state.playStatus=='PLAYING' ? 'PAUSED' : 'PLAYING'});
  }

  handleSkip(direction){
    let newPosition=0;
    switch(direction){
      case 'foreward':
        newPosition = this.state.position+5000 < this.state.duration ? this.state.position+5000 : this.state.duration;
        break;
      case 'backward':
        newPosition = this.state.position-5000 > 0 ? this.state.position-5000 : 0;
        break;
      }
      this.setState({position: newPosition});
  }

  handleSongPlaying(x){
    if(this.state.playStatus=='PLAYING'){
      this.setState({position: x.position})
    }
  }

  handleHighlightClick(item){
    this.setState({
      showHighlight: true,
      highlightItem: item
    })
  }
}

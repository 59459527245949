import store from "../redux/store";
import * as types from "../redux/types";

export function changeFilter(pageKey, obj) {
  store.dispatch({
    type: types.CHANGE_FILTER,
    payload: {pageKey, obj},
  });
}


import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Homepage from './Homepage';
import DashboardPage from "./user/DashboardPage";
import LoginPage from "./LoginPage";
import PrivacyPolicy from "./PrivacyPolicy";
import RegisterPage from "./RegisterPage";
import VerifyEmailPage from "./VerifyEmailPage";
import BrowsePage from './BrowsePage';
import ReadStoryPage from './ReadStoryPage';
import SignupPage from './SignupPage';
import NoRoute from '../components/NoRoute';

//ADMIN
import EditArticlePage from './admin/EditArticlePage';
import ManageArticlesPage from './admin/ManageArticlesPage';
import ManageLanguagesPage from './admin/ManageLanguagesPage';
import AdminLoginPage from './admin/AdminLoginPage';
import ViewUsersPage from './admin/ViewUsersPage';
import ViewUserPage from './admin/ViewUserPage';
import AdminDashboardPage from './admin/AdminDashboardPage';
import LogsPage from './admin/LogsPage';
import ManageLessonDecksPage from './admin/ManageLessonDecksPage';
import EditLessonDeckPage from './admin/EditLessonDeckPage/EditLessonDeckPage';
import AdminManageCoursesPage from './admin/AdminManageCoursesPage/AdminManageCoursesPage';
import AdminEditCoursePage from './admin/AdminEditCoursePage/AdminEditCoursePage';

//USER
import SettingsPage from './user/SettingsPage';
import WordsPage from './user/words/WordsPage';
import CoursesPage from './user/CoursesPage';
import ViewCoursePage from './user/ViewCoursePage';
import CourseLessonsPage from './user/CourseLessonsPage';

// Podcasts
import BrowsePodcastsPage from './BrowsePodcastsPage';
import PlayPodcastPage from './PlayPodcastPage';

import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module'
import AdminEditBannerPage from './admin/AdminEditBannerPage';
import EmbedStoryPage from './EmbedStoryPage';
import OauthLoginPage from './OauthLoginPage';
import AdminLanguagesPage from './admin/AdminLanguagesPage';
const tagManagerArgs = {gtmId: 'GTM-TL6SZ2J'};

class TheRoutes extends Component {

    render(){
        let { loggedInUser, podcastMeta } = this.props;
        let worker;
        let admin;
        let moderator;
        let editor;
        let loggedIn;
        if(loggedInUser){
            worker = loggedInUser.accountType==='admin' || loggedInUser.accountType==='moderator' || loggedInUser.accountType === 'editor';
            admin = loggedInUser.accountType === 'admin' ? true : false;
            moderator = loggedInUser.accountType === 'moderator' ? true : false;
            editor = loggedInUser.accountType === 'editor' ? true : false;
            loggedIn = loggedInUser.id ? true : false;
        }

        // Restrict expired subscriptions to the settings page only
        if(loggedInUser) if(loggedInUser.subscriptionActive==false){
            return (
                <BrowserRouter>
                    <Route component={SettingsPage} />
                </BrowserRouter>
            );
        }

        if(this.props.podcastMeta.isPodcast){
            return(
                <React.Fragment>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" render={() => <Redirect to='/spanish' />}  />
                            <Route exact path="/spanish" component={BrowsePodcastsPage} />
                            <Route exact path="/listen/:id" component={PlayPodcastPage}  />
                            <Route exact path="/login" render={() => loggedIn ? <Redirect to='/' /> : <LoginPage isPodcast={true}/> } />
                            <Route component={NoRoute} />
                        </Switch>
                    </BrowserRouter>
                </React.Fragment>
            )

        }else{
            return(
            <BrowserRouter>
                <Switch>
                    {/* <Route exact path="/" component={Homepage} /> */}
                    <Route exact path="/" render={() => loggedIn ? <Redirect to='/stories' /> : <LoginPage />} />
                    <Route exact path="/dashboard" render={() => loggedIn ? <DashboardPage /> : <Redirect to='/login' />}  />
                    <Route exact path="/login" render={() => loggedIn ? <Redirect to='/stories' /> : <LoginPage /> } />
                    <Route exact path="/oauthlogin" render={() => <OauthLoginPage /> } />
                    <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                    <Route exact path="/register" render={() => loggedIn ? <Redirect to='/stories' /> : <RegisterPage />} />
        
                    <Route exact path="/browse" component={BrowsePage} />
                    <Route exact path="/stories" component={BrowsePage} />
                    <Route exact path="/conversations" component={BrowsePage} />
                    <Route exact path="/freestories" component={BrowsePage} />  
                    <Route exact path="/favorites" component={BrowsePage} />  
                    <Route exact path="/history" component={BrowsePage} />  
                    
                    <Route exact path="/courses" render={(p) => loggedIn ? <CoursesPage {...p} /> : <Redirect to='/login' />} />  
                    <Route exact path="/courses/:id" render={(p) => loggedIn ? <ViewCoursePage {...p} /> : <Redirect to='/login' />} />  
                    <Route exact path="/courses/:id/lessons" render={(p) => loggedIn ? <CourseLessonsPage {...p} /> : <Redirect to='/login' />} />  
        
                    <Route exact path="/email/verify" component={VerifyEmailPage} />
                    <Route exact path="/read-story/:id" render={(p) => <ReadStoryPage router={p} /> } />
                    <Route exact path="/embed-story/:id" render={(p) => <EmbedStoryPage router={p} /> } />
                    <Route exact path="/signup" render={(p) => <SignupPage /> } />
        
                    {/* ADMIN */}
                    <Route exact path="/manage-articles" render={p => worker ? <ManageArticlesPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/sitebanner" render={p => admin || moderator ? <AdminEditBannerPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-courses" render={p => admin || moderator ? <AdminManageCoursesPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-languages" render={p => admin || moderator ? <AdminLanguagesPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-courses/edit/:id" render={p => admin || moderator ? <AdminEditCoursePage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-courses/new" render={p => admin || moderator ? <AdminEditCoursePage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-lesson-decks" render={p => admin || moderator ? <ManageLessonDecksPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-lesson-decks/deck/:id" render={p => admin || moderator ? <EditLessonDeckPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-lesson-decks/new" render={p => worker ? <EditLessonDeckPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/manage-podcasts" render={p => worker ? <ManageArticlesPage router={p} pageType={'podcasts'}/> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/podcasts" render={p => worker ? <ManageArticlesPage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/edit-article/:id" render={(p) => worker ? <EditArticlePage router={p} /> : <Redirect to='/adminlogin' />} />
                    <Route exact path="/adminlogin" render={() => worker ? <Redirect to='/manage-articles' /> : <AdminLoginPage />} />
                    <Route exact path="/viewusers" render={() => admin ? <ViewUsersPage /> : <AdminLoginPage />} />
                    <Route exact path="/logs" render={() => admin ? <LogsPage /> : <Redirect to='/manage-articles' />} />
                    <Route exact path="/user/:id" render={(p) => admin ? <ViewUserPage router={p} /> : <AdminLoginPage />} />
        
                    {/* USER */}
                    <Route exact path="/settings" render={() => loggedIn ? <SettingsPage /> : <Redirect to='/login' />} />
                    <Route exact path="/words" render={(p) => loggedIn ? <WordsPage router={p}/> : <Redirect to='/login' />} />   
        
                    {/* IF NO ROUTE        */}
                    <Route component={NoRoute} />
                                
        
                </Switch>
            </BrowserRouter>
            )
        }

    }
};

export default TheRoutes;
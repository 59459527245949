import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPodcastStatus } from '../redux/actions';

class PodcastUrlListener extends Component {

  componentDidMount(){
    this.checkAndSetPodcastStatus()
  }

  checkAndSetPodcastStatus(){
    let isPodcast = window.location.host.includes('podcast.');

    // if(window.location.host.includes("localhost")) isPodcast = true; // UNCOMMENT ME TO SIMULATE PODCAST IN DEV ENV

    this.props.setPodcastStatus(isPodcast);
  }

  render() {
    return null;
  }
}


const mapStateToProps = state => ({
  podcastMeta: state.podcastMeta
});
const mapActionsToProps = {
  setPodcastStatus
};
export default connect(mapStateToProps, mapActionsToProps)(PodcastUrlListener);
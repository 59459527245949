import * as types from '../types';
import { getGeneralDeckId } from '../../helpers/decks';

const initialState = {
  decks: {},
  selectedDeckId: '',
  allSavedWords: {},
  allSavedWordsDeckNames: {}
}

export function decksReducer(state = initialState, action) {
  switch (action.type) {
    case types.MODIFY_DECK:
      return({
        ...state, 
        decks: {...state.decks, [action.payload.deck.id] : action.payload.deck}
      });

    case types.SELECT_DECK_ID:
      return({...state, selectedDeckId: action.payload})
      
    case types.HYDRATE_DECKS: 
      let decksIndexed = {};
      let allSavedWords = {};
      let allSavedWordsDeckNames = {};
      action.payload.forEach(d => {
        decksIndexed[d.id] = d;
        d.list.forEach(wordItem => {
          allSavedWords[wordItem.word] = true;
          allSavedWordsDeckNames[wordItem.word] = d.title;
        })
      })
      return({
        ...state,
        decks: decksIndexed,
        selectedDeckId: state.selectedDeckId ? state.selectedDeckId : getGeneralDeckId(decksIndexed),
        allSavedWords: allSavedWords,
        allSavedWordsDeckNames: allSavedWordsDeckNames
      })

    default: return state;
  }
}
import React, { Component } from "react";

class CustomCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submitCard() {
    this.props.submitCard({ word: this.state.word, translation: this.state.translation });
    this.setState({ word: "", translation: "" });
  }

  handleWordChange(x) {
    this.setState({ word: x.target.value });
    x.stopPropagation();
  }

  handleTranslationChange(x) {
    this.setState({ translation: x.target.value });
    x.stopPropagation();
  }

  // blur and focus make it so the spacebar event on wordspage does not trigger card flip, and it will allow spaces in the textboxes
  handleInputFocus(){
    this.props.toggleSpacebarActive(false);
  }

  handleInputBlur(){
    this.props.toggleSpacebarActive(true);
  }

  render() {
    return (
      <div>
        <div class="modal fade" id="CustomCardModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">New Custom Card</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <input
                  class="form-control col-12"
                  onFocus={this.handleInputFocus.bind(this)}
                  onBlur={this.handleInputBlur.bind(this)}
                  onvalue={this.state.word}
                  onChange={x => this.handleWordChange(x)}
                  placeholder="Word"
                  style={{ marginBottom: 5 }}
                />
                <input
                  class="form-control col-12"
                  onFocus={this.handleInputFocus.bind(this)}
                  onBlur={this.handleInputBlur.bind(this)}
                  value={this.state.translation}
                  onChange={x => this.handleTranslationChange(x)}
                  placeholder="Translation"
                />
                <br />
                <div class="btn btn-primary col-12" data-dismiss="modal" onClick={this.submitCard.bind(this)}>
                  Submit
                </div>
                {/* <Link to='/share'>
                                <a>Try the web version<a>
                                </Link> */}
              </div>
              {/* <div class="modal-footer">
                <a class="btn btn-secondary" href={'mailto:'+this.props.class.professorEmail+'?Subject=Question'} >Email Professor</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  profileText: {
    // textAlign: 'center'
  },
  icon: {
    marginRight: 10
  }
};

export default CustomCardModal;

import React, { Component } from "react";
import ProgressBar from "../../ProgressBar";
import { isMobile } from "react-device-detect";
import checkIcon from "../../../assets/img/check.png";
import LabelIcon from "../LabelIcon";
import { getLengthStr } from "../../../helpers/courses";

class ProgressSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { lesson, status, progress, smallComplete } = this.props;

    // LESSONS DO NOT HAVE PROGRESS, ONLY new, in_progress, complete

    return (
      <div style={styles.wrapper}>
        {status === "new" ? (lesson.length ? <LabelIcon iconName="clock" label={getLengthStr(lesson.length)} /> : <div style={styles.label}>-</div>) : null}

        {status === "in_progress" ? (
          <div style={styles.row}>
            <div style={styles.progressWrapper}>
              <ProgressBar progress={progress || 50} backgroundColor="#CDD1D7" width={100} />
            </div>
            <div style={{ width: 12 }} />
            <div style={styles.label}>In Progress</div>
          </div>
        ) : null}

        {status === "complete" ? (
          <React.Fragment>
            {smallComplete ? (
              <div style={styles.label}>Complete</div>
            ) : (
              <div style={styles.completeBadge}>
                <img src={checkIcon} style={styles.checkIcon} />
                <div style={styles.completeText}>Completed</div>
              </div>
            )}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: "relative",
  },
  label: {
    fontSize: 12,
    color: "#8a94a6",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    // width: isMobile ? '100%' : 200
  },
  progressWrapper: {
    position: "relative",
    flex: 1,
  },
  completeBadge: {
    backgroundColor: "#46B049",
    borderRadius: 20,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  completeText: {
    fontSize: 11,
    color: "#fff",
  },
  checkIcon: {
    filter: "brightness(0) invert(1)",
    height: 11,
    width: 11,
    marginRight: 3,
  },
};

export default ProgressSection;

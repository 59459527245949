import React, { Component } from 'react';

const gradients = {
  green: 'linear-gradient(to right, #316f32, #53cb56)',
  red: 'linear-gradient(to right, #b02d2d, #ff7000)'
}

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      progress,
      gradientName,
      backgroundColor,
      width
      // color1='#B32F2C',
      // color2='#FC6D02',
    } = this.props;

    if(progress === 100) gradientName = 'green'

    let styles = {
      wrapper: {
        width: width || '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 4,
        backgroundColor: backgroundColor || undefined
      },
      bar: {
        display: 'flex',
        flex: progress/100,
        height: 8,
        borderRadius: 4,
        // backgroundImage: `linear-gradient(to right, ${color1}, ${color2})`
        backgroundImage: gradients[gradientName || 'red']
      }
    };

    return(
      <div style={styles.wrapper}>
        <div style={styles.bar} />
      </div>
    );
  }
}



export default ProgressBar;
import React, { Component } from "react";
import SkillPill from "../../routes/BrowsePage/components/SkillPill";
import LabelIcon from "./LabelIcon";
import HeaderDetailsRow from "./HeaderDetailsRow";

class CourseDetailsHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { course } = this.props;
    return (
      <div style={styles.wrapper}>
        <div style={styles.pageTitle}>{course.title}</div>
        <HeaderDetailsRow course={course} />
      </div>
    );
  }
}

const styles = {
  wrapper: {},
  pageTitle: {
    fontWeight: 700,
    fontSize: 28,
  },

  modulesDescription: {},
};

export default CourseDetailsHeader;

import React, { Component } from "react";
import Cards from "react-credit-cards";
import { Input } from "antd";
import validator from 'validator';

class CardInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeCardName = e => {
    this.props.onChangeItem('newCardName', e.target.value)
  }

  onChangeCardNumber = e => {
    let str = e.target.value;
    if(
      (!validator.isNumeric(str) && str !== '') ||
      str.length>16
     ) return;
    this.props.onChangeItem('newCardNumber', str)
  }

  onChangeExpiry = e => {
    let str = e.target.value;
    // if(str.length===2) str = str.substring(0,1)

    // if(str.indexOf('/')>-1) str = e.target.value.replace('/', '')
    // console.log('newstr', str)
    // str = str.replace('/', '')
    if(
      (!validator.isNumeric(str) && str !== '') ||
      str.length>4
     ) return;

    this.props.onChangeItem('newExpiry', str)
    
  }

  onChangeCVC = e => {
    let str = e.target.value;
    let len = str.length;
    if(
      (!validator.isNumeric(str) && str !== '') ||
      str.length>3
     ) return;

    this.props.onChangeItem('newCVC', str)
    
  }

  getExpiryDisplay = () => {
    const str = this.props.newExpiry || '';
    let formatted = str;
    // console.log('str', str)
    // if(str.length===2){
    //   formatted = str+'/';
    // }
    // if(str.length===3){
    //   formatted = `${str.substring(0,2)}/${str.substring(2,3)}`;
    // }
    // if(str.length===4){
    //   formatted = `${str.substring(0,2)}/${str.substring(2,4)}`;
      
    // }
    return formatted
  }

  render() {
    const { newCVC='', newCardNumber='', newCardName='', newExpiry='' } = this.props;
    return (
      <div style={styles.wrapper}>
        <div style={styles.topCard}>
        <Cards
          cvc={newCVC}
          expiry={newExpiry}
          focused={this.state.focused}
          name={newCardName}
          number={newCardNumber}
        />
        </div>
        <div style={styles.bottomForm}>
          <Input onChange={this.onChangeCardNumber} value={newCardNumber} placeholder="Card Number..." onFocus={e => this.setState({focused: 'number'})} />
          <div style={{height: 8}} />
          <Input onChange={this.onChangeCardName} value={newCardName} placeholder="Name on card..." onFocus={e => this.setState({focused: 'name'})} />
          <div style={{height: 8}} />

          <div style={styles.row}>
            <div style={{ flex: 0.48 }}>
              <Input onChange={this.onChangeExpiry} value={this.getExpiryDisplay()} placeholder="Expires..." onFocus={e => this.setState({focused: 'expiry'})} />
            </div>
            <div style={{ flex: 0.48 }}>
              <Input onChange={this.onChangeCVC} value={newCVC} placeholder="CVC..." onFocus={e => this.setState({focused: 'cvc'})} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20
  },
  topCard: {
    marginBottom: 20
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
};

export default CardInput;

import React, { Component } from 'react';
import PaymentIcon from 'react-payment-icons';
import Colors from '../../../assets/Colors';

class ExistingMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { method={} } = this.props;
    return(
      <div style={styles.wrapper}>
        {/* <div style={styles.label}>Current: </div> */}
        <div style={styles.cardInfo}>
        <PaymentIcon
          id={(method.brand || '').toLowerCase()}
          style={{ width: 45, marginRight: 5 }}
          className="payment-icon"
        />
        <div style={styles.cardTextInfo}>
          <div style={styles.cardNumber}>************{method.last4}</div>
          <div style={styles.expire}>Expires {method.exp_month}/{method.exp_year}</div>
        </div>
        <div style={styles.changeLink} className='hoverLink' onClick={this.props.onClickChangeMethod}>Change</div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginRight: 12,
    fontWeight: 'bold',
    color: Colors.text
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardNumber: {
    color: Colors.lightText
  },
  changeLink: {
    marginLeft: 15,
    // fontSize: 12
    // color: Colors.blue
  },
  cardTextInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  expire: {
    color: Colors.lightText,
    fontSize: 12,
    marginTop: -6
  }
};

export default ExistingMethod;
import React, { Component } from 'react';
import AdminNavbar from '../../components/admin/AdminNavbar';
import { Link, Redirect } from 'react-router-dom';
import { updateLoggedInUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import Colors from '../../assets/Colors';
import LoadingOverlay from 'react-loading-overlay';


class ViewUsersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
          users: [],
          loading: true,
          accountType: 'all'
        }
    }



    //-----LIFECYCLE--------------------------------
    componentDidMount(){
        fetch('/user', {
          method: 'get',
          credentials: 'same-origin',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(result => result.json())
        .then(result => {
          this.setState({loading: false});
          if(result.status=='success'){
            this.setState({users: result.users});
          }else{
            alert('error getting users.')
          }
        })
    }

    //---------METHODS-----------------------------

    handleSearchChange(e){
      this.setState({search: e.target.value});
    }

    handleAccountTypeChange(e) {
      this.setState({ accountType: e.target.value });
    }

    render() {
      let shownUsers =  this.state.search ? this.state.users.filter(user => {
        return user.email.toLowerCase().includes(this.state.search.trim().toLowerCase())
      }) : this.state.users;

      if(this.state.accountType !== 'all') shownUsers = shownUsers.filter(x => x.accountType === this.state.accountType)

        return (
            <div style={{ height: '100%' }}>
                <AdminNavbar active='viewusers' />
                <div class='container fadeMeIn' style={{paddingTop: 80}}>
                    <h1>Users</h1>
                    <div style={styles.topRow}>
                      <span>Total: {shownUsers.length}</span>
                      
                      <div style={styles.accountTypeContainer}>
                        <div>
                          Account Type
                        </div>
                        <div >
                          <select class="form-control" onChange={e => this.handleAccountTypeChange(e)} value={this.state.accountType} style={{width: 180}}>
                            <option value={'all'} selected={this.state.accountType === 'all' ? true : false}>All</option>
                            <option value={'admin'} selected={this.state.accountType === 'admin' ? true : false}>Admin</option>
                            <option value={'moderator'} selected={this.state.accountType === 'moderator' ? true : false}>Moderator</option>
                            <option value={'editor'} selected={this.state.accountType === 'editor' ? true : false}>Editor</option>
                            <option value={'user'} selected={this.state.accountType === 'user' ? true : false}>User</option>
                          </select>
                        </div>
                      </div>

                      <input onChange={e => this.handleSearchChange(e)} value={this.state.search} type="text" class="form-control" style={styles.search} placeholder='Search...'/>
                    </div>
                    {this.state.loading ? <LoadingOverlay/> : null}
                    <br/>
                    <br/>

                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Email</th>
                            <th scope="col">Membership Active?</th>
                            <th scope="col">Account Type</th>
                            <th scope="col">Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shownUsers.map((item, index) => {
                            return(
                              <tr>
                                <td>{item.email}</td>
                                <td>{item.subscriptionActive ? <i class='fa fa-check-circle' style={{color: 'green'}} /> : <i class='fa fa-times-circle' style={{color: 'red'}} /> }</td>
                                <td>{item.accountType}</td>
                                <td>
                                  <Link to={'/user/'+item.id}>
                                    <i class='fa fa-edit'/>
                                  </Link>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                </div>
            </div>
        );
    }
}

const styles = {
  search: {
    float: 'right',
    maxWidth: 300
  },
  accountType: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(ViewUsersPage);

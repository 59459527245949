import React, { Component } from 'react';
import { Link, Redirect } from 'react-router';
import { updateLoggedInUser } from '../../redux/actions';
import { connect } from 'react-redux';
import ReactS3 from '../../libraries/react-s3';
import Constants from '../../assets/Constants';
import $ from 'jquery'
//Components
import { PulseLoader } from 'react-spinners';
import AdminNavbar from '../../components/admin/AdminNavbar';
import Paragraph from '../../components/admin/Paragraph';
import LoadingOverlay from '../../components/LoadingOverlay';
import Colors from '../../assets/Colors';


var shortid = require('shortid');


class EditArticlePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            language: '',
            newLanguageName: null,
            availableLanguages: [], //what the user can select from
            pages: [
                [
                    { startsAtMin: null, startsAtSec: null, text: '', translation: '' },
                ],
            ],
            currentPageIndex: 0,
            title: null,
            subtitle: null,
            ccAttribution: null,
            description: null,
            isFree: null,
            audio: null,
            artwork: null,
            category: null,
            isHidden: false,
            isEmbedded: false,
            audioUrl: '',
            artworkUrl: '',
            articleType: null,
            deleteArticleButtonClass: 'animateButtonText',

            //podcast
            isPodcast: false,
            blurHeight: 1,
            spotifyLink: '',
            itunesLink: '',
            pocketcastsLink: '',
            googleLink: '',
            stitcherLink: '',
            rssLink: '',
        }
    }

    //------LIFECYCLE-------------------------
    async componentDidMount() {
        //set current available languages
        var langRes = await
            fetch('/languages/get/all', {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(result => result.json());
        if(langRes.languages.length>0) this.setState({ availableLanguages: langRes.languages });

        //if its not a new article, get the article from the server
        if (this.props.router.match.params.id != 'new') {
            this.setState({ loading: true });
            var articleResult = await
                fetch('/article/get/' + this.props.router.match.params.id, {
                    method: 'get',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(result => result.json());
            if (articleResult.status == 'success') {
                let stateObject = {
                    loading: false,
                    title: articleResult.story.title,
                    subtitle: articleResult.story.subtitle,
                    ccAttribution: articleResult.story.ccAttribution,
                    description: articleResult.story.description,
                    isFree: articleResult.story.is_free ? 'free article' : 'subscribers only',
                    audioUrl: articleResult.story.audio ? articleResult.story.audio : '',
                    artworkUrl: articleResult.story.artwork ? articleResult.story.artwork : '',
                    articleType: articleResult.story.type,
                    language: articleResult.story.language,
                    difficulty: articleResult.story.difficulty,
                    category: articleResult.story.category,
                    isHidden: articleResult.story.isHidden,
                    isEmbedded: articleResult.story.isEmbedded,
                    pages: JSON.parse(articleResult.story.pages),
                };
                console.log('article res', articleResult)
                if(articleResult.story.isPodcast){
                    let { blurHeight, podcastIntroText, podcastLinks } = articleResult.story;
                    let { spotifyLink, googleLink, stitcherLink, pocketcastsLink, rssLink, itunesLink } = (podcastLinks || {});
                    stateObject.isPodcast = true;
                    stateObject.blurHeight = blurHeight;
                    stateObject.spotifyLink = spotifyLink;
                    stateObject.googleLink = googleLink;
                    stateObject.stitcherLink = stitcherLink;
                    stateObject.pocketcastsLink = pocketcastsLink;
                    stateObject.rssLink = rssLink;
                    stateObject.itunesLink = itunesLink;
                    stateObject.podcastIntroText = podcastIntroText;
                }
                this.setState(stateObject);
            } else {
                this.setState({ loading: false });
                alert('could not find article with id: ' + this.props.router.match.params.id);
                this.props.router.history.push('/manage-articles');
            }

            $(function () { $('[data-toggle="tooltip"]').tooltip()})
        }


    }

    //---------METHODS-------------

    handleArtworkChange(e) {
        this.setState({ artwork: e.target.files[0] });
    }
    handleAudioChange(e) {
        this.setState({ audio: e.target.files[0] });
    }

    handleDeleteCurrentPage() {
        if (this.state.pages.length > 1) {
            if (window.confirm('Click OK to delete page ' + (this.state.currentPageIndex + 1) + '')) {
                let newPages = this.state.pages;
                newPages.splice(this.state.currentPageIndex, 1);
                this.setState({ pages: newPages, currentPageIndex: 0 });
            }
        } else {
            alert('only 1 page exists...')
        }
    }

    handleDeleteArticle() {
        if (window.confirm('Click OK to delete this article')) {
            //delete article from server and nav back here
            this.setState({ loading: true })
            fetch('/article/delete/' + this.props.router.match.params.id, {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(result => result.json())
            .then(result => {
                this.setState({ loading: false });
                alert(result.message);
                this.props.router.history.push('/manage-articles');
            })
        }
    }

    handlePageClick(index) {
        this.setState({ currentPageIndex: index });
    }

    handleNewPage() {
        let newPages = this.state.pages;
        newPages.push([{ startsAtMin: null, startsAtSec: null, text: '', translation: '' }]);
        this.setState({ pages: newPages });
    }

    handleNewParagraph() {
        let newPages = this.state.pages;
        newPages[this.state.currentPageIndex].push({ startsAtMin: null, startsAtSec: null, text: '', translation: '' });
        this.setState({ pages: newPages, animate: true });
    }

    handleDeleteParagraph(i, e) {
        let newPages = this.state.pages;
        newPages[this.state.currentPageIndex].splice(i, 1);
        this.setState({ pages: newPages });
    }

    handleChange(i, e) { //when a textbox in a paragraph changes
        const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        let pagesCopy = JSON.parse(JSON.stringify(this.state.pages));
        // if(e.target.name=='startsAtSec'){
        //     if( numbers.indexOf(parseInt(e.target.value))>-1 && parseInt(e.target.value)<60)
        //         pagesCopy[this.state.currentPageIndex][i][e.target.name]=e.target.value;
        // }else if( e.target.name=='startsAtMin'){
        //     if(numbers.indexOf(parseInt(e.target.value))>-1)
        //         pagesCopy[this.state.currentPageIndex][i][e.target.name]=e.target.value;
        // }else{
        //     pagesCopy[this.state.currentPageIndex][i][e.target.name]=e.target.value;
        // }

        pagesCopy[this.state.currentPageIndex][i][e.target.name] = e.target.value;
        this.setState({ pages: pagesCopy });
    }

    handleTogglePlainText(index, val){
        let pagesCopy = [...this.state.pages];
        pagesCopy[this.state.currentPageIndex][index].isPlainText = val;
        this.setState({ pages: pagesCopy });
    }

    async handleSave() {
        this.setState({ saving: true, loading: true });
        let creds = await fetch('/aws', {
          method: 'GET',
          credentials: 'same-origin',
          headers: {Accept: 'application/json','Content-Type': 'application/json'},
        }).then(result => result.json())

        //validate here
        if (1 == 1) {
            //calcuate and set starts at times based on min/sec
            let pages = JSON.parse(JSON.stringify(this.state.pages));
            pages.forEach(page => {
                page.forEach((paragraph, index) => {
                    paragraph.startsAt = paragraph.startsAtSec * 1000 + paragraph.startsAtMin * 1000 * 60;
                })
            })
            let { isPodcast, blurHeight, spotifyLink, googleLink, stitcherLink, pocketcastsLink, rssLink, itunesLink, podcastIntroText } = this.state;
            let requestBody = {
                title: this.state.title,
                subtitle: this.state.subtitle,
                ccAttribution: this.state.ccAttribution,
                description: this.state.description,
                articleType: this.state.articleType,
                language: this.state.language == 'new' ? this.state.newLanguageName : this.state.language,
                difficulty: this.state.difficulty,
                isFree: this.state.isFree == 'subscribers only' ? false : true,
                category: this.state.category,
                pages: pages,
                isHidden: this.state.isHidden,
                isEmbedded: this.state.isEmbedded,
                podcastLinks: {}
            }
            if(isPodcast){
                requestBody.isPodcast = true;
                requestBody.blurHeight = blurHeight;
                requestBody.podcastLinks.spotifyLink = spotifyLink;
                requestBody.podcastLinks.googleLink = googleLink;
                requestBody.podcastLinks.stitcherLink = stitcherLink;
                requestBody.podcastLinks.pocketcastsLink = pocketcastsLink;
                requestBody.podcastLinks.rssLink = rssLink;
                requestBody.podcastLinks.itunesLink = itunesLink;
                requestBody.podcastIntroText = podcastIntroText;
            }

            if (this.state.audio) {
                const audioConfig = {
                    bucketName: Constants.bucket,
                    albumName: 'audio',
                    fileName: 
                        // this.state.audioUrl.length > 0 ? this.state.audioUrl.split('/').pop().split('.')[0] + '.' + this.state.audio.name.split('.')[1] :  //use same name as before but with the proper extension
                        shortid.generate() + '.' + this.state.audio.name.split('.')[1], //new name with file type extension
                    region: Constants.region,
                    accessKeyId: creds.accessKey,
                    secretAccessKey: creds.secretKey,
                }
                let uploadAudioData = await ReactS3.upload(this.state.audio, audioConfig);
                requestBody.audio = uploadAudioData.location; //the url to aws image
            }

            if (this.state.artwork) {
                const artworkConfig = {
                    bucketName: Constants.bucket,
                    albumName: 'artwork',
                    fileName:
                        // this.state.artworkUrl.length > 0 ? this.state.artworkUrl.split('/').pop().split('.')[0] + '.' + this.state.artwork.name.split('.').pop() : //use same name as before but with the proper extension
                            shortid.generate() + '.' + this.state.artwork.name.split('.')[1], //new name with file type extension
                    region: Constants.region,
                    accessKeyId: creds.accessKey,
                    secretAccessKey: creds.secretKey,
                }
                let uploadArtworkData = await ReactS3.upload(this.state.artwork, artworkConfig);
                requestBody.artwork = uploadArtworkData.location; //the url to aws image
            }

            let param = this.props.router.match.params.id;
            let route = param == 'new' ? '/article/new' : '/article/edit/' + param;
            fetch(route, {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            })
                .then(result => result.json())
                .then(result => {
                    if (result.status == 'success') {
                        this.setState({ saving: false, loading: false });
                        alert('article saved')
                        this.props.router.history.push(this.state.isPodcast ? '/manage-podcasts' : '/manage-articles');
                    } else {
                        this.setState({ saving: false, loading: false });
                        alert('problem saving article')
                    }
                })

        } else {
            this.setState({ loading: false, saving: false });
            alert('Please fill out all the necessary feilds');
        }
    }

    handleMarkdown(markdownType, textType, paragraphIndex, startIndex, endIndex){
        let MDtext='';
        if(markdownType=='bold') MDtext='**';
        if(markdownType=='italic') MDtext='*';
        let pagesCopy = this.state.pages
        let textCopy = this.state.pages[this.state.currentPageIndex][paragraphIndex][textType];
        textCopy =
            textCopy.slice(0, startIndex)
            + MDtext
            + textCopy.slice(startIndex, endIndex)
            + MDtext+textCopy.slice(endIndex, textCopy.length);
        pagesCopy[this.state.currentPageIndex][paragraphIndex][textType] = textCopy;
        this.setState({ pages: pagesCopy });
    }

    render() {
        return (
            <div style={{ height: '100%', backgroundColor: Colors.blueBG }}>
                <AdminNavbar />
                {this.state.loading ? <LoadingOverlay /> : null}
                <div class='container fadeMeIn' style={{ paddingTop: 75, paddingBottom: 100 }}>
                    <h2 >Article Info</h2>

                    <div class="form-group row">
                        <label for="title" class="col-sm-2 col-form-label">Title</label>
                        <div class="col-sm-10">
                            <input maxLength='253' type="text" class="form-control" name='title' placeholder="title" onChange={x => this.setState({ title: x.target.value })} value={this.state.title} />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="title" class="col-sm-2 col-form-label">Subtitle</label>
                        <div class="col-sm-10">
                            <input maxLength='253' type="text" class="form-control" name='title' placeholder="subtitle" onChange={x => this.setState({ subtitle: x.target.value })} value={this.state.subtitle} />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="title" class="col-sm-2 col-form-label">
                            CC Attribution
                            <br/>
                            <span style={styles.viewTemplate} onClick={() => this.setState({showCCTemplate: true})}>view template</span>
                        </label>
                        <div class="col-sm-10">
                            <textarea type="text" class="form-control" name='title' onChange={x => this.setState({ ccAttribution: x.target.value })} value={this.state.ccAttribution} />
                            {this.state.ccAttribution ? <p><span style={{fontWeight: 'bold'}}>preview: </span><span dangerouslySetInnerHTML={{__html: (this.state.ccAttribution || '').toString()}}/></p> : null}
                            {this.state.showCCTemplate ? <p style={styles.templateText}>
                            <strong>CC Attribution Template</strong> <br/>
                            {"This <a href='https://www.articlewebsite.com' target='_blank'>article</a> first appeared on <a href='https://www.website.com' target='_blank'>Sourcehere</a> and is republished here under a Creative Commons license."}
                            </p> : null}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="description" class="col-sm-2 col-form-label">Description</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" maxLength='253' name='description' placeholder="description" onChange={x => this.setState({ description: x.target.value })} value={this.state.description} />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="description" class="col-sm-2 col-form-label">Free?</label>
                        <div class="col-sm-5">
                            <div class="form-group">
                                <select class="form-control" onChange={x => this.setState({ isFree: x.target.value })} value={this.state.isFree}>
                                    <option value={null}></option>
                                    <option >subscribers only</option>
                                    <option >free article</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-2">Hidden?</div>
                        <div class="col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked={this.state.isHidden} onChange={e => this.setState({isHidden: e.target.checked})} />
                                <i class='fas fa-info-circle' style={{marginLeft: 10, opacity: .5}} data-toggle="tooltip" data-placement="top" title='Only admins and moderators can see hidden articles. Check this box to hide this article.' />
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-2">Is Embedded?</div>
                        <div class="col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked={this.state.isEmbedded} onChange={e => this.setState({isEmbedded: e.target.checked})} />
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="description" class="col-sm-2 col-form-label">Language</label>
                        <div class="col-sm-5">
                            <div class="form-group">
                                <select class="form-control" onChange={x => this.setState({ language: x.target.value })} value={this.state.language}>
                                    <option value={null}></option>
                                    {this.state.availableLanguages.map((item, index) => {
                                        return <option value={item.title}>{item.title}</option>
                                    })}
                                    <option value='new'>new language (enter below)</option>
                                </select>
                            </div>
                            {this.state.language == 'new' ?
                                <div class="col-10 animateHeight">
                                    <input type="text" class="form-control" placeholder='enter language...' name='newLanguageName' value={this.state.newLanguageName} onChange={x => this.setState({ newLanguageName: x.target.value })} />
                                </div>
                                : null}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="description" class="col-sm-2 col-form-label">Article Type</label>
                        <div class="col-sm-5">
                            <div class="form-group">
                                <select class="form-control" onChange={x => this.setState({ articleType: x.target.value })} value={this.state.articleType}>
                                    <option value={null}></option>
                                    <option>story</option>
                                    <option>book</option>
                                    <option>conversation</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="description" class="col-sm-2 col-form-label">Difficulty</label>
                        <div class="col-sm-5">
                            <div class="form-group">
                                <select class="form-control" onChange={x => this.setState({ difficulty: x.target.value })} value={this.state.difficulty}>
                                    <option value={null}></option>
                                    <option>newbie</option>
                                    <option>beginner</option>
                                    <option>intermediate</option>
                                    <option>advanced</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="category" class="col-sm-2 col-form-label">Category</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" name='category' placeholder="category" onChange={x => this.setState({ category: x.target.value })} value={this.state.category} />
                        </div>
                    </div>



                    <h2 >Files</h2>

                    <div class="form-group row">
                        <label for="description" class="col-sm-2 col-form-label">Upload MP3</label>
                        <div class="col">
                            <div class="form-group">
                                <div class="custom-file">
                                    {this.state.audioUrl.length > 0 ? <div >Current: <a href={this.state.audioUrl}>{this.state.audioUrl}</a></div> : null}
                                    <input type="file" accept='audio/mpeg' onChange={e => this.handleAudioChange(e)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="description" class="col-sm-2 col-form-label">Upload Artwork</label>
                        <div class="col">
                            <div class="form-group">
                                <div class="custom-file">
                                    {this.state.artworkUrl.length > 0 ? <div >Current: <a href={this.state.artworkUrl}>{this.state.artworkUrl}</a></div> : null}
                                    <input type="file" accept='image/*' onChange={e => this.handleArtworkChange(e)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2>Podcast Info</h2>

                    <div class="form-group row">
                        <div class="col-sm-2">Is Podcast?</div>
                        <div class="col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked={this.state.isPodcast} onChange={e => this.setState({isPodcast: e.target.checked})} />
                            </div>
                        </div>
                    </div>
                    {this.state.isPodcast ? 
                        <React.Fragment>
                            <div class="form-group row">
                                <label for="title" class="col-sm-2 col-form-label">Start blur after paragraph #</label>
                                <div class="col-sm-10">
                                    <input maxLength='253' type="number" class="form-control" placeholder="blur height" onChange={x => this.setState({ blurHeight: x.target.value })} value={this.state.blurHeight} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="title" class="col-sm-2 col-form-label">Spotify Link</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control"  onChange={x => this.setState({ spotifyLink: x.target.value })} value={this.state.spotifyLink} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="title" class="col-sm-2 col-form-label">iTunes Link</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control"  onChange={x => this.setState({ itunesLink: x.target.value })} value={this.state.itunesLink} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="title" class="col-sm-2 col-form-label">Google Podcasts Link</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control"  onChange={x => this.setState({ googleLink: x.target.value })} value={this.state.googleLink} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="title" class="col-sm-2 col-form-label">Stitcher Link</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control"  onChange={x => this.setState({ stitcherLink: x.target.value })} value={this.state.stitcherLink} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="title" class="col-sm-2 col-form-label">PocketCasts Link</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control"  onChange={x => this.setState({ pocketcastsLink: x.target.value })} value={this.state.pocketcastsLink} />
                                </div>
                            </div>
                            
                            <div class="form-group row">
                                <label for="title" class="col-sm-2 col-form-label">RSS Link</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control"  onChange={x => this.setState({ rssLink: x.target.value })} value={this.state.rssLink} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="description" class="col-sm-2 col-form-label">Intro Text</label>
                                <div class="col-sm-10">
                                    <textarea class="form-control" maxLength='253' onChange={x => this.setState({ podcastIntroText: x.target.value })} value={this.state.podcastIntroText} />
                                </div>
                            </div>
                        </React.Fragment>
                    :null}

                    <h2>Content</h2>
                    <div >
                        <span style={{display: 'inline-block'}}>
                            <ul class="pagination">
                                {this.state.pages.map((item, index) => {
                                    return (
                                        <li class="page-item" onClick={() => this.handlePageClick(index)}>
                                        <span>
                                            <a style={this.state.currentPageIndex == index ? { backgroundColor: '#ededed', fontWeight: 'bold' } : null} className='page-link'>{index + 1}</a>
                                            </span>
                                        </li>
                                    );
                                })}
                                <li class="page-item" onClick={this.handleNewPage.bind(this)}><a class="page-link"><i class='fa fa-plus text-primary'></i></a></li>
                            </ul>
                        </span>
                        <span>
                            <div class='btn btn-outline-danger' onClick={this.handleDeleteCurrentPage.bind(this)} style={{ marginLeft: 20 }}>Delete current page</div>
                        </span>
                    </div>
                    {this.state.pages[this.state.currentPageIndex].map((item, index) => {

                        return (
                            <Paragraph
                                data={item}
                                handleChange={(i, e) => this.handleChange(i, e)}
                                index={index}
                                delete={(i, e) => this.handleDeleteParagraph(i, e)}
                                markdownText={this.handleMarkdown.bind(this)}
                                togglePlainText={val => this.handleTogglePlainText(index, val)}
                            />
                        )
                    })}

                    <br />
                    <div class='col-12 text-center'>
                        <div class='btn btn-secondary' onClick={this.handleNewParagraph.bind(this)}><i class='fa fa-plus' style={{ marginRight: 15 }}></i>New Paragraph</div>
                    </div>

                    <hr />
                    <div class='btn btn-outline-danger' onClick={this.handleDeleteArticle.bind(this)} onMouseEnter={() => this.setState({deleteArticleButtonClass: 'animateButtonText show'})} onMouseLeave={() => this.setState({deleteArticleButtonClass: 'animateButtonText'})}>
                        <i class='fa fa-trash'></i>{'\u00A0'}{'\u00A0'}Delete Article
                    </div>
                    <div class='btn btn-primary' onClick={this.handleSave.bind(this)} style={{ float: 'right', minWidth: 200, fontWeight: 'bold' }}>{this.state.saving ? <PulseLoader color='white' /> : 'Save'}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser,
    availableLanguages: state.availableLanguages
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(EditArticlePage);

const styles = {
    viewTemplate: {
        cursor: 'pointer',
        color: 'blue',
        opacity: .5
    },
    templateText: {
        backgroundColor: '#dddddd',
        borderRadius: 3,
        padding: 10
    }
}
import * as types from '../types';
export * from './decks';

export function updateLoggedInUser(newUser){
  return {
    type: types.UPDATE_LOGGED_IN_USER,
    payload: newUser
  }
}

export function updateWordList(wordList) {
  return {
    type: types.UPDATE_WORD_LIST,
    payload: wordList
  }
}

export function toggleNav(navState){
  return {
    type: types.TOGGLE_NAV,
    payload: navState
  }
}

export function updateArticles(articlesArray, categories){
  return {
    type: types.UPDATE_ARTICLES,
    payload: {articlesArray, categories}
  }
}

export function articlesNeedReload(x){
  return {
    type: types.ARTICLES_NEED_RELOAD,
    payload: x
  }
}

export function selectLanguage(language){
  return {
    type: types.SELECT_LANGUAGE,
    payload: language
  }
}

export function setLastClickedArticleId(articleId){
  return {
    type: types.LAST_CLICKED_ARTICLE,
    payload: articleId
  }
}

export function setAvailableLanguages(languages){
  return {
    type: types.SET_LANGUAGES,
    payload: languages
  }
}

export function updateSearchText(x){
  return {
    type: types.UPDATE_SEARCH_TEXT,
    payload: x
  }
}

export function setPodcastStatus(x){
  return {
    type: types.SET_PODCAST_STATUS,
    payload: x
  }
}

export function fetchWordListThunk(selectedLanguage){
  return function(dispatch, getState){
    fetch("/words/getlist", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
    .then(result => result.json())
    .then(result => {
      if (result.wordList.list) {
        if (result.wordList.list[selectedLanguage]) {
          dispatch(updateWordList(result.wordList.list[selectedLanguage]))
        }else{
          dispatch(updateWordList([]))
        }
      }
    });
  }
}
import store from "../redux/store";
import * as types from "../redux/types";

export function fetchLessonDecks() {
  return new Promise(resolve => {
    let selectedLanguage = store.getState().selectedLanguage;
    // alert('asd')
  
    fetch("/lessondecks/getall", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((result) => {
        console.log("ressss", result);
        if (result.decks) {
          console.log("selectedlang", selectedLanguage);
          result.decks = result.decks.map(d => {
            return({
              ...d,
              isLessonDeck: true
            })
          })
          // let currentDecks = result.decks.filter((x) => (x.language === selectedLanguage ? true : false));
          store.dispatch({
            type: types.HYDRATE_LESSON_DECKS,
            payload: result.decks,
          });
          resolve()
        }
      });
  })
}

export function selectLessonDeck(deck) {
  store.dispatch({
    type: types.SELECT_LESSON_DECK,
    payload: deck,
  });
}


import React, { Component } from "react";
import { toggleNav, updateLoggedInUser, selectLanguage, updateSearchText, articlesNeedReload, updateArticles, setLastClickedArticleId } from "../../redux/actions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import Colors from "../../assets/Colors";
import { changeFilter } from '../../helpers/filters';

//Components
import LoadingOverlay from "../../components/LoadingOverlay";
import Dropdowns from "./components/Dropdowns";
import StoryCard from "./components/StoryCard";
import PageTemplate from "../PageTemplate";
import LoggedOutBanner from "./components/LoggedOutBanner";
import Constants from "../../assets/Constants";
const queryString = require("querystring");

class BrowsePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      categories: [],
      shownArticles: [],
      // filterLanguage: true,
      // filterDifficulty: "any",
      // filterHidden: 'any', //admin only
      // filterRead: "any",
      // filterType: "any",
      // filterCategory: "any",
      // showArticles: null,
      // filterShowFree: false,
      // sortBy: "newest",
      alreadyGotArticles: 0,
      loading: false,
    };
  }

  componentDidMount() {
    // this.setState({ loading: true });
    this.props.updateSearchText('');
    if(this.props.lastClickedArticleId){
      setTimeout(() => {
        let card = document.getElementById('storycard'+this.props.lastClickedArticleId);
        if(card){
          card.scrollIntoView();
          window.scrollBy(0, -70);
          this.props.setLastClickedArticleId('');
        }
      }, 100);
    }
    let hasLoggedInBefore = localStorage.getItem('hasLoggedInBefore');
    this.setState({hasLoggedInBefore})
    //hacky thing im doing because of the progressive web app, this is the only way i could get it to work.
    // if (isMobile) {
    //   // setTimeout(() => {
    //   //   if (this.state.articles.length < 1) this.getArticles();
    //   // }, 100);
    //   // setTimeout(() => {
    //   //   if (this.state.articles.length < 1) this.getArticles();
    //   // }, 500);
    //   // setTimeout(() => {
    //   //   if (this.state.articles.length < 1) this.getArticles();
    //   // }, 1000);
    //   // setTimeout(() => {
    //   //   if (this.state.articles.length < 1) this.getArticles();
    //   // }, 1500);
    //   // setTimeout(() => {
    //   //   if (this.state.articles.length < 1) this.getArticles();
    //   // }, 2000);
    //   // setTimeout(() => {
    //   //   if (this.state.articles.length < 1) this.getArticles();
    //   // }, 3000);
    //   // setTimeout(() => {
    //   //   if (this.state.articles.length < 1) alert("could not get articles. check your internet connection");
    //   // }, 6000);
    // } else {
    // }
    if(this.props.articleInfo.articlesNeedReload || this.props.articleInfo.articles.length<1){
      this.getArticles();
    }

    switch (this.props.match.path) {
      case "/stories":
        changeFilter(this.props.match.path, { filterType: "story" });
        break;
      case "/conversations":
        changeFilter(this.props.match.path, { filterType: "conversation" });
        break;
      case "/history":
        changeFilter(this.props.match.path, { showArticles: "history" });
        break;
      case "/favorites":
        changeFilter(this.props.match.path, { showArticles: "favorites" });
        break;
    }
  }

  getArticles() {
    let route = this.props.loggedInUser ? "/article/getall" : "/article/getallfree";
    this.setState({ loading: true });
    fetch(route, {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "cache-control": "no-cache"
      }
    })
      .then(result => result.json())
      .then(result => {
        if (result.status == "success") {
          this.props.updateArticles(result.articles, result.categories);
          this.props.articlesNeedReload(false);
          this.setState({
            loading: false,
            // articles: result.articles,
            // categories: result.categories
          });
        } else {
          this.setState({ loading: false });
          if (!isMobile) alert("could not get articles. check your internet connection");
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterType, filterCategory, filterDifficulty, showArticles, filterRead, search } = this.props.filters[this.props.match.path];
    let prevFilters =  prevProps.filters[this.props.match.path];
    if (
      // reset searchtext if any other filters change
      (search && prevFilters.filterType !== filterType) ||
      prevFilters.filterDifficulty !== filterDifficulty ||
      prevFilters.filterRead !== filterRead ||
      prevFilters.filterCategory !== filterCategory
    ) {
      this.props.updateSearchText('');
    }

    //set all the different filters based on the URL. filters happen in filter method of this component
    if (this.props.match.path === prevProps.match.path) return; // only continue if route has changed
    window.scrollTo(0,0)
    switch (this.props.match.path) {
      case "/stories":
        if (filterType != "story") {
          changeFilter("/stories", {
            filterType: "story",
            search: "",
            filterCategory: "any",
            showArticles: null
          });
          this.props.updateSearchText('');
        }
        break;

      case "/conversations":
        if (filterType != "conversation") {
          changeFilter("/conversations", {
            filterType: "conversation",
            search: "",
            filterCategory: "any",
            showArticles: null
          });
          this.props.updateSearchText('');
        }
        break;

      case "/history":
        if (showArticles != "history") {
          changeFilter("/history", {
            showArticles: "history",
            search: "",
            filterType: "any",
            filterCategory: "any",
            filterDifficulty: "any",
            filterRead: "any"
          });
          this.props.updateSearchText('');
        }
        break;

      case "/favorites":
        if (showArticles != "favorites") {
          changeFilter("/favorites", {
            showArticles: "favorites",
            search: "",
            filterType: "any",
            filterCategory: "any",
            filterDifficulty: "any",
            filterRead: "any"
          });
          this.props.updateSearchText('');
        }
        break;

      case "/browse":
        if (
          filterType != "any" ||
          filterCategory != "any" ||
          filterDifficulty != "any" ||
          showArticles != null
        ) {
          changeFilter("/browse", {
            filterType: "any",
            search: "",
            filterCategory: "any",
            filterDifficulty: "any",
            showArticles: null
          });
          this.props.updateSearchText('');
        }
        break;
      default:
        break;
    }
  }

  handleSortChange(item) {
    this.setState({ sortBy: item.target.value });
  }

  handleSelectLanguage(lang) {
    //used if theuser is on the wrong language and needs to switch back by clicking "change back to..."
    this.setState({ loading: true });
    fetch("/user/setlanguage/" + lang, {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(() => {
      this.setState({ loading: false });
      this.props.selectLanguage(this.props.loggedInUser.authorizedLanguages);
    });
  }

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
  }

  // accepts an array of articles, and returns the array filtered based on the current state
  filterArticles(articles){
    let { filterRead, showArticles, filterDifficulty, filterType, filterCategory, sortBy, filterHidden } = this.props.filters[this.props.match.path];

    if (!this.props.loggedInUser) {
      //if not logged in, filter by query string language in url
      let language = queryString.parse(this.props.location.search.replace("?", "")).language;
      if (language) articles = articles.filter(item => item.language == language);
    }
    if (filterDifficulty != "any") articles = articles.filter(item => item.difficulty == filterDifficulty);
    if (filterType != "any") articles = articles.filter(item => item.type == filterType);
    if (filterHidden != "any") articles = articles.filter(item => item.isHidden == JSON.parse(filterHidden));
    if (filterCategory != "any") articles = articles.filter(item => item.category == filterCategory);
    if (filterRead != "any") {
      articles = articles.filter(item => {
        let val = item.UserStories[0] ? (item.UserStories[0].isRead == true ? "read" : "unread") : "unread";
        return val == filterRead;
      });
    }
    if (articles == undefined) articles = [];
    if (this.props.searchText)
      articles = articles.filter(item => item.title.toLowerCase().includes(this.props.searchText.trim().toLowerCase()));
    if (showArticles == "favorites")
      articles = articles.filter(item => (item.UserStories[0] ? item.UserStories[0].isFavorite : false));
    if (showArticles == "history") {
      if (sortBy != null) changeFilter(this.props.match.path, { sortBy: null }); //dont filter again after the filter here.
      articles = articles.filter(item => (item.UserStories[0] ? item.UserStories[0].isRead : false));
      articles.sort((a, b) => {
        if (new Date(a.UserStories[0].lastRead) > new Date(b.UserStories[0].lastRead)) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    return articles;
  }

  // accepts an array of articles, and returns the array sorted based on the current state
  sortArticles(articles){
    let { filterRead, filterDifficulty, showArticles, filterType, filterCategory, sortBy } = this.props.filters[this.props.match.path];

    if (sortBy == "newest")
      articles.sort((a, b) => {
        let a_sort = a.publishedOn ? a.publishedOn : a.createdAt;
        let b_sort = b.publishedOn ? b.publishedOn : b.createdAt;
        if (a_sort < b_sort) return 1;
        if (b_sort < a_sort) return -1;
        return 0; //they must be the same
      });
    if (sortBy == "alphabetical")
      articles.sort((a, b) => {
        var titleA = a.title.toUpperCase();
        var titleB = b.title.toUpperCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0; // names must be equal
      });
    if (sortBy == "popular")
      articles.sort((a, b) => {
        if (a.favoritesCount > b.favoritesCount) return -1;
        if (a.favoritesCount < b.favoritesCount) return 1;
        return 0; // vals must be equal
      });
    if (sortBy == "hot")
      articles.sort((a, b) => {
        if (a.hotCount > b.hotCount) return -1;
        if (a.hotCount < b.hotCount) return 1;
        return 0; // vals must be equal
      });
    return articles;
  }

  // returns an array of categories if there is at least 1 article in that category currently
  getShownCategories(){
    const { filterType, filterCategory, filterDifficulty, showArticles, filterRead } = this.props.filters[this.props.match.path];

    let shown_categories = [{ title: "Any Category", value: "any" }];
    this.props.articleInfo.categories.forEach(item => {
      if (item) {
        let show_item = false;

        this.props.articleInfo.articles.forEach(a => {
          if (a.type === filterType || filterType === "any") {
            if (a.category === item) show_item = true;
          }
        });
        if (show_item === true) shown_categories.push({ title: item, value: item });
      }
    });
    return shown_categories;
  }

  setDropdownValue = obj => {
    changeFilter(this.props.match.path, obj)
  }

  render() {
    let { filterRead, showArticles, filterDifficulty, filterType, filterCategory, filterHidden, sortBy } = this.props.filters[this.props.match.path];
    let { loggedInUser } = this.props;

    // this displays a message on the screen letting user know "they arent authorized to view this language based on their payment plan"
    let show_purchase_language = false;
    if (loggedInUser) {
      if (
        loggedInUser.authorizedLanguages != "all" &&
        this.props.selectedLanguage != loggedInUser.authorizedLanguages
      )
        show_purchase_language = true;
    }

    // filter and sort articles
    let shownArticles = [...this.props.articleInfo.articles];
    shownArticles = this.filterArticles(shownArticles);
    shownArticles = this.sortArticles(shownArticles);

    let shown_categories = this.getShownCategories();

    let showAdminFilter = false;
    if(loggedInUser) if(loggedInUser.accountType==='admin' || loggedInUser.accountType==='moderator') showAdminFilter = true;


    return (
      <PageTemplate showSearch={true}>
        {!this.props.loggedInUser && this.state.hasLoggedInBefore ? <LoggedOutBanner /> : null}
        <LoadingOverlay hide={this.state.loading == false} />

        <div class="container" style={{ maxWidth: 1300 }}>
          <Dropdowns
            setValue={this.setDropdownValue}
            filterRead={filterRead}
            filterDifficulty={filterDifficulty}
            filterCategory={filterCategory}
            filterHidden={filterHidden}
            sortBy={sortBy}
            showAdminFilter={showAdminFilter}
            shown_categories={shown_categories}
            showArticles={showArticles}
            loggedInUser={loggedInUser}
            title={this.props.match.path.includes("conversation") ? "Conversations" : "Stories"}
          />
          <div class="row" style={{ justifyContent: "center" }}>
            {show_purchase_language ? (
              <h2 style={{ marginTop: 40, width: "100%", textAlign: "center" }}>
                Your {Constants.appName} plan does not include this language.
                <br />
                <div class="btn btn-primary" onClick={() => this.handleSelectLanguage(loggedInUser.authorizedLanguages)}>
                  Change back to {loggedInUser.authorizedLanguages}
                </div>
              </h2>
            ) : null}
            {shownArticles.map((item, index) => {
              let isRead = false;
              if (item.UserStories) isRead = item.UserStories[0] ? item.UserStories[0].isRead : false;
              return <StoryCard item={item} index={index} isRead={isRead} />;
            })}
          </div>
        </div>
        <br/>
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage,
  searchText: state.searchText,
  articleInfo: state.articleInfo,
  lastClickedArticleId: state.lastClickedArticleId,
  filters: state.filters
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
  selectLanguage,
  updateSearchText,
  articlesNeedReload,
  updateArticles,
  setLastClickedArticleId
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(BrowsePage);

const styles = {
  topContainer: {
    width: "100%",
    backgroundColor: "#ededed",
    // textAlign: 'center',
    // padding: 25,
    marginBottom: 20,
    padding: 30,
    paddingTop: 20,
    paddingBottom: 20
  },
  filterButton: {
    // marginLeft: 15,
    // marginRight: 15,
    marginBottom: 5,
    paddingLeft: 0,
    paddingRight: 0
  },
  dropdownSelection: {
    cursor: "default"
  },

  buttonLabel: {
    color: "#4c4c4c"
  },
  sidebarContainer: {
    padding: 17
  },
  typeTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#1e1e1e",
    marginBottom: 10
  },
  typeOption: {
    fontSize: "14px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginBottom: 8,
    cursor: "pointer"
  },

};

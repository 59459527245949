import React, { Component } from "react";
import { isMobile } from 'react-device-detect';
import Colors from "../../../assets/Colors";

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { defaultValue, selected, options, onChange, valName, admin } = this.props;
    if (options.length < 1) return null;
    // console.log(options);

    let fakeInputStyle = {
      padding: 1,
      backgroundColor: '#fff',
      border: '1px solid #e1e4e8',
      borderRadius: 50,
      // width: isMobile ? '100%' : '80%',
      position: 'relative',
      marginBottom: 7,
    };
    if(admin) fakeInputStyle.borderColor = Colors.main;

    return (
      <div style={styles.col}>
        <div style={fakeInputStyle}>
          <select
            class="form-control"
            value={selected}
            onChange={item => onChange({ [valName]: item.target.value })}
            defaultValue={defaultValue}
            style={styles.select}
          >
            {options.map(item => {
              return <option value={item.value}>{item.title}</option>;
            })}
          </select>
          <img src={require('../../../assets/img/down2.png')} style={styles.selectArrow} />
        </div>
      </div>
    );
  }
}

const styles = {
  col: {
    width: '17%',
    minWidth: 160,
    marginLeft: 9
  },
  select: {
    border: "none",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    // width: "84%",
    fontSize: 16,
    color: '#a6aebc',
    borderRadius: 50,
    paddingTop: 2,
    paddingBottom: 2
  },
  selectArrow: {
    maxHeight: 28,
    position: 'absolute',
    right: 12,
    top: 5,
    zIndex: 1,
    pointerEvents: 'none',
  }
};

export default CustomSelect;

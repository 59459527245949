import React, { Component } from 'react';
import { isSmartTV } from 'react-device-detect';
const gradients = {
  green: 'linear-gradient(315deg, #316f32, #53cb56)',
  purple: 'linear-gradient(312deg, rgba(129, 0, 255, 0.91), #007fff)',
  grey: 'linear-gradient(312deg, #B5BBC6, #B5BBC6)',
  blue: 'linear-gradient(312deg, #1584F0, #1584F0)',

};

class Square extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label='Lesson', number='', gradientName, isSmall, hover } = this.props;

    let size = isSmall ? 36 : 60;

    const styles = {
      wrapper: {
        borderRadius: 7,
        height: size,
        width: size,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: isSmall ? 'center' : 'flex-start',
        backgroundImage: hover ? gradients['blue'] : (gradientName ? gradients[gradientName] : gradients['grey']),
        // backgroundColor:  hover ? '#1284F3' : (gradientName ? undefined : '#B5BBC6'),
        transition: '.3s'
      },
      label: {
        fontSize: 12,
        color: '#fff',
        textAlign: 'center',
        marginTop: 4,
      },
      number: {
        fontSize: isSmall ? 14 : 24,
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'center',
        marginTop: -2
      }
    };

    return(
      <div style={styles.wrapper}>
        {isSmall ? null : <div style={styles.label}>{label}</div>}
        <div style={styles.number}>{number}</div>
      </div>
    );
  }
}



export default Square;
import React, { Component } from "react";
import Constants from "../assets/Constants";
import ReactS3 from "../libraries/react-s3";
import imageCompression from "browser-image-compression";
import shortid from "shortid";
import { Button } from "antd";
import { ClipLoader } from "react-spinners";

class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onAddImageClick = () => {
    this.fileInputRef.click();
  };

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  onFilesAdded = async (e) => {
    this.setState({ isUploading: true });
    let files = e.target.files;
    files = this.fileListToArray(files);
    files = files.map((f) => {
      f.filename = f.name;
      return f;
    });

    const options = {
      maxSizeMB: 0.3, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 900, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      onProgress: (data) => console.log("progress", data), // optional, a function takes one progress argument (percentage from 0 to 100)
    };

    imageCompression(files[0], options)
      .then(async (compressedImage) => {
        console.log("compressed", compressedImage);
        this.saveToAWS(compressedImage).then((url) => {
          this.props.onChange(url);
          this.setState({ isUploading: false });
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  saveToAWS = async (image) => {
    return new Promise(async (resolve) => {
      let creds = await global.apiRequest({ url: `/aws` });
      const artworkConfig = {
        bucketName: Constants.bucket,
        albumName: "artwork",
        fileName: shortid.generate() + "." + image.name.split(".")[1], //new name with file type extension
        region: Constants.region,
        accessKeyId: creds.accessKey,
        secretAccessKey: creds.secretKey,
      };
      let uploadArtworkData = await ReactS3.upload(image, artworkConfig);
      resolve(uploadArtworkData.location);
    });
  };

  render() {
    const { value } = this.props;
    const { isHoveringImage, isUploading } = this.state;
    return (
      <div style={styles.wrapper}>
        <input ref={(x) => (this.fileInputRef = x)} type="file" accept="image/*" onChange={this.onFilesAdded} style={{ display: "none" }} />

        {value ? (
          <div>
            <div
              style={styles.imageBox}
              className="shadow-sm"
              onMouseEnter={() => this.setState({ isHoveringImage: true })}
              onMouseLeave={() => this.setState({ isHoveringImage: false })}
            >
              {isHoveringImage ? (
                <div style={styles.hoverCover} onClick={this.onAddImageClick}>
                  Change
                </div>
              ) : null}
              {isUploading ? (
                <div style={styles.hoverCover}>
                  <ClipLoader color="#fff" />
                </div>
              ) : null}
              <img style={styles.img} src={value} />
            </div>
            <a href={value} style={{textAlign: 'center', width: 80}} target='_blank'>view</a>
          </div>
        ) : (
          <Button onClick={this.onAddImageClick} disabled={isUploading} loading={isUploading}>
            Add Image
          </Button>
        )}

        {/* <input type="file" accept="image/*" onChange={(e) => this.handleUploadCourseImage(e)} /> */}
      </div>
    );
  }
}

const image_size = 80;

const styles = {
  img: {
    objectFit: "cover",
    minWidth: image_size,
    minHeight: image_size,
    maxWidth: image_size,
    maxHeight: image_size,
  },
  hoverCover: {
    position: "absolute",
    zIndex: 2,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "rgba(0,0,0,.5)",
    color: "#fff",
    cursor: "pointer",
  },
  addBox: {},
  imageBox: {
    position: "relative",
    height: image_size,
    width: image_size,
    overflow: "hidden",
  },
};

export default ImageInput;

import React, { Component } from "react";
import AnimateHeight from "react-animate-height";
import { toggleNav, updateLoggedInUser } from "../../../redux/actions";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

//Components
import MyNavbar from "../../../components/MyNavbar";
import SideNav from "../../../components/SideNav";
import { PulseLoader } from "react-spinners";
import Footer from "../../../components/Footer";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Colors from "../../../assets/Colors";
import PageTemplate from "../../PageTemplate";
import CreditCardInput from 'react-credit-card-input';
import ExistingMethod from "./ExistingMethod";
import CustomModal from '../../../components/modals/CustomModal';
import CardInput from "./CardInput";
import validator from 'validator';
import Constants from "../../../assets/Constants";

class MyInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.loggedInUser.email,
      highlightSavedWords: this.props.loggedInUser.highlightSavedWords ? 'yes' : 'no',
      password1: "",
      password2: "",
      passwordOpen: false,
      message: "",
      contactButtonText: "Send",
      selectedTab: "settings",
      newCVC: '',
      newCardName: '',
      newCardNumber: '',
      newExpiry: ''
    };
  }

  componentDidMount(){
    this.loadExistingPaymentMethod();
  }

  changePassword() {
    if (this.state.password1 == this.state.password2 && this.state.password1.length > 5) {
      this.setState({ loading: true });
      fetch("/password/submitchange", {
        method: "post",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password1: this.state.password1,
          password2: this.state.password2,
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          this.setState({
            password1: "",
            password2: "",
            loading: false,
          });
          alert(result.message);
        });
    } else {
      alert("Please make sure passwords match, and are at least 6 charecters");
    }
  }

  submitInfoChange() {
    this.setState({ loading: true });
    console.log(this.state)
    fetch("/user/changeinfo", {
      method: "post",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
        fontSize: this.state.fontSize,
        highlightSavedWords: this.state.highlightSavedWords === 'yes' ? true : false
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({ loading: false });
          this.props.onUpdateLoggedInUser({
            ...this.props.loggedInUser,
            email: this.state.email,
            fontSize: this.state.fontSize,
            highlightSavedWords: this.state.highlightSavedWords == 'yes' ? true : false
          });
          alert("Info saved.");
        } else {
          alert(result.message);
        }
      });
  }

  onChangeHighlightWords = (e) => {
    let val = e.target.value;
    this.setState({highlightSavedWords: val})
  }

  submitContact() {
    if (!this.state.message) return;
    let email = this.props.loggedInUser.email;
    this.setState({ contactButtonText: "...", contactButtonLoading: true });
    fetch("/contact", {
      method: "POST",
      credentials: "same-origin",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        message: this.state.message,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({
            contactButtonText: "Sent!",
            contactButtonLoading: false,
            message: "",
            sentMessage: true,
          });
        } else {
          alert(result.message);
          this.setState({ contactButtonText: "Send", contactButtonLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ contactButtonText: "Send", contactButtonLoading: false });
        alert("Could not send message.");
      });
  }

  handleUnsubscribeClick() {
    let showThrivecartLink = new Date(this.props.loggedInUser.subscribedOn) >= new Date("January 24, 2021")
    if(showThrivecartLink){
      window.location = 'https://lingomastery.thrivecart.com/updateinfo/';
    }else{
        if (window.confirm("Are you sure? click OK to cancel your subscription.")) {
          this.setState({ loading: true });
          fetch("/user/cancelsubscription", {
            method: "post",
            credentials: "same-origin",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
        .then((result) => result.json())
        .then((result) => {
          this.setState({ loading: false });
          alert(result.message);
        });
      }
    }

  }

  loadExistingPaymentMethod = () => {
    global.apiRequest({
      url: `/billing/get-methods`,
      method: 'GET',
     }).then(res => {
      console.log('payment methopd res', res)
      this.setState({defaultCard: res.defaultCard})
     })
  }

  onClickChangeMethod = () => {
    this.setState({newCardModalVisible: true})
  }

  onSubmitPaymentMethod = () => {
    const { newCardNumber, newExpiry, newCVC, newCardName } = this.state;
    if(!validator.isNumeric(newCardNumber) || newCardNumber.length !== 16) return alert("Please enter a valid 16 digit card number")
    if(!validator.isNumeric(newExpiry) || newExpiry.length !== 4) return alert("Please enter a valid expiration date")
    if(!validator.isNumeric(newCVC) || newCVC.length !== 3) return alert("Please enter a valid 3 digit CVC code");
    this.setState({paymentMethodChangeLoading: true})
    global.apiRequest({
      url: `/billing/change-method`,
      method: 'POST',
      body: {
        cardNumber: newCardNumber,
        cvc: newCVC,
        nameOnCard: newCardName,
        expiry: newExpiry
      }
     }).then(res => {
       this.setState({paymentMethodChangeLoading: false})
       if(res.status==='failed'){
         return alert(res.message)
       }
      console.log('payment change res', res)
      this.setState({
        newCVC: '',
        newCardName: '',
        newCardNumber: '',
        newExpiry: '',
        newCardModalVisible: false,
        defaultCard: res.newCard
      });
      setTimeout(() => {
        alert("Card changed succesfully!")
      }, 200);
     }) 
  }

  render() {
    let subscriptionAlert = (
      <div style={styles.subscriptionAlertWrapper}>
        It looks like your subscription has expired. Please <a href="https://www.lingomastery.com/plussp">resubscribe </a>
        to continue using {Constants.appName}.
      </div>
    );
    let { subscriptionActive } = this.props.loggedInUser;
    let { selectedTab } = this.state;

    let cardStyles = { ...styles.card };
    if (!subscriptionActive) cardStyles.paddingTop = 70;



    const billing = this.props.loggedInUser.subscriptionActive ? (
      <div>
        <hr />
        <h4 style={styles.sectionTitle}>Subscription</h4>
        <a href="#" onClick={this.handleUnsubscribeClick.bind(this)} style={{ opacity: 0.9, color: "#82ca1c", fontWeight: "500" }}>
          Unsubscribe
        </a>
        <div style={{height: 8}}></div>
        <h4 style={styles.sectionTitle}>Payment Method</h4>
        <ExistingMethod method={this.state.defaultCard} onClickChangeMethod={this.onClickChangeMethod} />
        {/* <CreditCardInput
          cardNumberInputProps={{ value: this.state.newCardNumber, onChange: this.handleNewCardNumberChange }}
          cardExpiryInputProps={{ value: this.state.expiry, onChange: this.handleNewExpiryChange }}
          cardCVCInputProps={{ value: this.state.newCVC, onChange: this.handleNewCVCChange }}
          fieldClassName="input"
        /> */}
        
      </div>
    ) : null;

    const settings = (
      <React.Fragment>
        <div class="form-group" style={styles.formGroup}>
          <small style={styles.formLabel}>Email</small>
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={this.state.email}
            onChange={(x) => this.setState({ email: x.target.value })}
          />
        </div>
        <div class="form-group" style={styles.formGroup}>
          <small style={styles.formLabel}>Story Font Size</small>
          <select class="form-control" onChange={x => this.setState({fontSize: parseInt(x.target.value)})} value={this.state.fontSize || this.props.loggedInUser.fontSize}>
            <option value={16}>Normal</option>
            <option value={18} >Large</option>
            <option value={20}>Extra Large</option>
          </select>
        </div>
        <div class="form-group" style={styles.formGroup}>
          <small style={styles.formLabel}>Highlight words already saved as a flashcard?</small>
          <select class="form-control" onChange={this.onChangeHighlightWords} value={this.state.highlightSavedWords}>
            <option value={'yes'}>Yes</option>
            <option value={'no'}>No</option>
          </select>
        </div>
        <div class="btn btn-primary" style={styles.button} onClick={this.submitInfoChange.bind(this)}>
          Save
        </div>
        <hr />
        {/* <div class='btn btn-dark dropdown-toggle' onClick={() => this.setState({passwordOpen: !this.state.passwordOpen})}>Change Password</div> */}
        {/* {<AnimateHeight duration={300} height={this.state.passwordOpen ? 'auto' : 0}>
                                <div>

                                </div>
                            </AnimateHeight>} */}
        <div class="form-group" style={styles.formGroup}>
          <small style={styles.formLabel}>New Password</small>
          <input
            type="password"
            class="form-control"
            value={this.state.password1}
            onChange={(x) => this.setState({ password1: x.target.value })}
          />
        </div>
        <div class="form-group" style={styles.formGroup}>
          <small style={styles.formLabel}>Confirm New Password</small>
          <input
            type="password"
            class="form-control"
            id="password2"
            value={this.state.password2}
            onChange={(x) => this.setState({ password2: x.target.value })}
          />
        </div>

        <button class="btn btn-primary" style={styles.button} onClick={this.changePassword.bind(this)}>
          Save Password
        </button>

        <hr />
        <h4 style={styles.sectionTitle}>Contact Support</h4>
        <div class="form-group" style={styles.formGroup}>
          <textarea
            class="form-control"
            id="message"
            rows="4"
            placeholder="Your Message..."
            value={this.state.message}
            onChange={(x) => this.setState({ message: x.target.value })}
          />
        </div>
        <button
          class="btn btn-primary"
          style={styles.button}
          onClick={this.submitContact.bind(this)}
          disabled={this.state.contactButtonLoading}
        >
          {this.state.contactButtonText}
        </button>
        {this.state.sentMessage ? (
          <p style={{ marginTop: 15 }}>Thank you for contacting {Constants.appName}! We will get back to you as soon as possible.</p>
        ) : null}
      </React.Fragment>
    );

    return (
      <PageTemplate>
          <CustomModal
          visible={this.state.newCardModalVisible}
          onClose={() => this.setState({ newCardModalVisible: false })}
          submitButtonText={"Submit"}
          title="Change Payment Method"
          onSubmit={this.onSubmitPaymentMethod}
          marginBottom={isMobile ? 300 : 300}
          isSubmitting={this.state.paymentMethodChangeLoading}
        >
          <div style={styles.insideModal}>
            <CardInput
              onChangeItem={(key, val) => this.setState({[key]: val})}
              newCardNumber={this.state.newCardNumber}
              newExpiry={this.state.newExpiry}
              newCVC={this.state.newCVC}
              newCardName={this.state.newCardName}
            />
          </div>
        </CustomModal>
        <LoadingOverlay hide={!this.state.loading} />
        {subscriptionActive ? null : subscriptionAlert}
        <div class="col-12 col-lg-6 offset-lg-3">
          <div style={cardStyles}>
            <div style={styles.tabRow}>
              <div
                style={selectedTab === "settings" ? styles.selectedTab : styles.tab}
                onClick={() => this.setState({ selectedTab: "settings" })}
              >
                Settings
              </div>
              {this.props.loggedInUser.subscriptionActive ? (
                <div
                  style={selectedTab === "billing" ? styles.selectedTab : styles.tab}
                  onClick={() => this.setState({ selectedTab: "billing" })}
                >
                  Billing
                </div>
              ) : null}
            </div>

            {selectedTab === "settings" ? settings : null}
            {selectedTab === "billing" ? billing : null}
          </div>
        </div>
        <br />
        <br />
      </PageTemplate>
    );
  }
}

const tabStyle = {
  fontWeight: "1000",
  fontSize: 24,
  paddingBottom: 6,
  borderBottomWidth: 2,
  borderBottomColor: Colors.border,
  borderBottomStyle: "solid",
  color: Colors.border,
  cursor: "pointer",
};

const styles = {
  card: {
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    boxShadow: "0 5px 36px 0 rgba(0, 0, 0, 0.22)",
    padding: 26,
    marginTop: isMobile ? 20 : 50,
    marginBottom: 20,
    maxWidth: 600,
  },
  button: {
    minWidth: 130,
  },
  formLabel: {
    color: "#717171",
  },
  formGroup: {
    marginTop: 20,
  },
  sectionTitle: {
    marginBottom: 20,
    marginTop: 20,
  },
  subscriptionAlertWrapper: {
    backgroundColor: "rgba(255,59,48,1)",
    color: "white",
    width: "100%",
    // height: 70,
    textAlign: "center",
    borderRadius: 4,
    padding: 20,
    marginTop: 10,
  },
  tabRow: {
    justifyContent: "space-evenly",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },
  tab: {
    ...tabStyle,
  },
  selectedTab: {
    ...tabStyle,
    borderBottomColor: Colors.main,
    color: Colors.text,
  },
  insideModal: {
    height: 400,
    // minWidth: '75vw'
  }
};

const mapStateToProps = (state) => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
  toggleNav,
};

export default connect(mapStateToProps, mapActionsToProps)(MyInfoPage);

import React, { Component } from "react";
import { updateLoggedInUser } from "../redux/actions";
import { connect } from "react-redux";
import Colors from "../assets/Colors";
import { isMobile, isBrowser } from "react-device-detect";
import ReadFavoriteButtons from "./read-page/ReadFavoriteButtons";
import Constants from "../assets/Constants";
import packageJSON from '../../package.json'


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let now = new Date();
    now.setMonth(now.getMonth() + 1); //stay 1 month ahead
    let year = now.getFullYear();

    return (
      <div style={styles.container}>
        {isBrowser && this.props.buttonInfo ? (
          <div style={styles.desktopFavoriteWrapper}>
            {/* <ReadFavoriteButtons {...this.props.buttonInfo} /> */}
          </div>
        ) : null}
        {/* <hr /> */}
        <footer>
          <div class="row w-100" style={styles.row}>
            <div class="col-md-4">
              <div style={styles.insideCol}>
              <a href='https://lingomastery.com'>
                <img src={require("../assets/img/logo-header.png")} alt={this.props.isPodcast ? `${Constants.appName} Spanish Podcasts` : ''} style={styles.logo} height="50" />
              </a>
                
                  {this.props.isPodcast ? 
                  <p style={styles.textblock}>
                  Boost your language learning acquisition with the <a href='https://lingomastery.com/about/'>reading while listening method</a> used by {Constants.appName} Spanish Podcast.
                  </p>
                  :
                  <p style={styles.textblock}>Read engaging stories in the language you're learning while listening to the audio. Fun language immersion that works!</p>
                  }
            
              </div>
            </div>

            <div class="col-md-4">
              <div style={styles.insideCol}>
                <h6 style={styles.title}>Links</h6>
                <ul class="list-unstyled text-small">
                  <li style={styles.linkLI}>
                    <a style={styles.link} target="_blank" href="https://www.lingomastery.com/blog">
                      Blog
                    </a>
                  </li>
                  <li style={styles.linkLI}>
                    <a style={styles.link} target="_blank" href="https://www.lingomastery.com/contact">
                      Contact
                    </a>
                  </li>
                  <li style={styles.linkLI}>
                    <a style={styles.link} target="_blank" href="https://lingomastery.com/privacy-policy/">
                      Privacy Policy
                    </a>
                  </li>
                  {this.props.forvo ? (
                    <li style={styles.linkLI}>
                      <a style={styles.link} target="_blank" href="https://forvo.com/">
                        Pronounciations by Forvo
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div style={styles.insideCol}>
                <h6 style={styles.title}>Socials</h6>
                <a target="_blank" href="https://www.facebook.com/groups/storyling.spanish/">
                  <img src={require("../assets/img/facebook.png")} alt="" style={styles.facebookLogo} height="26" />
                </a>
                <ul class="list-unstyled text-small">
                  <li style={styles.linkLI}>
                    <a style={styles.link} target="_blank" href="https://www.facebook.com/groups/storyling.spanish/">
                      Join the Facebook community
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div style={styles.bottomText}>&copy; Copyright {year} {Constants.appName} - All rights reserved.</div>
          <div style={styles.version}>V {packageJSON.version}</div>
          <br />
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Footer)

const styles = {
  desktopFavoriteWrapper: {
    position: "absolute",
    right: 0,
    top: -300,
    paddingRight: 25
  },
  link: {
    color: "#8a94a6",
    fontSize: 14
  },
  linkLI: {
    marginBottom: 15
  },
  title: {
    marginBottom: 15
  },
  facebookLogo: {
    marginTop: 5,
    marginBottom: 15
  },
  insideCol: {
    maxWidth: isMobile ? "100%" : 290,
    paddingBottom: isMobile ? 50 : 0
  },
  container: {
    boxShadow: "0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08",
    backgroundColor: "white",
    position: "relative",
    paddingLeft: isMobile ? 20 : 0,
    paddingRight: isMobile ? 20 : 0,
    zIndex: 3
  },
  socialIcon: {
    fontSize: "1.6em",
    margin: "auto",
    textAlign: "center",
    width: "100%",

    textAlignVertical: "center"
    // color: '#82ca1c'
  },
  facebookLabel: {
    height: "100%",
    position: "relative",
    top: -3,
    textAlign: "center",
    textAlignVertical: "center",
    display: "inline",
    // height: 25,
    margin: "auto",
    fontSize: 16
  },
  row: {
    paddingLeft: isMobile ? 10 : 100,
    paddingRight: isMobile ? 10 : 100,
    paddingTop: isMobile ? 40 : 80,
    paddingBottom: isMobile ? 40 : 80
  },
  textblock: {
    color: "#8a94a6",
    fontSize: 14
  },
  logo: {
    marginBottom: 40,
    marginTop: -10,
    cursor: 'pointer'
  },
  bottomText: {
    color: "#8a94a6",
    fontSize: 14,
    width: "100%",
    textAlign: "center"
  },
  version: {
    color: "#8a94a6",
    opacity: .6,
    fontSize: 14,
    width: "100%",
    textAlign: "center"
  }
};

import React, { Component } from "react";
import Colors from "../../../assets/Colors";
import Square from "./Square";
import { isMobile } from "react-device-detect";
import ProgressSection from "./ProgressSection";

class LessonItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMouseEnter = () => {
    this.setState({hover: true})
  }

  onMouseLeave = () => {
    this.setState({hover: false})
  }

  render() {
    let { lesson, onClick, isSelected, status, isSmall, showLessonLabel } = this.props;
    const { hover } = this.state;
    if(isMobile) isSmall = true
    const styles = {
      wrapper: {
        paddingBottom: 14,
        width: "100%",
      },
      inside: {
        padding: 14,
        borderRadius: 7,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "#E8EDF4",
        position: 'relative',
        width: "100%",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: isSelected ? Colors.blue : "transparent",
      },
      title: {
        fontSize: isSmall ? 16 : 24,
        color: Colors.blue,
        fontWeight:500,
      },
      rightSide: {
        display: 'flex',
        flex: 1,
        marginLeft: 15,
        flexDirection: isSmall ? 'column' : 'row',
        alignItems: isSmall ? 'flex-start' : 'center',
        justifyContent: isSmall ? 'space-between' : 'space-between',
        height: isSmall && !showLessonLabel ? undefined : 60
      }
    };
    let gradientName = undefined;
    if(status==='in_progress') gradientName='purple'
    if(status==='complete') gradientName='green'

    let isSquareSmall = isSmall;
    if(showLessonLabel) isSquareSmall = false;

    return (
      <div style={styles.wrapper} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <div style={styles.inside} onClick={onClick}>
          <Square label="Lesson" number={lesson.sortIndex + 1} gradientName={ gradientName} hover={hover} isSmall={isSquareSmall} />
          <div style={styles.rightSide}>
            <div style={styles.title}>{lesson.title}</div>
            <ProgressSection lesson={lesson} status={status} progress={status==='in_progress' ? 50 : undefined} smallComplete={!showLessonLabel} />
          </div>
        </div>
      </div>
    );
  }
}

export default LessonItem;

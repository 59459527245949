import React, { Component } from 'react';
import { PulseLoader } from 'react-spinners';

class ForgotPasswordModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading: false
        }
    }

    submitEmail() {
        this.setState({loading: true});
        fetch('/password/sendnewpassword', {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: this.state.email })
        })
        .then(result => result.json())
        .then(result => {
            this.setState({
                email: '',
                loading: false
            });
            alert(result.message);
        })
    }


    render() {
        return (
            <div>
                <div class="modal fade" id="forgotPasswordModal" tabindex="-1" role="dialog" >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Forgot Password</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> 
                            </div>
                            <div class="modal-body">
                                <p class='col-12'>Enter your email used for login, and we will send you a temporary password.</p>
                                <div class='col-md-9'>
                                    <input class='form-control col-12' type='email' value={this.state.email} onChange={x => this.setState({ email: x.target.value })} placeholder='email...' />
                                    {/* <Link to='/share'>
                                    <a>Try the web version<a>
                                    </Link> */}
                                </div>
                                <div class='col-md-3'>
                                    <div 
                                        class='form-control btn btn-primary' 
                                        data-dismiss={null/*"modal"*/} 
                                        onClick={this.submitEmail.bind(this)} 
                                        style={{marginTop: 10, float: 'top'}}
                                    >

                                        {this.state.loading ? <PulseLoader color='#f7f7f7' /> : 'Submit'}
                                    </div>
                                </div>
                            </div>
                            {/* <div class="modal-footer">
                <a class="btn btn-secondary" href={'mailto:'+this.props.class.professorEmail+'?Subject=Question'} >Email Professor</a>
              </div> */}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const styles = {
    profileText: {
        // textAlign: 'center'
    },
    icon: {
        marginRight: 10,
    }
}

export default ForgotPasswordModal;
import * as types from '../types';
import { getGeneralDeckId } from '../../helpers/decks';


const defaultStateContents = {
  filterLanguage: true,
  filterDifficulty: "any",
  filterHidden: 'any', //admin only
  filterRead: "any",
  filterType: "any",
  filterCategory: "any",
  showArticles: null,
  filterShowFree: false,
  sortBy: "newest",
}

const initialState = {
  "/stories": {
    ...defaultStateContents,
    filterType: "story",
    filterCategory: "any",
    showArticles: null
  },
  "/conversations": {
    ...defaultStateContents,
    filterType: "conversation",
    search: "",
    filterCategory: "any",
    showArticles: null
  },
  "/browse": {
    ...defaultStateContents,
    filterType: "any",
    filterCategory: "any",
    filterDifficulty: "any",
    showArticles: null
  },
  "/favorites": {
    ...defaultStateContents,
    showArticles: "favorites",
    filterType: "any",
    filterCategory: "any",
    filterDifficulty: "any",
    filterRead: "any"
  },
  "/history": {
    ...defaultStateContents,
    showArticles: "history",
    filterType: "any",
    filterCategory: "any",
    filterDifficulty: "any",
    filterRead: "any"
  },

}

export function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_FILTER:
      return({
        ...state,
        [action.payload.pageKey]: {
          ...state[action.payload.pageKey],
          ...action.payload.obj
        }
      })

    default: return state;
  }
}
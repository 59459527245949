import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import Colors from "../../assets/Colors";
import Constants from "../../assets/Constants";
import podcast_logo_2 from '../../assets/img/podcast_logo_2.png';

class PodcastHeader extends Component {

  click = () => {
    this.props.history.push('/')
  }

  render() {
    return (
      <div style={styles.wrapper} onClick={this.click}>
        <img src={podcast_logo_2} style={styles.icon} alt={`${Constants.appName} Spanish Podcast`} />
        {/* <img src={require("../../assets/img/podcasts/title.png")} style={styles.text} alt={`${Constants.appName} Spanish Podcast`} /> */}
        <h1 style={styles.subtitle}>The Spanish Podcast That Will Skyrocket Your Spanish</h1>
      </div>
    );
  }
}

const styles = {
  text: {
    width: 400,
    maxWidth: "100%",
  },
  icon: {
    maxHeight: 140,
    maxWidth: '90%',
    marginBottom: 20
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: isMobile ? 25 : 40,
    cursor: 'pointer',
    paddingTop: 40

  },
  subtitle: {
    fontSize: 20,
    color: '#999999',
    fontWeight: 'normal',
    marginTop: 10,
    textAlign: 'center'
  }
};

export default withRouter(PodcastHeader);

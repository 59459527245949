import React, { Component } from "react";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { Checkbox } from "antd";

class AdminEditCoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      languages: [],
      checkState: {},
    };
  }

  componentDidMount() {
    this.loadLanguages();
  }

  loadLanguages = async () => {
    var langRes = await global.apiRequest({ url: "/languages/get/all" });
    let newCheckState = {};
    langRes.languages.forEach((langItem) => {
      if (langItem.title) {
        newCheckState[langItem.title] = langItem.isActive;
      }
    });

    this.setState({ languages: langRes.languages, loading: false, checkState: newCheckState });
  };

  onBoxCheck = (lang) => {
    let currentChecked = this.isLangChecked(lang);
    this.setState({
      checkState: {
        ...this.state.checkState,
        [lang]: !currentChecked,
      },
    });
    global.apiRequest({
      url: "/languages/set-active",
      method: "POST",
      body: {
        language: lang,
        isActive: !currentChecked,
      },
    });
  };

  isLangChecked = (lang) => {
    return this.state.checkState[lang] ? true : false;
  };

  render() {
    return (
      <div style={styles.wrapper} class="container-fluid">
        <AdminNavbar active={"manage-languages"} />
        {this.state.loading ? <LoadingOverlay /> : null}
        <div style={styles.body}>
          <h1>Languages</h1>
          <div>Select languages available for users</div>
          <br />
          {this.state.languages.map((lang) => {
            return (
              <div>
                <Checkbox checked={this.isLangChecked(lang.title)} onChange={() => this.onBoxCheck(lang.title)}>
                  {lang.title}
                </Checkbox>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    paddingTop: 75,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  body: {
    padding: 20,
  },
};

export default AdminEditCoursePage;

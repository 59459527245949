import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateLoggedInUser, toggleNav } from "../../redux/actions";
import logo from '../../assets/img/logo-header.png';

class MyNavbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        }
        // this.checkIfLoggedIn();
    }

    handleMenuPress(){
        //dont delete this empty function i forget why
    }

    handleLogout(){
      fetch('/logout', {
        method: 'get',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })
      // .then(result => result.json())
      .then(result => {
        this.props.onUpdateLoggedInUser(null);
      })
    }



    render() {
        const { loggedInUser } = this.props;

        let worker = loggedInUser.accountType==='admin' || loggedInUser.accountType==='moderator' || loggedInUser.accountType === 'editor';
        let admin = loggedInUser.accountType === 'admin' ? true : false;
        let moderator = loggedInUser.accountType === 'moderator' ? true : false;
        let editor = loggedInUser.accountType === 'editor';
        let loggedIn = loggedInUser ? true : false;

        return (

            <nav class="navbar navbar-expand-lg bg-white navbar-light box-shadow fixed-top" style={{zIndex: 100}}>
                <a class="navbar-brand" href="#">
                    <img src={require('../../assets/img/logo-header.png')} style={{height: 28}} />
                    <span style={{marginLeft: 12, color: 'grey'}} class='align-middle'>Admin</span>
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarsExample03" >
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item" style={this.props.active=='manage-articles' ? styles.activeLink : styles.navLink}>
                              <Link to='/manage-articles' class='nav-link'>
                                  <a>Articles</a>
                              </Link>
                            </li>
                            <li class="nav-item" style={this.props.active=='manage-podcasts' ? styles.activeLink : styles.navLink}>
                              <Link to='/manage-podcasts' class='nav-link'>
                                  <a>Podcasts</a>
                              </Link>
                            </li>
                            {admin || moderator ? 
                                <li class="nav-item" style={this.props.active=='manage-lesson-decks' ? styles.activeLink : styles.navLink}>
                                <Link to='/manage-lesson-decks' class='nav-link'>
                                    <a>Lesson Decks</a>
                                </Link>
                                </li>
                            :null}
                            {admin || moderator ? 
                                <li class="nav-item" style={this.props.active=='manage-courses' ? styles.activeLink : styles.navLink}>
                                <Link to='/manage-courses' class='nav-link'>
                                    <a>Courses</a>
                                </Link>
                                </li>
                            :null}
                            {admin || moderator ? 
                                <li class="nav-item" style={this.props.active=='sitebanner' ? styles.activeLink : styles.navLink}>
                                <Link to='/sitebanner' class='nav-link'>
                                    <a>Site Banner</a>
                                </Link>
                                </li>
                            :null}
                            {admin || moderator ? 
                                <li class="nav-item" style={this.props.active=='manage-languages' ? styles.activeLink : styles.navLink}>
                                <Link to='/manage-languages' class='nav-link'>
                                    <a>Languages</a>
                                </Link>
                                </li>
                            :null}
                            {this.props.loggedInUser.accountType=='admin' ? 
                                <li class="nav-item" style={this.props.active=='viewusers' ? styles.activeLink : styles.navLink}>
                                <Link to='/viewusers' class='nav-link'>
                                    <a>Users</a>
                                </Link>
                                </li>
                            :null}
                            {this.props.loggedInUser.accountType=='admin' ? 
                                <li class="nav-item" style={this.props.active=='logs' ? styles.activeLink : styles.navLink}>
                                <Link to='/logs' class='nav-link'>
                                    <a>Logs</a>
                                </Link>
                                </li>
                            :null}
                            <li class="nav-item align-middle" style={{...styles.navLink, marginRight:20, cursor: 'pointer'}} onClick={this.handleLogout.bind(this)}>
                              <a class="nav-link">Log Out</a>
                            </li>
                        </ul>
                </div>
            </nav>
        );
    }
}

const styles = {
    navbar: {
        marginBottom: 0
    },
    navToggleButton: {
        padding: 5,
        paddingRight: 30,
        display: 'inline'
    },
    activeLink: {
        fontWeight: 'bold',
        color: '#000',
        marginLeft: 10,
        borderBottom:'1px solid #000'

    },
    navLink: {
        marginLeft: 10,
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser,
    navState: state.navState
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser, //to prevent variable collisions with naming, use on
    toggleNav,
}

export default connect(mapStateToProps, mapActionsToProps)(MyNavbar);

import * as types from "../types";

const initialState = {
  courses: {},
};

export function courseStatusReducer(state = initialState, action) {
  let existingCourse = state.courses[(action.payload || {}).courseId] || { completedLessonIds: [], inProgressLessonIds: [] };

  switch (action.type) {
    case types.HYDRATE_COMPLETE_LESSONS:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.courseId]: {
            ...existingCourse,
            completedLessonIds: action.payload.completedLessonIds,
          },
        },
      };
    case types.HYDRATE_IN_PROGRESS_LESSONS:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.courseId]: {
            ...existingCourse,
            inProgressLessonIds: action.payload.inProgressLessonIds,
          },
        },
      };

    case types.MARK_LESSON_COMPLETE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.courseId]: {
            ...existingCourse,
            completedLessonIds: [...existingCourse.completedLessonIds, action.payload.lessonId],
          },
        },
      };

    case types.MARK_LESSON_INCOMPLETE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.courseId]: {
            ...existingCourse,
            completedLessonIds: [...existingCourse.completedLessonIds].filter(x => x !== action.payload.lessonId),
          },
        },
      };

    case types.MARK_LESSON_IN_PROGRESS:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.courseId]: {
            ...existingCourse,
            inProgressLessonIds: [...existingCourse.inProgressLessonIds, action.payload.lessonId],
          },
        },
      };

    default:
      return state;
  }
}

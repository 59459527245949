import React, { Component } from 'react';

class StorylingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { text, onClick, disabled, width, loading } = this.props;
    if(loading) disabled = true
    return(
      <div style={{...styles.wrapper, opacity: disabled ? .6 : 1, width}} onClick={disabled || loading ? () => null : onClick}>
        {loading ? '...' : text}
      </div>
    );
  }
}

const styles = {
wrapper: {
    paddingLeft: 20,
    paddingRight :20,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 4,
    color: "white",
    backgroundColor: "#4BB24D",
    cursor: 'pointer',
    minWidth: 100


  
}
};

export default StorylingButton;
import React, { Component } from "react";
import { toggleNav, updateLoggedInUser } from "../../../redux/actions";
import { modifyDeck, selectDeckId, getGeneralDeckId, fetchDecks } from '../../../helpers/decks';
import { connect } from "react-redux";
import Colors from "../../../assets/Colors";
import { isMobile, isBrowser } from "react-device-detect";
import getDictionaryLink from "../../../scripts/getDictionaryLink";
import addFlashcard from "../../../scripts/addFlashcard";
import languageCode from "../../../scripts/languageCode";
import Sound from "react-sound";
import { message } from 'antd'


//Components
import { PulseLoader } from "react-spinners";
import ReactCardFlip from "react-card-flip";
import CustomCardModal from "../../../components/modals/CustomCardModal";
import WordsPageMobile from "./WordsPageMobile";
import WordList from "./WordList";
import PageTemplate from '../../PageTemplate';
import DecksSection from "./DecksSection";
import CustomModal from "../../../components/modals/CustomModal";
import DeckEmptyMessage from "./DeckEmptyMessage";
import { selectLessonDeck, fetchLessonDecks } from "../../../helpers/lessonDecks";
import LoadingOverlay from '../../../components/LoadingOverlay';
const queryString = require("querystring");


const maxCardHeight = 320;

class WordsPage extends Component {
  constructor(props) {
    super(props);
    const queryItems = queryString.parse((props.router.location.search || '').replace("?", "")) || {};
    let lessondeckid = queryItems.lessondeckid || "";
    this.state = {
      isFlipped: false,
      selectedWord: {
        word: "",
        translation: ""
      },
      flipSpeed: 0.3,
      reverse: false,
      audioUrl: "",
      playing: false,
      cachedUrls: [],
      pronounciationLoading: false,
      isSpacebarActive: true,
      shuffle: false,
      selectedTab: 'flashcards',
      renameCardModal: {word: {}},
      loading: lessondeckid ? true : false
      // decks: []
    };
  }

  //-------------------LIFECYCLE-----------------------
  componentDidMount() {
    // this.updateWordList();
    const queryItems = queryString.parse((this.props.router.location.search || '').replace("?", "")) || {};
    let lessondeckid = queryItems.lessondeckid || "";
    document.addEventListener("keydown", this.handleKeyDown);
    this.urlInterval = setInterval(() => {
      this.setState({ cachedUrls: [] });
    }, 1000 * 60 * 60 * 1.9); //urls expire after 2 hrs
    fetchDecks().then(res => {
      if(!lessondeckid){
        selectLessonDeck({})
      }
      selectDeckId(getGeneralDeckId())
      this.loadDefaultWord()
    })
    if(lessondeckid){
      fetchLessonDecks().then(ldRes => {
        let ld = this.props.lessonDeckInfo.decks[lessondeckid]
        this.onSelectLessonDeck(ld)
        this.loadDefaultWord()
        this.setState({loading: false})
      })
    }else{
      selectDeckId(getGeneralDeckId())
      this.loadDefaultWord();
    }
  }

  componentDidUpdate(prevProps, prevState){
    try{
      let thisDeck = this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId] || {};
      let prevDeck = prevProps.deckInfo.decks[prevProps.deckInfo.selectedDeckId] || {};
      if(!prevDeck.list && (thisDeck.list || []).length>0){
        this.loadDefaultWord()
      }
    }catch(e){
      console.warn(e)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    clearInterval(this.urlInterval);
  }

  //-------------------METHODS-----------------------------
  //removes word from list based on index and updates server
  removeWord = (e, word='') => {
    if(!word) word = this.state.selectedWord.word;
    let newDeck = {...this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId]}
    newDeck.list = newDeck.list.filter(x => x.word !== word)
    modifyDeck(newDeck);
    this.nextWord("foreward");
  }

  loadDefaultWord = () => {
    try{
      if(this.props.lessonDeckInfo.selectedDeck.id){
        let thisList = this.props.lessonDeckInfo.selectedDeck.list || [];
        this.setState({selectedWord: thisList[0] || {word: '', translation: ''}})
      }else{
        let thisList = this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId].list || [];
        this.setState({selectedWord: thisList[0] || {word: '', translation: ''}})
      }
    }catch(e){}
  }

  // loadMyDecks = () => {
  //   fetch(`/words/decks/getall`, {
  //     method: "GET",
  //     credentials: "same-origin",
  //     headers: { Accept: "application/json", "Content-Type": "application/json" }
  //   })
  //   .then(result => result.json())
  //   .then(result => {
  //     console.log('decks res', result);
  //     if (result.status == "success") {
  //       let filteredDecks = result.decks.filter(x => x.language === this.props.selectedLanguage)
  //       this.setState({decks: filteredDecks})
  //     }
  //   })
  // }


  shuffleArray(array) {
    //shuffles it in place
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  getRandomIndex(){
    let wordList = this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId].list;
    if(this.props.lessonDeckInfo.selectedDeck.id) wordList = this.props.lessonDeckInfo.selectedDeck.list
    const getRand = () => {
      let rand = Math.round(Math.random()*wordList.length-1);
      if(rand<0) rand = 0;
      return rand;
    }
    let randomIndex = null;
    while(randomIndex === null){
      let canidate = getRand();
      if(wordList.length<3) randomIndex = canidate; // it was freezing if only 2 words
      if(wordList[canidate] && this.state.selectedWord != wordList[canidate]){
        randomIndex = canidate;
      }
    }
    return randomIndex;
  }

  nextWord(direction) {
    console.log('nextword '+direction)
    let wordList = ((this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId] || {}).list || []);
    if(this.props.lessonDeckInfo.selectedDeck.id) wordList = this.props.lessonDeckInfo.selectedDeck.list

    let { shuffle } = this.state;
    if (wordList.length == 0) return;
    this.setState({ isFlipped: false, flipSpeed: 0 });
    let currentWordIndex = 0;
    wordList.forEach((w, wIndex) => {
      if(w.word === this.state.selectedWord.word) currentWordIndex = wIndex;
    })
    if(shuffle){
      this.setState({selectedWord: wordList[this.getRandomIndex()]})
    }else{
      if (direction == "foreward") {
        console.log('sel word', this.state.selectedWord)
        if (currentWordIndex == wordList.length - 1) {
          this.setState({ selectedWord: wordList[0] });
        } else {
          let nextWordIndex = currentWordIndex + 1;
          this.setState({ selectedWord: wordList[nextWordIndex] });
        }
      } else {
        //go backwards
        if (currentWordIndex == 0) {
          //if on first word
          this.setState({ selectedWord: wordList[wordList.length - 1] }); //last word
        } else {
          let previousWordIndex = currentWordIndex - 1;
          this.setState({ selectedWord: wordList[previousWordIndex] });
        }
      }
    }
    if (!isMobile && this["word" + this.state.selectedWord.word]){
      try{
        setTimeout(() => {
          if(this["word" + this.state.selectedWord.word]) this["word" + this.state.selectedWord.word].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "end" });
        }, 20);
      }catch(e){
        console.warn(e)
      }
    }
  }

  handleKeyDown = (e) => {
    const LEFT_ARROW = 37;
    const UP_ARROW = 38;
    const RIGHT_ARROW = 39;
    const DOWN_ARROW = 40;
    const SPACEBAR = 32;
    switch (e.keyCode) {
      case LEFT_ARROW:
        // this.setState({isFlipped: false, flipSpeed: 0});
        this.nextWord("backward");
        break;
      case UP_ARROW:
        e.preventDefault(); //stop from scrolling
        // this.setState({isFlipped: false, flipSpeed: 0});
        this.nextWord("backward");
        break;
      case RIGHT_ARROW:
        // this.setState({isFlipped: false, flipSpeed: 0});
        this.nextWord("foreward");
        break;
      case DOWN_ARROW:
        e.preventDefault(); //stop from scrolling
        // this.setState({isFlipped: false, flipSpeed: 0});
        this.nextWord("foreward");
        break;
      case SPACEBAR:
        if (this.state.isSpacebarActive) {
          //see customcardmodal
          e.preventDefault(); //stop spacebar from scrolling
          this.flipCard();
        }
        break;
      default:
        break;
    }
  }

  flipCard() {
    this.setState({ isFlipped: !this.state.isFlipped, flipSpeed: 0.3 });
  }

  submitCard(card) {
    let newDeck = {...this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId]}
    newDeck.list.push(card);
    modifyDeck(newDeck);
  }

  handleReadWord(e) {
    // //check cached urls -- this was causing problems so i stopped caching
    // if (this.state.cachedUrls[this.state.selectedWord.word]) {
    //   this.setState({
    //     audioUrl: this.state.cachedUrls[this.state.selectedWord.word],
    //     playStatus: Sound.status.PLAYING
    //   });
    // } else {

    // safari and chrome dont let audio begin playing unless it is at the same time as a user click.
    // since we need to load url from forvo asynchrononsly we start playing audio immedately, then switch the source when it comes in.
    e.stopPropagation();
    const silence = "https://s3.us-east-2.amazonaws.com/lingomastery/silence.mp3"; //10 sec of silence
    this.setState({
      playStatus: Sound.status.PLAYING,
      audioUrl: silence,
      pronounciationLoading: true
    });
    fetch("/words/pronounce", {
      method: "POST",
      credentials: "same-origin",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        word: this.state.selectedWord.word,
        language: this.props.selectedLanguage
      })
    })
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      let newUrls = this.state.cachedUrls;
      newUrls[this.state.selectedWord.word] = url;
      this.setState({
        //dont set play status here
        audioUrl: url,
        cachedUrls: newUrls,
        pronounciationLoading: false
      });
    })
  }

  handleWordFinishedPlaying() {
    this.setState({ playStatus: Sound.status.STOPPED });
  }

  onSelectDeck = deck => {
    selectLessonDeck({})
    selectDeckId(deck.id);
    let nextSelectedWord = (deck.list || []).length>0 ? deck.list[0] : {word: '', translation: ''} ;
    this.setState({selectedTab: 'flashcards', selectedWord: nextSelectedWord })
  }

  onSelectLessonDeck = deck => {
    selectLessonDeck(deck)
    let nextSelectedWord = (deck.list || []).length>0 ? deck.list[0] : {word: '', translation: ''} ;
    this.setState({selectedTab: 'flashcards', selectedWord: nextSelectedWord })
  }

  moveWord = (e, word, newDeck) => {
    // console.log('move '+word.word+' to '+newDeck.title)
    message.success("Moved!")
    // e.stopPropagation()
    
    fetch(`/words/moveword`, {
      method: "POST",
      credentials: "same-origin",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        wordObject: word,
        language: this.props.selectedLanguage,
        toDeckTitle: newDeck.title,
        fromDeckTitle: this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId].title
      })
    })
    .then(result => result.json())
    .then(result => {
      fetchDecks()
    })

  }

  copyWord = (e, word, newDeck) => {

    // console.log('copy '+word.word+' to '+newDeck.title)
    message.success("Copied!")
    // e.stopPropagation()

    fetch(`/words/copyword`, {
      method: "POST",
      credentials: "same-origin",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        wordObject: word,
        language: this.props.selectedLanguage,
        toDeckTitle: newDeck.title
      })
    })
    .then(result => result.json())
    .then(result => {
      fetchDecks()
    })


  }

  renameWord = (e, word, index) => {
    console.log('e', e)

    e.stopPropagation()
    this.setState({
      renameCardModal: {
        visible: true,
        word: word,
        wordIndex: index
      }
    })

  }

  handleSubmitRenameCard = () => {
    let newDeck = {...this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId]}
    newDeck.list[this.state.renameCardModal.wordIndex] = this.state.renameCardModal.word
    console.log('wordIndex', this.state.renameCardModal.wordIndex)
    modifyDeck(newDeck);
    this.setState({renameCardModal: {word: {}}})
    message.success("Renamed!")
    this.nextWord("foreward");
  }

  handleRenameCardWordChange = e => {
    this.setState({
      renameCardModal: {
        ...this.state.renameCardModal,
        word: {
          ...this.state.renameCardModal.word,
          word: e.target.value
        }
      }
    })

  }

  handleRenameCardTranslationChange = e => {
    this.setState({
      renameCardModal: {
        ...this.state.renameCardModal,
        word: {
          ...this.state.renameCardModal.word,
          translation: e.target.value
        }
      }
    })
  }

  render() {
    let longWord = this.state.selectedWord.word.length > 20 ? true : false;
    let isSingleWord = !this.state.selectedWord.word.includes(" ");
    let wordStyle = {
      fontSize: longWord ? 20 : 36,
      // fontWeight: longWord ? "normal" : "bold",
      maxHeight: maxCardHeight,
      overflow: "auto",
      height: "100%",
      alignItems: "center",
      margin: "auto",
      textAlign: "center",
      justifyContent: "center",
      display: "flex",
      whiteSpace: "pre-wrap"
    };

    let selectedDeck = this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId] || {list: []};
    if(this.props.lessonDeckInfo.selectedDeck.id) selectedDeck = this.props.lessonDeckInfo.selectedDeck;
    if(this.state.loading) selectedDeck = {}


    let currentDeckHasCards = true;
    if(!this.props.deckInfo.selectedDeckId && (!this.props.lessonDeckInfo.selectedDeck || {}).id) currentDeckHasCards = false;
    if(this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId] && !this.props.lessonDeckInfo.selectedDeck.id){
      if(this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId].list){
        if(this.props.deckInfo.decks[this.props.deckInfo.selectedDeckId].list.length<1){
          currentDeckHasCards = false;
        }
      }
    } 

    let buttons =
      // longWord || isSingleWord == false ? null : (
        false ? null : ( // disable this since elevenlabs can handle long words
        <div style={styles.defineButtonWrapper}>
          <a
            href={getDictionaryLink(this.state.selectedWord.word ? this.state.selectedWord.word : "", this.props.selectedLanguage)}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div style={{ ...styles.defineButton, backgroundColor: "#54B358" }} class="fadeHover">
              <i class="fa fa-book" style={{ marginRight: 10 }} /> Dictionary
            </div>
          </a>
          <span>
            <div style={{ width: 20, height: 10, display: "inline-block" }} />
            <div style={{ ...styles.defineButton, backgroundColor: "#0c66ff" }} class="fadeHover" onClick={e => this.handleReadWord(e)}>
              {this.state.pronounciationLoading ? (
                <span>
                  <PulseLoader color="white" size={9} />
                </span>
              ) : (
                <span>
                  <img src={require("../../../assets/img/speaker.png")} style={styles.buttonIcon} />
                  Pronounce
                </span>
              )}
            </div>
          </span>
        </div>
      );

      const desktopContents = (
        this.state.selectedTab==='flashcards' ? 
        <React.Fragment>
          {currentDeckHasCards ? 
          <div style={styles.insideLeftCol}>
          {/* <div style={{...styles.circleButton, float: 'left'}}></div> */}
          <div style={styles.circleButtonLeft} class="fadeHover" onClick={() => this.nextWord("backward")}>
            <img src={require("../../../assets/img/arrow-back.png")} style={styles.nextWordIcon} />
          </div>
          <ReactCardFlip
            isFlipped={this.state.isFlipped}
            flipSpeedFrontToBack={this.state.flipSpeed}
            flipSpeedBackToFront={this.state.flipSpeed}
            style={{ display: "inline-block" }}
          >
            <div style={styles.flashCard} id="flashCard" class="mx-auto text-center row" key="front">
              <div class="col-12 px-0" onClick={this.flipCard.bind(this)} style={styles.cardMiddle}>
                <div style={wordStyle}>
                  <p style={{ margin: "auto", whiteSpace: "pre-wrap" }}>
                    {this.state.reverse ? this.state.selectedWord.translation : this.state.selectedWord.word}
                  </p>
                </div>
                {longWord ? null : (
                  <div>
                    <div style={styles.flipCardIconWrapper}>
                      <img src={require("../../../assets/img/turn.png")} style={styles.flipCardIcon} />
                    </div>
                  </div>
                )}
                {this.state.reverse ? null : buttons}
              </div>
            </div>
            <div style={styles.flashCard} id="flashCard" class="mx-auto text-center row" key="back">
              <div class="col-12 px-0" onClick={this.flipCard.bind(this)} style={styles.cardMiddle}>
                <div style={wordStyle}>
                  <div style={{ margin: "auto" }}>
                    {this.state.reverse ? this.state.selectedWord.word : this.state.selectedWord.translation}
                  </div>
                </div>
                {longWord ? null : (
                  <div>
                    <div style={styles.flipCardIconWrapper}>
                      <img src={require("../../../assets/img/turn.png")} style={styles.flipCardIcon} />
                    </div>
                  </div>
                )}
                {this.state.reverse ? buttons : null}
              </div>
            </div>
          </ReactCardFlip>
          <div style={styles.circleButtonRight} class="fadeHover" onClick={() => this.nextWord("foreward")}>
            <img src={require("../../../assets/img/arrow-forward.png")} style={styles.nextWordIcon} />
          </div>
          {/* <div style={{...styles.circleButton, float: 'right' }}></div> */}
        </div>
          :
          <DeckEmptyMessage />
          }
          
        </React.Fragment>
        :
        <DecksSection toggleSpacebarActive={x => this.setState({ isSpacebarActive: x })} onSelectDeck={this.onSelectDeck} onSelectLessonDeck={this.onSelectLessonDeck}/>
        
      )

    let desktop = (
      <div style={styles.flexRow}>
        <CustomModal visible={this.state.renameCardModal.visible} onClose={() => this.setState({renameCardModal: {word: {}}})} submitButtonText={'Submit'} title="Edit Card" onSubmit={this.handleSubmitRenameCard} marginBottom={isMobile ? 0 :  300}>
          <div style={{padding: 20}}>
          <input class='form-control' style={{width: '100%'}} placeholder="Word" style={{minHeight: 50}} onChange={this.handleRenameCardWordChange} value={this.state.renameCardModal.word.word} />
          <div style={{height: 12}}></div>
          <input class='form-control' style={{width: '100%'}} placeholder="Translation" style={{minHeight: 50}} onChange={this.handleRenameCardTranslationChange} value={this.state.renameCardModal.word.translation} />
          </div>
        </CustomModal>
        <div style={styles.leftCol}>
         <LoadingOverlay hide={!this.state.loading} /> 
         {desktopContents}
        </div>
        <WordList
          selectedWord={this.state.selectedWord}
          setParentState={x => this.setState(x)}
          removeWord={this.removeWord}
          reverse={this.state.reverse}
          shuffle={this.state.shuffle}
          setref={(name, ref) => (this[name] = ref)}
          onSelectTab={x => this.setState({selectedTab: x})}
          selectedTab={this.state.selectedTab}
          setDecks={x => this.setState({decks: x})}
          decks={this.state.decks}
          moveWord={this.moveWord}
          copyWord={this.copyWord}
          renameWord={this.renameWord}
          selectedDeck={selectedDeck}
        />
      </div>
    );

    let mobile = (
      this.state.selectedTab==='flashcards' ? 
      <WordsPageMobile
        deleteCurrentWord={() => this.removeWord()}
        selectedWord={this.state.selectedWord}
        next={() => this.nextWord("foreward")}
        back={() => this.nextWord("backward")}
        shuffle={this.state.shuffle}
        toggleShuffle={() => this.setState({shuffle: !this.state.shuffle})}
        defineLink={getDictionaryLink(this.state.selectedWord.word ? this.state.selectedWord.word : "", this.props.selectedLanguage)}
        handleReadWord={e => this.handleReadWord(e)}
        longWord={longWord}
        pronounciationLoading={this.state.pronounciationLoading}
        fetchWordsList={() => this.props.fetchWordListThunk(this.props.selectedLanguage)}
        goToDecks={() => this.setState({selectedTab:'decks'})}
        selectedDeck={selectedDeck}
      />
      :
      <DecksSection toggleSpacebarActive={x => this.setState({ isSpacebarActive: x })} onSelectDeck={this.onSelectDeck} onSelectLessonDeck={this.onSelectLessonDeck}/>
    );

    return (
      <PageTemplate limitSize={isMobile ? false : true} forvo={true}>
      {/* <PageTemplate forvo={true}> */}
          <CustomCardModal submitCard={this.submitCard.bind(this)} toggleSpacebarActive={x => this.setState({ isSpacebarActive: x })}  />
          <Sound
            autoLoad={true}
            url={this.state.audioUrl}
            playStatus={this.state.playStatus}
            // playFromPosition={0 /* in milliseconds */}
            // onLoading={this.handleSongLoading}
            // onPlaying={x => this.handleSongPlaying(x)}
            // position={this.state.position}
            // playbackRate={1}
            onFinishedPlaying={this.handleWordFinishedPlaying.bind(this)}
          />
          {isMobile ? mobile : desktop}
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage,
  wordList: state.wordList,
  deckInfo: state.deckInfo,
  lessonDeckInfo: state.lessonDeckInfo
});

const mapActionsToProps = {
  onUpdateLoggedInUser: updateLoggedInUser,
  toggleNav,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(WordsPage);

const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "100%",
    maxHeight: '100%',
    overflowY: 'auto'
  },
  leftCol: {
    flex: 1
  },
  insideLeftCol: {
    minWidth: 500,
    maxWidth: 750,
    marginTop: 100,
    margin: "auto",
    position: "relative"
  },
  flashCard: {
    width: "77%",
    minHeight: maxCardHeight,
    backgroundColor: "#fff",
    boxShadow: "0 5px 36px 0 rgba(0, 0, 0, 0.22)",
    marginTop: 40,
    borderRadius: 12,
    cursor: "pointer",
    padding: 10
  },
  cardMiddle: {
    alignItems: "center",
    justifyContent: "center"
  },
  nextCol: {
    //side cols on card
    cursor: "pointer",
    paddingTop: 100
  },
  cardFlipContainer: {
  },
  nextWordIcon: {
    height: 52,
    width: 52,
    margin: "auto",
    marginTop: 14
  },
  circleButtonLeft: {
    position: "absolute",
    left: 0,
    height: 66,
    width: 66,
    borderRadius: 33,
    textAlign: "center",
    marginTop: 160,
    zIndex: 1000
  },
  circleButtonRight: {
    position: "absolute",
    right: 0,
    height: 66,
    width: 66,
    borderRadius: 33,
    textAlign: "center",
    marginTop: 160,
    zIndex: 1000
  },
  flipCardIcon: {
    width: 39,
    textAlign: "center"
  },
  flipCardIconWrapper: {
    width: "100%",
    position: "absolute",
    top: "85%"
  },
  defineButton: {
    padding: 9,
    borderRadius: 4,
    color: "white",
    backgroundColor: "#82ca1c",
    width: 140,
    margin: "auto",
    display: "inline-block",
    zIndex: 9
  },
  defineButtonWrapper: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    position: "absolute",
    top: 350,
    paddingRight: 15
  },
  buttonIcon: {
    display: "inline-block",
    height: 18,
    marginRight: 12
  }
};

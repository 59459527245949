import React, { Component } from 'react';
import { Input, Select } from 'antd';
const { Option } = Select;
const { TextArea } = Input;

class AdminInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, onChangeText, value, style, placeholder, onChange, options, labelWidth, multiline } = this.props;

    const styles = {
      wrapper: {
        ...style,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        width: '100%'
      },
      label: {
        width: labelWidth || 120,
        wordWrap: 'break-word',
        overflow: 'hidden',
        marginRight: 15
      },
      inputWrapper: {
        width: '100%'

      },
      input: {
        minWidth: 205,
        width: '100%'
      }
    };

    return(
      <div style={styles.wrapper}>
        <div style={styles.label}>{label || ''}</div>
        <div style={styles.inputWrapper}>
          {options ? 
            <Select value={value} onChange={onChange} style={{width: '100%', minWidth: 205}}>
              {options.map(o => <Option value={o.value}>{o.label}</Option>)}
            </Select>
          :
            (multiline ? 
              <TextArea onChange={e => onChangeText(e.target.value)} value={value} placeholder={placeholder} style={styles.input} rows={3} />
              : 
              <Input onChange={e => onChangeText(e.target.value)} value={value} placeholder={placeholder} style={styles.input}  />
            )
          }

        </div>
      </div>
    );
  }
}



export default AdminInput;
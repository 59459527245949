import React, { Component } from "react";
import Colors from "../../assets/Colors";
import Socials from "../Socials";

class PlayerControls extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  msToTime(ms) {
    //convert ms to mm:ss standard time
    let secs = Math.round((ms / 1000) % 60);
    if (secs < 10) {
      secs = "0" + secs;
    }
    let mins = Math.floor((ms / (1000 * 60)) % 60);
    return mins + ":" + secs;
  }

  playImage = require("../../assets/img/music-controls/play.png");
  pauseImage = require("../../assets/img/music-controls/pause.png");

  render() {
    let {
      musicPlayerLocked,
      musicBoxStyle,
      setRef,
      position,
      duration,
      togglePlaybackRate,
      playbackRate,
      handleSkip,
      handlePlayPause,
      playStatus,
      shadow,
      socials
    } = this.props;

    let progressValue = (position / duration) * 100;
    if (duration < 1) progressValue = 0;

    let musicBoxShadow = { boxShadow: shadow ? shadow : "0 -18px 18px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1)" };

    return (
      <div style={musicPlayerLocked ? null : { maxWidth: 780, float: "none" }}>
        <div style={{ ...musicBoxShadow, ...musicBoxStyle }} class="music-box" ref={x => setRef("musicBox", x)}>
        {/* {socials ? (
          <div style={styles.socialContainer}>
            <Socials />
          </div>
        ) : null} */}
          {" "}
          {/*the white container with music controls*/}
          <div class="dashboard">
            <div class={musicPlayerLocked ? "" : "w-100"}>
              <div class="player">
                {/* <div class="time-rail">
                <div class="thumb" style={{left: `${(this.props.position/this.props.duration)*100}%`}}></div>
                <div class="track"></div>
              </div> */}
                <div style={styles.progressRow}>
                  <span style={styles.timespan}>{this.msToTime(position)}</span>
                  <progress
                    style={styles.progress}
                    ref={x => setRef("progressBar", x)}
                    id="seek-obj"
                    value={`${progressValue}`}
                    max="100"
                  />
                  <span style={styles.timespan}>{this.msToTime(duration)}</span>
                </div>
              </div>
              <div class="action-button">
                <div
                  title="75% speed"
                  class="fadeHover"
                  onClick={togglePlaybackRate}
                  style={playbackRate == 1 ? styles.slow : styles.slowSelected}
                >
                  <img src={require("../../assets/img/music-controls/speed.png")} style={styles.slowIcon} />
                  {playbackRate === 1 ? "1x" : ".75x"}
                </div>
                <a class="prev fadeHover" onClick={() => handleSkip("backward")}>
                  <img src={require("../../assets/img/music-controls/left.png")} style={styles.seekButtons} title="-5 seconds" />
                </a>
                <span class="fadeHover" style={styles.playButtonWrapper} onClick={() => handlePlayPause()}>
                  <img src={playStatus === "PLAYING" ? this.pauseImage : this.playImage} style={styles.playPauseImage} />
                </span>
                <a class="next fadeHover" onClick={() => handleSkip("foreward")}>
                  <img src={require("../../assets/img/music-controls/right.png")} style={styles.seekButtons} title="+5 seconds" />
                </a>
                <div style={{ display: "inline-block", minWidth: 70 }} /> {/*fake div to balance the item alignment */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  progressRow: {
    display: "flex",
    flexDirection: "row"
  },
  oneLine: {
    // whiteSpace: 'wrap',
    overflow: "hidden"
  },
  timespan: {
    width: 30,
    marginLeft: 10,
    marginRight: 10,
    color: "#f07300",
    fontSize: 13
  },
  seekButtons: {
    width: 20,
    height: 20
    // marginBottom: 5,
  },
  playPauseImage: {
    height: 50,
    alignItems: "center"
  },
  playButtonWrapper: {
    marginLeft: 15,
    marginRight: 15
  },
  progress: {
    width: "100%",
    height: 4,
    // backgroundColor: 'rgb(130, 202, 28)',
    backgroundColor: "#d4d4d4",
    border: "0px",
    opacity: 1,
    borderRadius: 10,
    overflow: "hidden",
    marginTop: 8
  },
  slow: {
    color: "#8a94a6",
    padding: 3,
    border: "1px solid #e1e4e8",
    borderRadius: 20,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    minWidth: 70,
    display: "inline-block",
    marginRight: 5
  },
  slowSelected: {
    color: "#8a94a6",
    padding: 3,
    border: "1px solid " + Colors.blueGrey,
    borderRadius: 20,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    minWidth: 70,
    display: "inline-block",
    marginRight: 5
  },
  slowIcon: {
    height: 26,
    width: 26,
    paddingBottom: 2,
    paddingRight: 4
  },
  socialContainer: {
    position: "absolute",
    left: 0,
    bottom: -200
  }
};

export default PlayerControls;

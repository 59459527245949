import React, { Component, PureComponent } from 'react';
import ReactMarkdown from "react-markdown";
import { isBrowser, isMobile } from 'react-device-detect';
import $ from 'jquery';
import LightButton from '../buttons/LightButton';
import BlurCover from '../podcasts/BlurCover';
import { connect } from 'react-redux';
import removePunctuation from '../../scripts/removePunctuation';
// let playIcon = require('../../assets/img/play-circle.png');
let pauseIcon = require("../../assets/img/music-controls/pause.png");
let playIcon = require("../../assets/img/music-controls/play.png");
let greyPlayIcon = require("../../assets/img/music-controls/play_grey.png");
let soundPlayingIcon = require("../../assets/img/music-controls/sound_playing.png");

class Paragraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleWordClicked(item, wordId, onboardingClass){
      let isPlainText = this.props.paragraph.isPlainText;
      if(isPlainText) return;
      // if(isBrowser){ //render popup next to paragraph in browser
      //   this.props.wordClicked(item, 'paragraphCard' + this.props.id);
      // }else{ //render popop on word for mobile
        this.props.wordClicked(item, wordId);
        if(onboardingClass) $('.onboardingWord').removeClass('onboardingWord');
      // }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext){
      // stop paragraphs from rendering everytime page is scrolled
      if(nextProps.position !== this.props.position || nextProps.playStatus !== this.props.playStatus || nextProps.deckInfo.allSavedWords !== this.props.deckInfo.allSavedWords){
        return true
      }
      return false
    }


    render() {
      let loggedInUser = this.props.loggedInUser || {};
      let isPlainText = this.props.paragraph.isPlainText;
      let wordsArray = this.props.paragraph.text.split(/(\s+)/) //dont remember exactly what this regex matches but it works
      let onboardingAlreadySet = false;
      let bold = false;
      let italic = false;
      let turnBoldOff=false;
      let turnItalicOff=false;
      let addLinebreak = false;
      let words = wordsArray.map((item, index) => {
        if( item.includes('**') ){
          if(bold==false){ bold=true }
          else{ turnBoldOff=true }
          if(item.slice(-3).includes('**')) turnBoldOff = true; // for single words, allow a punctuation on end
          item = item.replace(/\**/g, '')
        } 
        if( item.includes('*') && !item.includes('**') ){
          if(italic==false){ italic=true }
          else{turnItalicOff=true }
          if(item.slice(-2).includes('*')) turnItalicOff = true; // for single words, allow a punctuation on end
          item = item.replace(/\*/g, '')
        } 
        // this may cause slowness - could i do something so i dont have to remove punctuation every time here?
        let isAlreadySavedToFlashcard = this.props.deckInfo.allSavedWords[removePunctuation(item)] === true;
        let wordSpanStyle = {
          borderRadius: 3,
          position: 'relative',
          zIndex: 3,  
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: loggedInUser.fontSize,
          fontWeight: bold ? 'bold' : '400',
          fontStyle: italic ? 'italic' : 'normal',
        };
        if(turnItalicOff) italic=false;
        if(turnBoldOff) bold=false;

        let onboardingClass = '';
        if(item.length>5 && this.props.index===0 && !onboardingAlreadySet && !loggedInUser){
          onboardingAlreadySet=true;
          onboardingClass = ' onboardingWord';
        }

        // -- linebreaks --
        if(item === ' ') return null; // if item is a space
        // if this linebreak was already accounted for last loop
        if(addLinebreak || item.match(/(\r\n|\r|\n)/)){
          addLinebreak = false;
          return '';
        }
        if(wordsArray[index+1]) if(wordsArray[index+1].match(/(\r\n|\r|\n)/) ){ // if next item is a line break
          // multiple linebreaks are held in 1 array item, it doesnt split them up. here we find out how many linebreaks are in this item
          let num = wordsArray[index+1].split(/\r\n|\r|\n/).length;
          addLinebreak = 1;
          if(num===3) addLinebreak = 2;
          if(num===2) addLinebreak = 1;
        }else{
          addLinebreak = false;
        }

        return (
          <span>
            <span
              id={"word" + this.props.id + index}
              style={wordSpanStyle}
              class={isPlainText ? 'plainTextWordSpan' : "hoverableWordSpan" + onboardingClass + (isAlreadySavedToFlashcard && this.props.loggedInUser.highlightSavedWords === true ? ' alreadySavedWord' : '')}
              onClick={() => this.handleWordClicked(item, "word" + this.props.id + index, onboardingClass)}
            >
              {item}
              {addLinebreak===1 ? <br/> : null}
              {addLinebreak===2 ? <React.Fragment><br/><br/></React.Fragment> : null}
            </span>
            <span>{" "}</span>
          </span>
        );
      })
      
      let translationText = this.props.paragraph.translation.replace(/\n{2}(?!\n)/g, '\n&nbsp;\n'); // this makes the line breaks render in the markdown parser. only works for single or double, not triple linebreak. regex matches 2 linebreaks
      // .replace(/(^|[^\n])\n{2}(?!\n)/g, '\n&nbsp;\n'); // this regex was selecting the last char in the line also
      let { hideHighlightButton, hideTranslateButton, darkButtons, hasBlurModal } = this.props;
      if(isPlainText){
        hideTranslateButton = true;
        hideHighlightButton = true;
      }

      // I could move this logic outside of paragraphgs into the loop that renders the <Paragraph so the paragraphs dont re-render every time play position changes
      let isCurrentlyActive = (this.props.position > this.props.paragraph.startsAt) && (this.props.position < (this.props.nextParagraphStartsAt ? this.props.nextParagraphStartsAt : Infinity));
      let displayPauseIcon = isCurrentlyActive && this.props.playStatus === 'PLAYING' ? true : false;

      let blurDivStyle = {filter: this.props.blur ? 'blur(3px)' : undefined};
      if(this.props.blur) blurDivStyle.pointerEvents = 'none'

      let playButtonStyle = {
        width: 22,
        height: 22,
        cursor: 'pointer',
        zIndex: 3,
        marginTop: -2,
        marginRight: 5,
        
      }
      let showGreyPlayButton = !isCurrentlyActive && this.props.playStatus === 'PLAYING' ;


      return (
        <div class='w-100 px-2' style={{...styles.container, opacity: showGreyPlayButton ? .5 : 1}} id={'paragraphCard' + this.props.id}>
          {!loggedInUser.email && hasBlurModal ? 
          <>
            <BlurCover />
          </> : null }
          <div  style={blurDivStyle}>
            <div >
              <p style={styles.paragraph}>
                <span style={{position: 'relative'}}>
                  {isCurrentlyActive && this.props.playStatus === 'PLAYING' && !isMobile ? 
                  <div style={styles.soundPlayingIconWrapper}>

                    <img 
                        src={soundPlayingIcon} 
                        style={styles.soundPlayingIcon} />
                  </div>
                  :null}
                  <img 
                    class={showGreyPlayButton ? 'playButtonSecondary' : 'playButtonActive' }
                    title={displayPauseIcon ? 'Pause' : 'Play starting here' }
                    src={displayPauseIcon ? pauseIcon : (showGreyPlayButton ? playIcon : playIcon)} 
                    style={playButtonStyle} 
                    onClick={isCurrentlyActive ? this.props.handlePlayPause : () => this.props.handlePlayParagraph(this.props.paragraph.startsAt)} />
                </span>
                {words}
              </p>
              <div class='collapse' id={'paragraph' + this.props.id} >
                <div style={styles.translationBox} class='markdownParagraphWrapper'>
                  <p style={{...styles.translation, fontSize: loggedInUser.fontSize}}>
                    <ReactMarkdown source={translationText} style={{ marginBottom: 0, fontSize: loggedInUser.fontSize }} />
                  </p>
                </div>
              </div>

              {/* BUTTONS */}
              {hideTranslateButton ? null : 
              <div style={styles.buttonRow}>
                <LightButton
                  dark={darkButtons}
                  dataToggle="collapse" 
                  href={"#paragraph" + this.props.id} 
                  text='Translate'
                  imgSrc={require('../../assets/img/blue/dark/translate.png')}
                />

                { loggedInUser && isBrowser && !hideHighlightButton ? 
                  <LightButton
                    dark={darkButtons}
                    imgSrc={require('../../assets/img/blue/dark/stack.png')}
                    text='Flashcard'
                    onClick={() => this.props.highlightClick()}
                  />
                : null }
              </div>
              }

            </div>
          </div>
        </div>
      );
    }
}

const styles = {
  container: {
    // border: '1px solid grey'
    marginTop: 10,
    marginBottom: 10,
    position: 'relative'
    
    // minWidth: isBrowser ? 600 : 0
  },
  paragraph: { //the main <p> element
    marginTop:20,
    marginBottom: 0,
    lineHeight:1.5,
    color: '#6e7684',
    // whiteSpace: 'pre-wrap',
    fontSize: '.99rem', //fixed weird line break issue with line length
  },

  translation: {
    fontStyle: 'italic',
    marginBottom: 5,
    marginTop: 5,
    lineHeight:1.8,
    color: '#6e7684',
    whiteSpace: 'pre-wrap',    
  },
  translationBox: {
    // marginLeft: 15,
    transition: '.4s',
    paddingLeft: 15,
    paddingRight: 5,
    // borderTop: '4px solid #5e5e5e50',
    borderRight: '4px solid #8a94a6',
    borderLeft: '4px solid #8a94a6',
    borderRadius: 8,
    marginTop: 18,
    fontSize: 16,
    // backgroundColor: '#f2f2f2'

  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 7,
  },
  soundPlayingIcon: {
    // height: 20,
    width: 20,

    // zIndex: 9999
  },
  soundPlayingIconWrapper: {
    height: 25,
    width: 25,
    borderRadius: 12.5,
    // boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
    // backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: -60,
    top: -2,
    
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  deckInfo: state.deckInfo
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(Paragraph);

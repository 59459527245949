import React, { Component } from 'react';
import StorylingButton from '../buttons/StorylingButton';
import { isMobile } from 'react-device-detect';

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { submitButtonText, onSubmit, title, visible, onClose, isSubmitting, marginBottom=0 } = this.props;
    if(!visible) return null;
    return(
      <div style={styles.wrapper} onClick={e => {
        e.preventDefault();
        if(e.target === e.currentTarget) {
          onClose()
       }
      }}>
        <div style={{...styles.modalBody, marginBottom}}>
          <div style={styles.topRow}>
            <div style={styles.title}>{title}</div>
          </div>
          <div style={styles.middle}>
            {this.props.children}
          </div>
          <div style={styles.bottom}>
            <div style={styles.cancelButton} onClick={onClose}>
              Cancel
            </div>
            <StorylingButton onClick={onSubmit} text={submitButtonText} loading={isSubmitting} />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5000
  },
  modalBody: {
    backgroundColor: '#fff',
    borderRadius: 7,
    zIndex: 50,
    minWidth: isMobile ? '90%' : 500,
    // minHeight: isMobile ? '70%' : 300,
    display: 'flex',
    flexDirection: 'column',
  },
  topRow: {
    padding: 15,

  },
  middle: {
    flex: 1
  },
  bottom: {
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  cancelButton: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    textAlign: 'center',
    cursor: 'pointer'
  },
  title: {
    fontWeight: 400,
    fontSize: 18
  }

};

export default CustomModal;
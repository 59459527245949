import React, { Component } from 'react';
import AdminNavbar from '../../components/admin/AdminNavbar';
import { Link, Redirect } from 'react-router-dom';
import { updateLoggedInUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import LoadingOverlay from '../../components/LoadingOverlay';
import Colors from '../../assets/Colors';
import getFlagImageName from '../../scripts/getFlagImageName';

class ManageArticlesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            // shownArticles: [],
            filterLanguage: 'any',
            filterDifficulty: 'any',
            filterType: 'any',
            filterShowFree: false,
            filterShowPaid: true,
            filterShowHidden: false,
            filterShowEmbedded: false,
        }
    }

    //----LIFECYCLE-----

    componentDidMount(){
        this.setArticles()
    }

    getRouteName = () => {
        let { pageType='article' } = this.props;
        let url =  ``;
        if(pageType==='article') url = 'manage-articles';
        if(pageType==='podcasts') url = 'manage-podcasts';
        return url;
    }

    setArticles(){
        let { pageType='article' } = this.props;

        let url =  `/${pageType}/getall?languages=all&include_embedded=true`;
        
        this.setState({loading: true});
        fetch(url, {
            method: 'get',
            credentials: 'same-origin',
            headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
            }
        })
        .then(result => result.json())
        .then(result => {
            if(result.status=='success') {
                this.setState({
                    loading: false,
                    articles: result.articles
                });
                // this.filterArticles();
            } else {
            this.setState({ loading: false });         
            alert('could not get articles. check your internet connection') ;
            }
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.router.match.path != prevProps.router.match.path){
            this.setArticles()
        }
    }


    render() {
        //filter articles
        let shownArticles = JSON.parse(JSON.stringify(this.state.articles));
        if(shownArticles)
        console.log(shownArticles[0])
        if(this.state.filterLanguage!='any')
            shownArticles = shownArticles.filter(item => item.language==this.state.filterLanguage);
        if(this.state.filterDifficulty!='any')
            shownArticles = shownArticles.filter(item => item.difficulty==this.state.filterDifficulty);
        if(this.state.filterType!='any')
            shownArticles = shownArticles.filter(item => item.type==this.state.filterType);
        //filter free/paid articles
        if(this.state.filterShowFree==false)
            shownArticles = shownArticles.filter(item => item.is_free==false);
        if(this.state.filterShowPaid==false)
            shownArticles = shownArticles.filter(item => item.is_free==true);    
        if(this.state.filterShowHidden==true)
            shownArticles = shownArticles.filter(item => item.isHidden==true);    
        if(this.state.filterShowEmbedded==true)
            shownArticles = shownArticles.filter(item => item.isEmbedded==true);         

        if(shownArticles==undefined)
            shownArticles=[];

        if(this.props.loggedInUser.accountType === 'editor'){
            shownArticles = shownArticles.filter(x => x.createdBy === this.props.loggedInUser.id  ?true : false)
        }

        
        return (
            <div style={{ height: '100%' }}>
                <AdminNavbar active={this.getRouteName()} />
                {this.state.loading ? <LoadingOverlay /> : null}
                <div class='container-fluid fadeMeIn' style={{paddingTop: 75}}>
                    <div class='row'>
                        <div class='col-md-3 bg-light rounded py-3'>
                            {/* <h4 class='text-center'>Filter</h4> */}

                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Language</label>
                                <select class="form-control" onChange={x => { this.setState({filterLanguage: x.target.value});  }} value={this.state.filterLanguage}>
                                <option value='any'>any</option>
                                {this.props.availableLanguages.map((item, index) => {
                                    return (<option value={item.title}>{item.title}</option>)
                                })}
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Type</label>
                                <select class="form-control" onChange={x => { this.setState({filterType: x.target.value});  }} value={this.state.filterType}>
                                <option value='any'>any</option>
                                <option value='book'>book</option>
                                <option value='story'>story</option>
                                <option value='conversation'>conversation</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Difficulty</label>
                                <select class="form-control" onChange={x => { this.setState({filterDifficulty: x.target.value});  }} value={this.state.filterDifficulty}>
                                <option value='any'>any</option>
                                <option value='newbie'>newbie</option>
                                <option value='beginner'>beginner</option>
                                <option value='intermediate'>intermediate</option>
                                <option value='advanced'>advanced</option>
                                </select>
                            </div>

                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" onChange={x => { this.setState({ filterShowFree: x.target.checked }); }} checked={this.state.filterShowFree} />
                                <label class="form-check-label">Free Articles</label>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" onChange={x => { this.setState({ filterShowPaid: x.target.checked }); }} checked={this.state.filterShowPaid} />
                                <label class="form-check-label">Paid Articles</label>
                            </div>
                            <br/>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" onChange={x => { this.setState({ filterShowHidden: x.target.checked }); }} checked={this.state.filterShowHidden} />
                                <label class="form-check-label">Hidden Articles Only</label>
                            </div>

                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" onChange={x => { this.setState({ filterShowEmbedded: x.target.checked }); }} checked={this.state.filterShowEmbedded} />
                                <label class="form-check-label">Embedded Articles Only</label>
                            </div>

                            <div class="form-group">
                                <Link to='/edit-article/new'>
                                    <div class='btn btn-outline-primary rounded' style={{marginTop: 30, float: 'right', fontWeight: 'bold'}}>
                                        <i class='fa fa-plus' style={{marginRight: 15}}></i>
                                        New Article
                                    </div>
                                </Link>
                            </div>

                        </div>

                        <div class='col-md-9' style={{maxHeight: '85vh', overflow: 'auto'}}>
                            <p class='text-center'>Total: {shownArticles.length}</p>

                            {shownArticles.map((item, index) => {
                                let flagName = getFlagImageName(item.language);
                                let flag = <img style={styles.flag} src={require(`../../assets/img/flags/${flagName || 'default-flag'}.png`)}/>;
                                let publishedText = item.isHidden ? 'hidden' : 'Published';
                                let publishedOn = item.publishedOn && item.isHidden===false ? ': '+new Date(item.publishedOn).toLocaleString() : '';
                                return (
                                    <div>
                                    <Link to={'/edit-article/'+item.id} style={{textDecoration: 'none', color: '#191919', opacity: (item.isHidden ? .6 : 1)}}>
                                        <div class='col-md-10 offset-md-1 adminArticle' style={styles.article}>
                                            <div class='row'>
                                                <div class='col-1 text-center'>
                                                    {flag}
                                                </div>
                                                <div class='col-11'>
                                                    <h4>{item.title}</h4>
                                                    <div style={styles.label}><strong>Created:</strong> {new Date(item.createdAt).toLocaleString()}</div>
                                                    <div style={{...styles.label}}>
                                                        <strong>{publishedText}</strong>{publishedOn}
                                                    </div>
                                                    <div style={styles.label}>Difficulty: <strong>{item.difficulty}</strong></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <hr class='col-8 '/>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser,
    availableLanguages: state.availableLanguages
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(ManageArticlesPage);

const styles = {
    article: {
        marginBottom: 10,
        padding: 10,
        paddingLeft: 20,
        cursor: 'pointer',

    },
    bookIcon: {
        fontSize: 36,
        marginTop: '20%',
        color: '#191919'
    },
    label: {
        fontSize: 14,
    },
    flag: {
        height: 50,
    }
}

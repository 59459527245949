import React, { Component } from "react";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { Link } from "react-router-dom";
import DraggableList from "react-draggable-list";
import LessonDeckRow from "./LessonDeckRow";
import Colors from "../../../assets/Colors";
import RowSection from "./RowSection";
import { message } from "antd";
import AdminInput from "../../../components/admin/AdminInput";

class ManageLessonDecksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decks: [],
      loading: true,
      languages: [],
      filteredDecks: [],
    };
  }

  componentDidMount() {
    this.loadDecks();
    this.loadLanguages();
  }

  loadLanguages = async () => {
    var langRes = await fetch("/languages/get/all", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((result) => result.json());
    if (langRes.languages.length > 0) this.setState({ languages: langRes.languages });
  };

  loadDecks = () => {
    fetch(`/lessondecks/getall`, {
      method: "GET",
    })
      .then((result) => result.json())
      .then((result) => {
        this.setState({ decks: result.decks, loading: false });
      });
  };

  onListChange = (newList) => {
    if (newList.length === 1) return;
    // if(this.state.selectedLanguage){
    //   let newDecks = [...this.state.decks];
    //   let newIndex =
    //   let objectBeforeIndex
    //   let objectAfterIndex

    //   this.setState({
    //     filteredDecks: newList,
    //     showSaveOrderButton: true,
    //     decks: newDecks
    //   })
    // }else{
    this.setState({
      decks: newList,
      showSaveOrderButton: true,
    });
    // }
  };

  saveOrder = () => {
    let indexData = this.state.decks.map((d, index) => {
      return {
        id: d.id,
        sortIndex: index,
      };
    });
    console.log(indexData);

    fetch(`/lessondecks/savesort`, {
      method: "POST",
      body: JSON.stringify({ indexData: indexData }),
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({ loading: false, showSaveOrderButton: false });
          message.success("Saved!");
        } else {
          alert(result.message);
          this.setState({ loading: false });
        }
      });
  };

  filterByLanguage = (lang) => {
    if (!lang) {
      this.setState({
        selectedLanguage: undefined,
        // filteredDecks: []
      });
    } else {
      this.setState({ selectedLanguage: lang });
      // let filtered = this.state.decks.filter(x => x.language===lang)
      // this.setState({filteredDecks: filtered});
    }
  };

  render() {
    const { showSaveOrderButton, filteredDecks, decks, selectedLanguage } = this.state;
    const languageOptions = this.state.languages.map(x => ({label: x.title, value: x.title}));

    return (
      <div style={styles.wrapper} class="container">
        <AdminNavbar active={"manage-lesson-decks"} />
        {this.state.loading ? <LoadingOverlay /> : null}
        <div style={styles.topRow}>
          <h1>Lesson Decks</h1>
          <div>
            {showSaveOrderButton ? (
              <div class="btn btn-primary" onClick={this.saveOrder} style={{ marginRight: 20 }}>
                Save Deck Order
              </div>
            ) : null}

            <Link to="/manage-lesson-decks/new">
              <div class="btn btn-primary">+ Add Deck</div>
            </Link>
          </div>
        </div>

        <div>
        <AdminInput
            labelWidth={200}
            style={{ maxWidth: 400 }}
            label="Filter Language"
            options={languageOptions}
            value={this.state.selectedLanguage}
            onChange={(x) => this.filterByLanguage(x)}
          />
          
        </div>

        {/* <span>Total: {shownUsers.length}</span>
          <input onChange={e => this.handleSearchChange(e)} value={this.state.search} type="text" class="form-control" style={styles.search} placeholder='Search...'/>
          {this.state.loading ? <PulseLoader color='grey'/> : null}
          <br/>
          <br/> */}
        <div style={styles.headerRow}>
          <RowSection flex={0.5}>Title</RowSection>
          <RowSection flex={0.1}># Cards</RowSection>
          <RowSection flex={0.2}>Language</RowSection>
          <RowSection flex={0.05}>Edit</RowSection>
          <RowSection flex={0.1}>Copy Link</RowSection>
          <RowSection flex={0.1}>Drag & Move</RowSection>
        </div>

        <div ref={(x) => (this.tbody = x)}>
          <DraggableList
            itemKey={"id"}
            template={(props) => <LessonDeckRow {...props} selectedLanguage={selectedLanguage} />}
            list={decks}
            onMoveEnd={this.onListChange}
            container={() => this.tbody}
            constrainDrag={true}
            padding={0}
            // autoScrollMaxSpeed={0}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    // padding: 20,
    paddingTop: 75,
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    marginTop: 10,
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
    padding: 3,
    paddingLeft: 8,

    // borderRadius: 4,
    // borderWidth: 1,
    // borderColor: Colors.border,
    // borderStyle: 'solid'
  },
  section: {
    flex: 0.2,
  },
};

export default ManageLessonDecksPage;

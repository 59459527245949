import React, { Component } from 'react';
import { Input } from 'antd';
import closeIcon from '../../../assets/img/close.png';
import Colors from '../../../assets/Colors';

class DeckRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    this.setState({isClosed: true})
    setTimeout(() => this.props.onDelete(), 200);
    // this.props.onDelete()
  }

  render() {
    const { word, translation } = this.props;
    const { isClosed } = this.state;
    return(
      <div className={isClosed ? 'collapse-row-closed' : 'collapse-row-open'}>
        <div style={styles.wrapper}>
          <div style={styles.word}>{word}</div>
          {/* <div style={{marginLeft: 8,marginRight: 8, flex: .05}}>/</div> */}
          <div style={styles.translation}>{translation}</div>
          <div style={styles.closeButton} onClick={() => this.onClose()} className='fadeHover'>
            <img src={closeIcon} style={styles.closeButtonIcon} />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.border,
    borderStyle: 'solid',
    padding: 3,
    marginBottom: 10,
    paddingLeft: 12,
    width: 600,
    backgroundColor: '#fff'
  },
  word: {
    flex: .5,
    wordWrap: 'break-word',
    overflow: 'hidden',
    marginRight: 5
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  translation: {
    flex: .5,
    wordWrap: 'break-word',
    overflow: 'hidden',
    color: 'blue',
    marginLeft: 10
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  closeButton: {
    padding: 8,
    marginLeft: 10,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closeButtonIcon: {
    height: 16,
    width: 16
  }
};

export default DeckRow;
// articles
export const UPDATE_ARTICLES = 'updateArticles';
export const ARTICLES_NEED_RELOAD = 'articlesNeedReload';
export const LAST_CLICKED_ARTICLE = 'lastClickedArticle';

// decks and words
export const UPDATE_WORD_LIST = 'updateWordList';
export const HYDRATE_DECKS = 'HYDRATE_DECKS';
export const SELECT_DECK_ID = 'SELECT_DECK_ID';
export const MODIFY_DECK = 'MODIFY_DECK';

// lesson decks
export const SELECT_LESSON_DECK = 'SELECT_LESSON_DECK';
export const HYDRATE_LESSON_DECKS = 'HYDRATE_LESSON_DECKS';

// article filters and sorts
export const CHANGE_FILTER = 'CHANGE_FILTER';

// user course status
export const MARK_LESSON_IN_PROGRESS = 'MARK_LESSON_IN_PROGRESS';
export const MARK_LESSON_COMPLETE = 'MARK_LESSON_COMPLETE';
export const MARK_LESSON_INCOMPLETE = 'MARK_LESSON_INCOMPLETE';
export const HYDRATE_COMPLETE_LESSONS = 'HYDRATE_COMPLETE_LESSONS';
export const HYDRATE_IN_PROGRESS_LESSONS = 'HYDRATE_IN_PROGRESS_LESSONS';





export const UPDATE_LOGGED_IN_USER = 'users:updateLoggedInUser';
export const TOGGLE_NAV = 'toggleNav';
export const SELECT_LANGUAGE = 'selectLanguage';
export const SET_LANGUAGES = 'setLanguages';
export const UPDATE_SEARCH_TEXT = 'updateSearchText';
export const SET_PODCAST_STATUS = 'SET_PODCAST_STATUS';
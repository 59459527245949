import React, { Component } from 'react';
import Colors from '../../../assets/Colors';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

class LoggedOutBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onLoginClick = () => {
    this.props.history.push('/login')
  }

  render() {
    return(
      <div style={styles.wrapper}>
        <div style={styles.inside}>
          <div style={styles.text}>You are currently logged out. Log in to view all stories!</div>
          <div class='btn btn-light' style={styles.button} onClick={this.onLoginClick}>Log In</div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginTop: isMobile ? -5 : -18,
    backgroundColor: 'blue',
    width: '100%'
  },
  inside: {
    maxWidth: 800,
    
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    color: Colors.text,
    marginRight: 30,
    color: '#fff'
  }
};

export default withRouter(LoggedOutBanner);
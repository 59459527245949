import React, { Component, PureComponent } from "react";
import Colors from "../../assets/Colors";
import AnimateHeight from "react-animate-height";
import translateSingleWords from "../../scripts/translateSingleWords";

class Paragraph extends PureComponent {
  handleMarkdown(markdownType) {
    // type, paragraphIndex, startIndex, endIndex
    let textStartIndex = this["text"].selectionStart;
    let textEndIndex = this["text"].selectionEnd;

    let translationStartIndex = this["translation"].selectionStart;
    let translationEndIndex = this["translation"].selectionEnd;

    let selectedText = textStartIndex == textEndIndex ? false : true;
    let selectedTranslation = translationStartIndex == translationEndIndex ? false : true;

    if (selectedText) {
      return this.props.markdownText(markdownType, "text", this.props.index, textStartIndex, textEndIndex);
    } else if (selectedTranslation) {
      return this.props.markdownText(markdownType, "translation", this.props.index, translationStartIndex, translationEndIndex);
    } else {
      alert("Highlight some text first to make it bold/italic");
    }
  }

  componentDidMount() {
    // translateSingleWords() // for testing only
  }

  render() {
    const { isPlainText } = this.props.data;
    const { togglePlainText } = this.props;
    return (
      <div class="col-12 fadeMeIn" style={styles.paragraph}>
        <div class="form-group row">
          <label for="title" class="col-sm-2 col-form-label">
            Starts At
          </label>
          <div class="col-4 col-sm-3 col-lg-2">
            <input
              type="text"
              class="form-control"
              name="startsAtMin"
              placeholder="0"
              value={this.props.data.startsAtMin}
              onChange={e => this.props.handleChange(this.props.index, e)}
            />
            <span>min.</span>
          </div>
          <div class="col-4 col-sm-3 col-lg-2">
            <input
              type="text"
              class="form-control"
              name="startsAtSec"
              placeholder="0"
              value={this.props.data.startsAtSec}
              onChange={e => this.props.handleChange(this.props.index, e)}
            />
            <span>sec.</span>
          </div>
          <div class="col-4 h-100">
            <div class="row" style={{alignItems: 'center'}}>
              <span>Plain text?</span>
                <input
                  type="checkbox"
                  checked={isPlainText}
                  onChange={e => togglePlainText(e.target.checked)}
                  style={{marginLeft: 5}}
                />
            </div>
          </div>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          style={{ position: "absolute", top: 5, right: 10 }}
          onClick={e => this.props.delete(this.props.index, e)}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div style={styles.buttonRow}>
          {/* preventDefault stops the selecting from being deselected when the button is clicked */}
          <span
            class="markdownButton"
            style={styles.markdownButton}
            onMouseDown={e => e.preventDefault()}
            onClick={e => this.handleMarkdown("bold", e)}
          >
            Bold
          </span>
          <span
            class="markdownButton"
            style={styles.markdownButton}
            onMouseDown={e => e.preventDefault()}
            onClick={e => this.handleMarkdown("italic", e)}
          >
            Italic
          </span>
        </div>

        <div class="form-group row">
          <span style={styles.textLabel}>Text</span>
          <div class="col-sm-12">
            <textarea
              class="form-control"
              name="text"
              value={this.props.data.text}
              onChange={e => this.props.handleChange(this.props.index, e)}
              style={styles.textbox}
              ref={x => (this["text"] = x)}
            />
          </div>
        </div>

        <div class="form-group row">
          <span style={styles.textLabel}>Translation</span>
          <div class="col-sm-12">
            <textarea
              class="form-control"
              name="translation"
              value={this.props.data.translation}
              onChange={e => this.props.handleChange(this.props.index, e)}
              style={styles.textbox}
              ref={x => (this["translation"] = x)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  paragraph: {
    backgroundColor: "#fff",
    border: "1px solid " + Colors.border,
    borderRadius: 5,
    padding: 15,
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 0,
    boxShadow: "0px 0px 14px rgba(0,0,0,.07)"
  },
  textLabel: {
    marginLeft: 10,
    opacity: 0.5
  },
  textbox: {
    minHeight: 170
  },
  markdownButton: {
    marginRight: 15,
    borderRadius: 3,
    padding: 4,
    cursor: "pointer",
    marginBottom: 8
  },
  buttonRow: {
    marginBottom: 12
  }
};

export default Paragraph;

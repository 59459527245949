import React from 'react';

const RowSection = ({flex=.2, children}) => {
  return(
    <div style={{
      flex: flex,
    }}>
      {children}
    </div>
  );
}

const styles = {

};

export default RowSection;
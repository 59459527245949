import React, { Component } from 'react';
import Colors from '../assets/Colors';


class NotLoggedIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {

        return (
          <div style={{ height: '100%' }}>
                {/* <MyNavbar /> */}
                <div class='container fadeMeIn py-2 text-center'>
                    <img src={require('../assets/img/logo.png')} height={40} />
                    <br/>
                    <br/>
                    <h1>You need to sign up to read this story</h1>
                    {/* <Link to='/'>Back Home</Link> */}
                </div>
            </div>
        );
    }
}

const styles = {

}

export default NotLoggedIn;

import React, { Component } from "react";
import Colors from "../../../assets/Colors";
import ReactCardFlip from "react-card-flip";
import { ClipLoader } from 'react-spinners';
import $ from 'jquery'
import { fetchDecks } from "../../../helpers/decks";
import backArrowIcon from '../../../assets/img/backarrow.png';
import DecksSection from "./DecksSection";
import { connect } from 'react-redux';
import MobileBottomBanner from "./MobileBottomBanner";

const maxCardHeight = "30vh";
const shuffleDeselected = require("../../../assets/img/shuffle.png");
const shuffleSelected = require("../../../assets/img/shuffle_green.png");

class WordsPageMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reverse: false,
      isFlipped: false,
      flipSpeed: 0.3,
      currentView: 'decks'
    };
  }

  componentDidMount(){
    fetchDecks()
  }

  handleFlipCard() {
    this.setState({ isFlipped: !this.state.isFlipped, flipSpeed: 0.3 });
  }

  handleNextCard() {
    this.props.next();
    this.setState({ isFlipped: false, flipSpeed: 0 });
  }

  handleBackCard() {
    this.props.back();
    this.setState({ isFlipped: false, flipSpeed: 0 });
  }

  render() {
    const { selectedDeck, selectedWord } = this.props;

    let wordStyle = {
      fontSize: this.props.longWord ? 18 : 36,
      // fontWeight: this.props.longWord ? "normal" : "bold",
      maxHeight: maxCardHeight,
      overflow: "auto",
      height: "100%",
      alignItems: "center",
      margin: "auto",
      textAlign: "center",
      justifyContent: "center",
      display: "flex"
    };

    let flipCardIcon = 
      <div style={styles.flipCardIconWrapper}>
        <img src={require("../../../assets/img/turn.png")} style={styles.flipCardIcon} />
      </div>;

    let currentWordIndex = 0;
    (selectedDeck.list || []).forEach((w, wIndex) => {
      if(w.word === selectedWord.word) currentWordIndex = wIndex;
    })

    return <div>
        {/* <div style={{height: 60}} /> */}
        <React.Fragment>
          <div style={{padding: 10}}>
          <div style={styles.decksButton} onClick={this.props.goToDecks}>
            <img src={backArrowIcon} style={styles.goToDecksBackIcon} />
            <div style={styles.goToDecksText}>Go to Decks</div>
          </div>
        </div>
        <div style={styles.cardContainer} onClick={this.handleFlipCard.bind(this)}>
          <ReactCardFlip isFlipped={this.state.isFlipped} flipSpeedFrontToBack={this.state.flipSpeed} flipSpeedBackToFront={this.state.flipSpeed} style={{ display: "inline-block" }}>
            <div style={styles.flashCard} class="text-center" id="flashCard" key="front">
              <div style={wordStyle}>
                <div style={{ margin: "auto" }}>{this.state.reverse ? this.props.selectedWord.translation : this.props.selectedWord.word}</div>
              </div>
              {this.props.longWord ? null : flipCardIcon}
            </div>
            <div style={styles.flashCard} class="text-center" id="flashCard" key="back">
              <div style={wordStyle}>
                <div style={{ margin: "auto" }}>{this.state.reverse ? this.props.selectedWord.word : this.props.selectedWord.translation}</div>
              </div>
              {this.props.longWord ? null : flipCardIcon}
            </div>
          </ReactCardFlip>
        </div>

        <div style={styles.amountRow}>
         {selectedDeck ? (currentWordIndex + 1 + " / " + (selectedDeck.list || []).length) : null}
        </div>

        <div class="px-2 my-3" style={styles.buttonRow1}>
          {selectedDeck.isLessonDeck ? null : 
            <div style={styles.iconButton} onClick={this.props.deleteCurrentWord}>
              <i class="fa fa-trash" style={{ ...styles.icon, color: "red", opacity: 0.6 }} />
            </div>
          }
          <div style={styles.iconButton} onClick={() => this.setState({ reverse: !this.state.reverse })}>
            <i class="fa fa-retweet" style={styles.icon} />
          </div>
          <div style={styles.iconButton} onClick={this.props.toggleShuffle}>
            <img src={this.props.shuffle ? shuffleSelected : shuffleDeselected} width="30" style={styles.image} />
          </div>
          {selectedDeck.isLessonDeck ? null : 
            <div style={styles.iconButton} data-toggle="modal" data-target="#CustomCardModal" onClick={() => $('#CustomCardModal').modal('show')}>
              <i class="fa fa-plus" style={styles.icon} />
            </div>
          }
          <a style={styles.iconButton} href={this.props.defineLink} target="_blank">
            <i class="fa fa-book" style={styles.icon} />
          </a>
          <div style={{...styles.iconButton, opacity: this.props.longWord ? .5 : 1}} onClick={this.props.longWord ? null : e => this.props.handleReadWord(e)}>
            {this.props.pronounciationLoading ? <ClipLoader color="#82ca1c" size={20} /> : <i class="fas fa-headphones-alt" style={styles.icon} />}
          </div>
        </div>
        <div class="row" style={styles.buttonRow2}>
          <div class="btn btn-primary btn-lg col-5" onClick={this.handleBackCard.bind(this)}>
            <i class='fas fa-arrow-left' />
          </div>
          <div class="col-2" />
          <div class="btn btn-primary btn-lg col-5" onClick={this.handleNextCard.bind(this)}>
            <i class='fas fa-arrow-right' />
          </div>
        </div>
        <div style={styles.flipButton} onClick={this.handleFlipCard.bind(this)}>Flip</div>
        </React.Fragment>

        <MobileBottomBanner />
        
      </div>;
  }
}

const styles = {
  flashCard: {
    height: maxCardHeight,
    width: "100%",
    borderRadius: 5,
    backgroundColor: "#fcfcfc",
    boxShadow: "0 0 12px rgba(0,0,0,.15)",
    position: "relative",
    padding: 5
  },
  cardContainer: {
    height: maxCardHeight,
    marginTop: 10,
    padding: 8
  },

  buttonRow2: {
    paddingTop: 10
  },
  amountRow: {
    width: "100%",
    textAlign: "center",
    marginTop: 15
  },
  flipButton: {
    // width: '100%',
    marginTop: 15,
    height: 50,
    backgroundColor: '#fff',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    color: Colors.lightText+'40',
    margin: 10,
    borderRadius: 5,
    border: '1px solid #dddddd50',
    // boxShadow: '0 0 12px rgba(0,0,0,.05)'
  },
  
  
  buttonRow1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  iconButton: {
    width: 50,
    height: 50,
    border: '1px solid #dddddd50',
    borderRadius: 25,
    textAlign: 'center',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 24,
    padding: 0,
    margin: 0,
    lineHeight: '48px',
    // margin: 0,
    color: "#82ca1c"
    // margin: '4',
    // boxShadow: '0 0 5px rgba(255,255,255,.5)',
  },
  image: { // shuffle image
    width: 25
  },

  flipCardIcon: {
    // position: 'absolute',
    height: 28,
    width: 28,
    opacity: 0.24,
    // bottom: 36,
    textAlign: "center"
  },
  flipCardIconWrapper: {
    width: "100%",
    position: 'absolute',
    top: 155
  },

  decksButton: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: '#0c66ff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    marginTop: 5,

  },
  goToDecksBackIcon: {
    height: 14,
    marginRight: 10
  },
  goToDecksText: {
    color: '#0c66ff',
    fontSize: 14
  }
};

const mapStateToProps = state => ({
  deckInfo: state.deckInfo,
  lessonDeckInfo: state.lessonDeckInfo
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(WordsPageMobile);

import React, { Component } from "react";
import Colors from "../assets/Colors";
import Footer from "../components/Footer";
import MyNavbar from "../components/MyNavbar";
import SideNav from "../components/SideNav";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import getNavbarHeight from "../scripts/getNavbarHeight";
import SiteBanner from "../components/SiteBanner";

class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { loggedInUser, limitSize, showSearch, forvo } = this.props;

    let insideDivStyle = {
      paddingTop: getNavbarHeight(this.props.loggedInUser),
      position: "relative",
      boxSizing: "border-box",
      minHeight: "100vh",
      backgroundColor: Colors.blueBG
    };
    if (limitSize) insideDivStyle.height = "100vh";
    // height 100% only sets to parents height, not not its maxheight. so parent needs height for children to use 100%

    return (
      <div style={{ minHeight: "100vh" }}>
        {/* <SiteBanner /> */}
        <MyNavbar showSearch={showSearch} />
        <div id={loggedInUser ? "sideNavWrapper" : ""} class={this.props.navState}>
          {loggedInUser ? <SideNav closePopover={this.props.closePopover} /> : null}
          <div id="page-content-wrapper">
            <div class="fadeMeIn" style={insideDivStyle}>
              {this.props.children}
            </div>
            <Footer forvo={forvo} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage
});

export default withRouter(connect(mapStateToProps)(PageTemplate));

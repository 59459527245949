import React, { Component } from "react";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleBackPage, article, handlePageClick, handleNextPage, currentPageIndex } = this.props;
    return (
      <ul class="pagination justify-content-center">
        <div style={styles.pageBackButton} onClick={handleBackPage} class="fadeHover">
          <img src={require("../../assets/img/arrow-back.png")} style={styles.pageBackArrow} />
        </div>
        {article.pages.map((item, index) => {
          let numberStyle = {
            fontWeight: "bold",
          };
          if (index == 0) {
            //if first element
            numberStyle.borderTopLeftRadius = 3;
            numberStyle.borderBottomLeftRadius = 3;
          }
          if (index == article.pages.length - 1) {
            //if last element
            numberStyle.borderTopRightRadius = 3;
            numberStyle.borderBottomRightRadius = 3;
          }
          if (currentPageIndex == index) {
            //if selected
            numberStyle.backgroundColor = "#fff";
            numberStyle.color = "#82ca1c";
            numberStyle.border = "1px solid #82ca1c";
          } else {
            //if not selected
            numberStyle.backgroundColor = "#dbdbdb";
            numberStyle.color = "#767676";
          }
          return (
            <li class="page-item" onClick={() => handlePageClick(index)}>
              <a style={numberStyle} className="page-link">
                {index + 1}
              </a>
            </li>
          );
        })}
        <div style={styles.nextArrowButton} onClick={handleNextPage} class="fadeHover">
          <img src={require("../../assets/img/arrow-green.png")} style={styles.nextArrow} />
        </div>
      </ul>
    );
  }
}

const styles = {
  pageBackButton: {
    cursor: "pointer",
    backgroundColor: "#dbdbdb",
    height: 37,
    width: 37,
    marginRight: 30,
    borderRadius: 3,
    alignItems: "center",
    textAlign: "center",
  },
  pageBackArrow: {
    width: 24,
    height: 24,
    marginTop: 7,
    filter: "brightness(0) invert(1)", // make image white
  },
  nextArrowButton: {
    padding: 8,
    paddingLeft: 35,
    height: 37,
    cursor: "pointer",
  },
  nextArrow: {
    width: 24,
    height: 24,
    marginBottom: 3,
  },
};

export default Pagination;

import React, { Component } from 'react';
import AdminNavbar from '../../components/admin/AdminNavbar';
import { Link, Redirect } from 'react-router';
import { updateLoggedInUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';


class ManageLanguagesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                <AdminNavbar />
                <div class='container fadeMeIn' style={{paddingTop: 75}}>
                    <h1>manage languages page</h1>
                    <p>not complete yet</p>
                    {/* <div class='col-md-6 box-shadow' style={styles.languageContainer}>
                        <h3>Spanish</h3>
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-secondary">Active</button>
                            <button type="button" class="btn btn-secondary">Disabled</button>
                        </div> 
                    </div>*/}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(ManageLanguagesPage);

const styles = {
    languageContainer: {
        padding: 15,
        border: '1px solid #dddddd',
        margin: 20
    }
}

import React, { Component } from "react";
import Colors from "../../assets/Colors";
import { Link } from "react-router-dom";
import store from '../../redux/store';

export default function NavItem({ route, blueIcon, greyIcon, title, selected, handleLinkClicked, link, collapsed, onClick, isSelected }) {
  let isPodcast = store.getState().podcastMeta.isPodcast;
  const styles = {
    icon: {
      width: 22,
      marginRight: 16,
      marginBottom: 6
    },
    navText: {
      color: Colors.blueGrey,
      fontSize: 16,
      height: "100%"
    },
    button: {
      padding: 15,
      paddingLeft: 10,
      cursor: 'pointer'
    },
    selectedButton: {
      padding: 15,
      paddingLeft: 6,
      cursor: 'pointer',
      borderLeft: '4px solid #007fff'
    }
  };
  let active = selected===route || isSelected ? true : false;

  if (link && link !== 'none')
  return (
    <div class="sideNavButton" style={active ? styles.selectedButton : styles.button}>
      <li >
        <a href={link==='none' ? '' : link}>
          <img src={active ? blueIcon : greyIcon} style={styles.icon} />
          {collapsed ? null : <span style={styles.navText}>{title}</span>}
        </a>
      </li>
    </div>
  );


  if ( link==='none')
    return (
      <div class="sideNavButton" style={active ? styles.selectedButton : styles.button} onClick={onClick ? onClick : () => null}>
        <li >
          <div>
            <img src={active ? blueIcon : greyIcon} style={styles.icon} />
            {collapsed ? null : <span style={styles.navText}>{title}</span>}
          </div>
        </li>
      </div>
    );

    if (isPodcast)
    return (
      <div class="sideNavButton" style={active ? styles.selectedButton : styles.button}>
        <li >
          <a href={link || `https://members.lingomastery.com${route}`}>
            <img src={active ? blueIcon : greyIcon} style={styles.icon} />
            {collapsed ? null : <span style={styles.navText}>{title}</span>}
          </a>
        </li>
      </div>
    );

  return (
    <div class="sideNavButton" style={active ? styles.selectedButton : styles.button}>
      <li onClick={handleLinkClicked}>
        <Link to={route}>
          <img src={active ? blueIcon : greyIcon} style={styles.icon} />
          {collapsed ? null : <span style={styles.navText}>{title}</span>}
        </Link>
      </li>
    </div>
  );
}

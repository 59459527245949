import React, { Component } from 'react';
import MyNavbar from '../components/MyNavbar';
import { Link, Redirect } from 'react-router';
import { updateLoggedInUser } from '../redux/actions';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';


class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            verifiedMessage: ''
        }
    }

    componentDidMount(){
        //take parameters from route and send request to server to verify email
        const params = new URLSearchParams(this.props.location.search);
        const email = params.get('email');
        const emailkey = params.get('emailkey');
        fetch('/email/verify?emailkey='+emailkey+'&email='+email, {
          method: 'get',
          credentials: 'same-origin',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then(result => result.json())
        .then(result => {
            this.setState({verifiedMessage: result.message});
        })
    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                <MyNavbar />
                <div class='container'>
                    <h1>verify email page</h1>
                    <p>{this.state.verifiedMessage}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(LoginPage);

import React, { Component } from 'react';
import MyNavbar from '../components/MyNavbar';
import { Link, Redirect } from 'react-router';
import { updateLoggedInUser } from '../redux/actions';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';


class SignupPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    //-----LIFECYCLE--------------------------------


    //---------METHODS-----------------------------

    render() {
        // return <iframe src='https://storyling.clickfunnels.com/optin' width='100%' style={{height: '100vh'}} frameborder='0'></iframe>;
        return <iframe src='https://storyling.clickfunnels.com/credit-card-test' width='100%' style={{height: '100vh'}} frameborder='0'></iframe>;
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(SignupPage);

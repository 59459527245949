import React, { Component } from "react";
import Colors from "../../../assets/Colors";
import { isMobile } from "react-device-detect";
import ProgressBar from "../../ProgressBar";
import { getCourseProgress } from "../../../helpers/courses";
import HeaderDetailsRow from "../HeaderDetailsRow";
import LinesEllipsis from 'react-lines-ellipsis'

class CourseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // getLengthStr = () => {
  //   const { course } = this.props;
  //   let len = 0;
  //   course.lessons.forEach(l => {
  //     if(l.length) len += parseInt(l.length);
  //   })
  //   return getLengthStr(len);
  // }

  render() {
    const { course, onClick } = this.props;

    let progress = getCourseProgress(course);
    const percentStr = Math.round(progress)


    let buttonStyle = {
      width: "100%",
      height: 45,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
      backgroundColor: progress === 100 ? '#46b049' : Colors.blue,
      color: "#fff",
      marginTop: 10,
    };

    return (
      <div style={styles.wrapper} onClick={onClick}>
        <img style={styles.image} src={course.imageUrl} />
        <div style={styles.bottom}>
          <div style={styles.title}>
            {course.title}
          </div>
          {progress === 0 ?
          <div style={styles.detailsRowWrapper}>
            <HeaderDetailsRow course={course} />
          </div>
          :
          <React.Fragment>
            <div style={{height: 13}} />
            <ProgressBar progress={progress} />
            <div style={styles.percentLabel}>{percentStr}% Complete</div>
          </React.Fragment>

          }
          <div style={{flex: 1}} />
          <div style={buttonStyle} className="fadeHover">
            View Course
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    cursor: "pointer",
    borderRadius: 6,
    backgroundColor: "#fff",
    boxShadow: "0 3px 4px 1px rgba(10, 31, 68, 0.1)",
    width: isMobile ? "100%" : 350,
    overflow: "auto",
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  bottom: {
    padding: 14,
    paddingTop: 18,
    display: 'flex',
    flexDirection: 'column',
    flex :1
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: 150,
  },
  title: {
    fontWeight: 500,
    minHeight: 48,
    
  },
  percentLabel: {
    fontSize: 13,
    color: '#8a94a6',
    marginTop :2
  },
  detailsRowWrapper: {
    paddingTop: 8,
    paddingBottom: 8
  }
};

export default CourseCard;

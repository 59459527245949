import React, { Component } from 'react';
import MyNavbar from '../components/MyNavbar';
import { Link, Redirect } from 'react-router-dom';
import { updateLoggedInUser } from '../redux/actions';
import { PulseLoader } from 'react-spinners';


class NoRoute extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                {/* <MyNavbar /> */}
                <div class='container fadeMeIn py-2 text-center'>
                    <img src={require('../assets/img/logo.png')} height={40} />
                    <br/>
                    <br/>
                    <h1>Page not found</h1>
                    <Link to='/'>Back Home</Link>
                </div>
            </div>
        );
    }
}

export default NoRoute;

let getFlagImageName = function(lang){
  switch(lang.toLowerCase()){
    case'spanish':  return 'spain'; break;
    case'german':  return 'germany'; break;
    case'french':  return 'france'; break;
    case'italian':  return 'italy'; break;
    case'portuguese':  return 'brazil'; break;
    case'russian':  return 'russia'; break;
    default: break;
  }
}
export default getFlagImageName;
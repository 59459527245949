import React, { Component } from "react";
import { Link } from "react-router-dom";
import Colors from "../../../assets/Colors";

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { course = {}, lesson = {} } = this.props;
    return (
      <div style={styles.wrapper}>

        <Link style={styles.link} to="/courses">Courses</Link>

        {course.id ? (
          <React.Fragment>
            {" / "}
            <Link style={styles.link} to={`/courses/${course.id}/lessons`}>{course.title}</Link>
          </React.Fragment>
        ) : null}

        {lesson.id ? (
          <React.Fragment>
            {" / "}
            <Link style={styles.link} to={`/courses/${course.id}?selectlessonid=${lesson.id}`}>{lesson.title}</Link>
          </React.Fragment>
        ) : null}

      </div>
    );
  }
}

const styles = {
  link: {
    fontSize: 14,
    color: '#8a94a6'
  },
  wrapper: {
    fontSize: 14,
    color: '#8a94a6'
  }
};

export default Breadcrumbs;

import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { setLastClickedArticleId } from '../../../redux/actions';
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom';
import SkillPill from './SkillPill';
import { withRouter } from 'react-router-dom';

class StoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  greyHeart = require('../../../assets/img/grey/heart.png');
  blueHeart = require('../../../assets/img/blue/heart.png');

  handleLink(storyId){
    this.props.history.push('/read-story/'+storyId);
    this.props.setLastClickedArticleId(storyId);
  }

  render() {
    let { item, index, isRead, loggedInUser } = this.props;
    let { difficulty, favoritesCount } = item;

    let hiddenIcon = null;
    if(loggedInUser) if(loggedInUser.accountType==='admin' || loggedInUser.accountType==='moderator'){
      if(item.isHidden) hiddenIcon = <img src={require('../../../assets/img/hidden.png')} style={styles.hiddenIcon} />;
    }

    return(
      <div 
        onClick={() => this.handleLink(item.id)}
        key={item.id} 
        id={'storycard'+item.id}
        // class="col-12 col-sm-6 col-md-4 col-xl-3" 
        style={{ ...styles.cardWrapper }}
      >
      <div class='storycard' >
        <img style={styles.cardImage} src={item.artwork ? item.artwork : require('../../../assets/img/default-image.png')} />
        <div style={styles.bottomContainer}>
          <div style={styles.infoRow}>
            <SkillPill difficulty={difficulty} />
            <span style={styles.category}>{item.category}</span>
          </div>
          <h3 style={{...styles.cardTitle, opacity: (isRead ? .5 : 1)}}>
            {/* {item.title} */}
            <LinesEllipsis
              text={item.title}
              maxLine='2'
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
          </h3>
          <h4 style={{...styles.subtitle, opacity: (isRead ? .5 : 1)}}>{item.subtitle}</h4>
          <div style={styles.bottomRow}>
            {hiddenIcon}
            {favoritesCount ? 
              <span style={styles.heartWrapper}>
                <img src={this.greyHeart} style={styles.heart} />
                {favoritesCount}
              </span>
            : null}
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const styles = {
  playButton: {
    height: 25,
    width: 25,
    // display: 'inline-block',
    // marginRight: 9,
    // marginBottom: 4,
    position: 'absolute',
  },

  cardImage: {
    height: 150,
    // maxWidth: 270,
    width: '100%',
    objectFit: 'cover',
    backgroundColor: '#cccccc80',
    marginBottom: 10,
  },
  cardTitle: {
    color: '#1e1e1e',
    lineHeight: 1.63,
    fontSize: 17,
    fontWeight: '500',
    marginBottom: 0,
    // marginLeft: 29
  },
  subtitle: {
    fontSize: 16, 
    color: '#b5b5b5',
    // fontWeight: 'normal',
    // margin: 0
  },
  bottomContainer: {
    padding: 13,
    paddingTop: 0,
    height: '100%'
  },

  cardWrapper: { // the link and col
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#191919',
    width: 280,
    margin: isMobile ? 0 : 9,
    marginBottom: isMobile ? 20 : 10,
  },
  infoRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10
  },
  category: {
    color: '#8a94a6',
    fontSize: 14
  },
  heartWrapper: {
    color: '#8a94a6',
    fontSize: 13,
    position: 'absolute',
    bottom: 10,
    right: 18
    // fontWeight: 'bold'
  },
  heart: {
    height: 19,
    marginRight: 5,
    marginBottom: 2
  },
  hiddenIcon: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    height: 22,
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  lastClickedArticleId: state.lastClickedArticleId
});
const mapActionsToProps = {
  setLastClickedArticleId
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(StoryCard));
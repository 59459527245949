import React, { Component } from "react";
import Colors from "../../assets/Colors";
import dayjs from 'dayjs';
import Constants from "../../assets/Constants";

class PodcastCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handlePodcastClick = () => {
    this.props.navigate(`/listen/${this.props.item.id}`);
  };

  render() {
    let { title, createdAt, artwork, podcastIntroText, publishedOn } = this.props.item;

    let date = dayjs(publishedOn ? publishedOn : createdAt).format("ddd MMMM D YYYY");

    return (
      <div style={styles.wrapper} onClick={this.handlePodcastClick}>
        <h3 style={styles.title}>{title}</h3>
        <div style={styles.date}>By {Constants.appName} on {date}</div>
        <div style={styles.imageWrapper}>
          <img src={artwork} style={styles.image} />
        </div>
        <p style={styles.text}>
          {podcastIntroText}
        </p>
        <div style={styles.readMore} onClick={this.handlePodcastClick}>Read more...</div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    marginBottom: 50,
    paddingBottom: 50,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: Colors.border,
    cursor: 'pointer'
  },
  title: {},
  date: {
    color: Colors.lighterText
  },
  image: {
    width: "100%"
    // marginTop: -100
  },
  imageWrapper: {
    overflow: "hidden",
    maxHeight: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    color: Colors.lightText,
    lineHeight: 1.6,
    marginTop: 20,
    fontSize: 18
  },
  readMore: {
    color: Colors.blue,
    textDecoration: "underline",
    fontSize: 18,
    cursor: "pointer"
  }
};

export default PodcastCard;

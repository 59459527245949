import React, { Component } from 'react';
import MyNavbar from '../../components/MyNavbar';
import { Link, Redirect } from 'react-router';
import { updateLoggedInUser } from '../../redux/actions';
import { connect } from 'react-redux';
import ForgotPasswordModal from '../../components/modals/ForgotPasswordModal';
import { PulseLoader } from 'react-spinners';


class LoginPage extends Component {

    constructor(props){
        super(props);
        this.state={
            email: '',
            password: '',
            loading: false
        }
    }

    checkSubmit(e){
      if(e.key == 'Enter')
        this.loginUser();
    }

    loginUser() {
    if(this.state.email.length>1 && this.state.password.length > 4){
        this.setState({loading: true});
        fetch('/adminlogin', {
        method: 'post',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
            body: JSON.stringify({email: this.state.email, password: this.state.password})
        })
        .then(result => result.json())
        .then(result => {
            this.setState({loading: false});
            if(result.status=='success'){
                this.setState({ email: '', password: ''});
                this.props.onUpdateLoggedInUser(result.user);
            }else{
            alert(result.message)
            console.log(result)
            }
        })

    }else{
        this.setState({error: "Please enter a valid email and password"});
    }

    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                <MyNavbar />
                <div id='loginContainer' class='fadeMeIn' style={{height: '100%', marginTop: 80}}>
                    <ForgotPasswordModal />
                    <div class="form-signin text-center" style={{maxWidth: 330}}>
                        {/* <img class="mb-4" src={require('../assets/img/png')} alt="" width="72" height="72"/> */}
                        <h1 class="h3 mb-3 font-weight-normal">Admin Login</h1>
                        <label for="inputEmail" class="sr-only">Email</label>
                        <input type="email" class="form-control" placeholder="email"  autofocus value={this.state.email} onChange={(x) => this.setState({email: x.target.value})}/>
                        <label for="inputPassword" class="sr-only">Password</label>
                        <input type="password" onKeyPress={this.checkSubmit.bind(this)} id="inputPassword" class="form-control" placeholder="Password"  value={this.state.password} onChange={(x) => this.setState({password: x.target.value})}/>

                        <button class="btn btn-lg btn-primary btn-block" onClick={this.loginUser.bind(this)}>
                            {this.state.loading ? <PulseLoader color='#f7f7f7' /> : 'Sign in'}
                        </button>

                        <p class="mt-5 mb-3 text-muted"><span data-toggle='modal' data-target='#forgotPasswordModal' style={{cursor: 'pointer'}}>Forgot Password?</span></p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser
})

const mapActionsToProps = {
    onUpdateLoggedInUser: updateLoggedInUser //to prevent variable collisions with naming, use on
}

export default connect(mapStateToProps, mapActionsToProps)(LoginPage);
